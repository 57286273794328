import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

function getToken() {
  const token = localStorage.getItem("token");
  console.log(token, "storetoken");
  return token || "";
}

export default new Vuex.Store({
  state: {
    id: localStorage.getItem("id"),
    name: "",
    img: "",
    money: "",
    mobile: "",
    loginState: false,
    loginStates: true,
    informState: true,
    id1: localStorage.getItem("csgoNum"),
    websockReadyState: 2,
    token: getToken(),
    loading: false,
    currentLanguage: localStorage.getItem("lang") || "zh",
  },
  mutations: {
    openLoading(state, value) {
      state.loading = value;
    },
    closeLoading(state, value) {
      state.loading = value;
    },
    getId(state, obj) {
      state.id = obj.id;
      state.name = obj.name;
      state.img = obj.img;
      state.money = obj.total_amount;
    },
    getMoney(state, money) {
      state.money = money;
    },
    getLogin(state, loginState) {
      state.loginState = loginState;
    },
    getLogins(state, loginStates) {
      state.loginStates = loginStates;
    },
    getInform(state, informState) {
      state.informState = informState;
    },
    getWebsock(state, websockReadyState) {
      state.websockReadyState = websockReadyState;
    },
    setMobile(state, value) {
      state.mobile = value;
    },
    setUserToken(state, token) {
      localStorage.setItem("token", token);
      state.token = token;
    },
    loginOut(state) {
      // 获取所有需要保留的数据
      const loginChecked = localStorage.getItem("loginChecked");
      const language = localStorage.getItem("lang");
      const save = localStorage.getItem("save");
      let csgoNum;
      let csgoPass;

      // 清空 localStorage
      localStorage.clear();
      // 如果需要保留登录凭证，则恢复它们
      if (loginChecked) {
        csgoNum = localStorage.getItem("csgoNum");
        csgoPass = localStorage.getItem("csgoPass");
        localStorage.setItem("csgoNum", csgoNum);
        localStorage.setItem("csgoPass", csgoPass);
        localStorage.setItem("loginChecked", loginChecked);
      }
      if(language&&save){
        // 保留语言和保存设置
        localStorage.setItem("lang", language);
        localStorage.setItem("save", save);
      }

      // 清空状态中的 token
      state.token = "";
    },
    setLanguage(state, language) {
      state.currentLanguage = language;
      localStorage.setItem("lang", language);
    },
  },
  actions: {
    changeLanguage({ commit }, language) {
      commit("setLanguage", language);
    },
  },
  modules: {},
});

//export default store
