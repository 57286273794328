<template>
  <div class="center-navs">
    <div
      class="list-item"
      v-for="item in list"
      :key="item.path"
      @click="goTo(item.path)"
    >
      <img :src="item.img" alt="" srcset="" />
      <span>{{ item.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          img: require("@/assets/img/index/80a6e583a785e2c1ed7273c689071d8.png"),
          title: "Roll房",
          path: "Arena",
        },
        {
          img: require("@/assets/img/index/mianfeibox.png"),
          title: "免费宝箱",
          path: "/Activity",
        },
        {
          img: require("@/assets/img/index/timebox.png"),
          title: "时间盲盒",
          path: "/TimeBox",
        },
        {
          img: require("@/assets/img/index/ba353ca4b75dd27160fc7eed308a4e2.png"),
          title: "我的账单",
          path: "/Bill",
        },
        {
          img: require("@/assets/img/index/8ead00d4d79ac5e628e348dc0d0bb02.png"),
          title: "邀请好友",
          path: "Spread",
        },
      ],
    };
  },
  methods: {
    goTo(path) {
      this.$router.push({
        path,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.center-navs {
  height: 120px;
  color: #fff;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  // white-space: nowrap;
  .list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    img {
      width: 61.36px;
      height: 61.36px;
    }
    span {
      font-size: 12px;
      margin-top: 5px;
    }
  }
}
</style>
