<template>
  <div class="homebot">
    <div class="top" >
      <div class="ng-binding">
        <b>csgoop.com</b> {{ $t('footer.copyright') }}.
      </div>
      <div class="ng-binding"  @click="fun(Clause)">
        <span>{{ $t('footer.service') }}</span>
      </div>
      <div class="ng-binding" @click="fun(Abouts)">
        <span>{{ $t('footer.about') }}</span>
      </div>

    </div>
    <!-- <div class="top" v-else>
      <div class="top-right">
        <img src="../assets/img/weixin_back.png" />
        <img src="../assets/img/zhifubao_back.png" />
      </div>
      <div class="top-left">
        <ul>
          <li v-for="(item, index) in routerList" :key="index">
            <span @click="fun(item.url)">{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <div class="top-bottom">
        <!-- <div class="beian">
					<img src="../assets/img/hui.png" style="width: 12px;" alt="">
					鲁公网安备37160202000550号
					</div> -->
        <!-- <div @click="beian()">备案号: 浙ICP备2021030006号-2</div> -->
        <!-- <div @click="beian()">{{ cont }}</div> -->
        <!-- <div>版权所属：湖北千悦网络科技有限公司</div> -->
      <!-- </div> -->
    <!-- </div> -->
    <!-- <div class="bot">
      <div class="bot-title">友情链接 ：</div>
      <ul>
        <li v-for="(item, index) in urlList" :key="index">
          <span @click="goUrl(item.url)">{{ item.name }}</span>
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowWidth: document.body.clientWidth,
      showNav: false,
      routerList: [
        {
          name: "关于我们",
          url: "Abouts",
        },
        {
          name: "用户协议",
          url: "Agreement",
        },
        {
          name: "隐私条款",
          url: "Privacy",
        },
        {
          name: "协议与条款",
          url: "Clause",
        },
        {
          name: "常见问题",
          url: "Doubt",
        },
        // {
        // 	name: "营业执照",
        // 	url: "Doubt"
        // },
      ],
      urlList: [],
      cont: "",
    };
  },
  mounted() {
    if (this.windowWidth < 1024) {
      this.showNav = false;
    } else {
      this.showNav = true;
    }
    this.$axios({
      url: "/api/index/getsite",
      method: "post",
    }).then((res) => {
      if (res.data.code == 1) {
        this.cont = res.data.data.beian;
        console.log(this.cont, "cont");
      }
    });
  },
  methods: {
    fun(url) {
      this.$router.push({
        // path: `/${url}`,
        path: `/footer`,
      });
    },
    goUrl(url) {
      window.open(url, "_blank");
    },
    goCase() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
    beian() {
      window.open("https://beian.miit.gov.cn/#/home", "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.homebot {
  background-color: #10121c;
  // background-image: url(./img/bottom-bg.png);
  // background-size: 100% 100%;
  padding: 24px 24px 24px 24px;

  .top {
    font-size: 12px;
    // color: #848492;
    color: white;
	display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    .top-left {
      margin-top: 10px;

      ul {
        display: flex;
        flex-wrap: wrap;
        margin-left: 10px;

        li {
          margin-top: 10px;
          padding-right: 10px;
          padding-left: 10px;
          border-right: 1px solid white;

          span {
            white-space: nowrap;
            display: inline-block;
          }
        }

        li:last-child {
          padding-right: 0;
          border-right: none;

          span {
            white-space: nowrap;
            display: inline-block;
          }
        }

        li:hover {
          cursor: pointer;
        }
      }
    }

    .top-right {
      // margin-top: 10px;
      margin-left: 0% !important;

      img {
        width: 100px;
        margin-right: 10px !important;
        margin-left: 12px;
      }
    }
  }

  .bot {
    margin-top: 24px;
    display: flex;
    font-size: 14px;
    color: #515260;
    overflow-y: hidden;

    .bot-title {
      margin-right: 12px;
    }

    ul {
      display: flex;

      li {
        margin-right: 24px;
      }

      li:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #e9b10e;
      }
    }
  }
}

.top-bottom {
  margin-top: 8px;
  width: 100%;
  color: rgba(95, 95, 104, 1);
  text-align: center;
  font-size: 12px;
  div {
    margin-top: 6px;
  }
}

.beian {
  display: flex;
  align-content: center;
  justify-content: center;

  img {
    margin-right: 4px;
  }
}

// @media screen and (max-width: 767px) {
//   .homebot {
//     background-color: #30313f;
//     padding: 0 24px 24px 24px;

//     .top {
//       font-size: 5px;
//       // color: #848492;
//       color: white;
//       display: flex;
//       justify-content: center;
//       flex-direction: column;
//       align-items: center !important;

//       .top-left {
//         margin-top: 10px;

//         ul {
//           display: flex;
//           flex-wrap: wrap;
//           margin-left: 10px;
//           li {
//             margin-top: 10px;
//             padding-left: 0;
//             padding-right: 8px;
//             border-right: 1px solid white;
//             font-size: 7px !important;
//             -webkit-text-size-adjust: none;

//             span {
//               white-space: nowrap;
//               display: inline-block;
//               -webkit-text-size-adjust: none;
//               font-size: 7px !important;
//             }
//           }

//           li:last-child {
//             padding-right: 0;
//             border-right: none;

//             span {
//               white-space: nowrap;
//               display: inline-block;
//             }
//           }

//           li:hover {
//             cursor: pointer;
//           }
//         }
//       }

//       .top-right {
//         // margin-top: 10px;
//         margin-left: 0% !important;

//         img {
//           margin-right: 10px !important;
//         }
//       }
//     }

//     .bot {
//       margin-top: 24px;
//       display: flex;
//       font-size: 14px;
//       color: #515260;
//       overflow-y: hidden;

//       .bot-title {
//         margin-right: 12px;
//       }

//       ul {
//         display: flex;

//         li {
//           margin-right: 24px;
//         }

//         li:hover {
//           cursor: pointer;
//           text-decoration: underline;
//           color: #e9b10e;
//         }
//       }
//     }
//   }
// }
.ng-binding:first-child{
	color: #727989;
}
.ng-binding{
	padding-bottom: 10px;
}
</style>