import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI, { Message } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/stylesheet.css"
import Loading from "./components/Loading.vue";

import { i18n } from "../i18n/index";
const bus = new Vue();
Vue.prototype.$bus = bus;

import Axios from "axios";
Vue.config.productionTip = false;
// step2：把axios挂载到vue的原型中，在vue中每个组件都可以使用axios发送请求,
// 不需要每次都 import一下 axios了，直接使用 $axios 即可
Vue.prototype.$axios = Axios;

// step3：使每次请求都会带一个 /api 前缀  // https://YYCSGO.com/
// Axios.defaults.baseURL = 'https://YYCSGO.com/'    // '/api'   https://ahyltt.com.com/
Axios.defaults.baseURL = "/";
Axios.defaults.timeout = 60000;
Axios.defaults.retry = 3;
let requestStartTime = 0; // 用于记录请求开始的时间
let isLoading = false;
Axios.interceptors.request.use(
  (config) => {
    requestStartTime = Date.now(); // 设置请求开始的时间
    isLoading = true; // 开始加载
    const excludeLoadingUrls = [
      "/api/index/lately",
      "/api/player/playerdata",
      "/api/box/buyboxfake",
      "/api/welfare/openbox_reward_info",
      "/api/box/boxinfo",
      "/api/rucksack/exchangetomoney",
      "/api/store/skinlist",
      "/api/exchange/exchange",
      "/api/rechargetime/rechargetimelist"
    ];
    if (!excludeLoadingUrls.includes(config.url)) {
      // 显示 loading
      bus.$emit("show-loading");
    }
    // const token = store.state.token
    const token = localStorage.getItem("token");
    if (config.url != "/api/index/lately" || token) {
      store.commit("openLoading", true);
    }
    if (token) {
      config.headers["token"] = token;
    }
    return config;
  },
  (config) => {
    return Promise.reject(config);
  }
);

// 响应拦截器
Axios.interceptors.response.use(
  (response) => {
    const elapsed = Date.now() - requestStartTime; // 计算请求所花费的时间

    let pendingResponse = response; // 先暂存响应结果

    if (elapsed < 1000) {
      // 如果请求完成得很快
      // 使用setTimeout来延迟关闭loading，并返回响应结果
      return new Promise((resolve) => {
        setTimeout(() => {
          if (isLoading) {
            // 只有当isLoading为true时才关闭loading
            bus.$emit("hide-loading");
            store.commit("closeLoading", false);
            isLoading = false; // 重置状态
          }
          resolve(pendingResponse); // 返回暂存的响应结果
        }, 1000 - elapsed);
      });
    } else {
      // 请求耗时较长，直接关闭loading并返回响应结果
      bus.$emit("hide-loading");
      store.commit("closeLoading", false);
      isLoading = false; // 重置状态
      return response;
    }
  },
  (error) => {
    const elapsed = Date.now() - requestStartTime; // 计算请求所花费的时间

    if (elapsed < 1000) {
      // 如果请求完成得很快
      // 使用setTimeout来延迟关闭loading
      setTimeout(() => {
        if (isLoading) {
          // 只有当isLoading为true时才关闭loading
          bus.$emit("hide-loading");
          isLoading = false; // 重置状态
        }
      }, 1000 - elapsed);
    } else {
      // 请求耗时较长，直接关闭loading
      bus.$emit("hide-loading");
      isLoading = false; // 重置状态
    }

    // 处理错误逻辑...
    if (error.response && error.response.status === 401) {
      store.commit("getLogin", true);
      store.commit("getLogins", false);
      store.commit("loginOut");
    }

    return Promise.reject(error);
  }
);

import qs from "qs";
// Vue.prototype.$ajax = axios // 把axios换成$ajax变量
Vue.prototype.$qs = qs;

//修改网页标题
Vue.directive("title", {
  inserted: function (el, binding) {
    document.title = el.dataset.title;
  },
});
Vue.config.productionTip = false;
Vue.use(ElementUI);
// 暴露 i18n 实例作为全局变量
window.i18n = i18n;
Vue.filter('fullPath', (path) => {
  if (typeof path === 'string' && path.trim()) {
    const baseUrl = 'http://192.151.196.58';
    // 检查 path 是否以 http 或者 https 开头
    if (/^(http|https):\/\//i.test(path)) {
      return path.trim();
    } else {
      return baseUrl + path.trim();
    }
  }
  return ''; // 返回空字符串或默认值
});
new Vue({
  router,
  store,
  i18n,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
