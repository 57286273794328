<template>
  <div class="main">
    <div class="container">
      <!-- 标题 -->
      <div class="upgrade-title">
        {{ $t("升级你的物品") }}
      </div>
      <!-- 升级内容中心 -->
      <div class="upgrade__body"></div>
      <!-- 电脑端 -->
      <div class="top">
        <div class="title">
          <span>{{ $t("您将获得的价格区间") }}</span>
        </div>
        <div class="min-max">
          <span
            ><span>{{ $t("最高") }}</span
            ><span style="margin-left: 3px; color: #fff">{{
              2 * allPrice
            }}</span></span
          >
          <span style="margin-left: 5px"
            ><span>{{ $t("最低") }}</span
            ><span style="margin-left: 3px; color: #fff">{{
              0.5 * allPrice
            }}</span></span
          >
        </div>
        <div class="upgrade__selected-container">
          <!-- 为空 -->
          <div class="upgrade__selected" v-if="addList.length == 0">
            <img src="../assets/img/weapons.svg" alt="" />
            <h3>{{ $t("选择你的物品") }}</h3>
            <h4>{{ $t("要升级的物品") }}</h4>
          </div>
          <div class="upgrade__add" v-else>
            <template v-if="showNav">
              <div
                class="upgrade__add-item"
                v-for="(item, index) in addList"
                :key="index"
              >
                <img :src="item.img" alt="" />
                <svg
                  viewBox="0 0 152 152"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="absolute top-1/2 left-1/2 w-8/12 -translate-x-1/2 -translate-y-1/2 h-8/12"
                >
                  <path
                    d="M11.1821 38.5774L76 1.1547L140.818 38.5774V113.423L76 150.845L11.1821 113.423V38.5774Z"
                    stroke="url(#board-grade-11-paint0)"
                    stroke-width="2"
                  ></path>
                  <path
                    opacity="0.25"
                    d="M76.0001 29.5562L116.222 52.7784V99.2228L76.0001 122.445L35.778 99.2228V52.7784L76.0001 29.5562Z"
                    fill="url(#board-grade-11-paint1)"
                  ></path>
                  <defs>
                    <linearGradient
                      id="board-grade-11-paint0"
                      x1="76"
                      y1="0"
                      x2="76"
                      y2="152"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#4b69ff" stop-opacity="0"></stop>
                      <stop offset="1" stop-color="#4b69ff"></stop>
                    </linearGradient>
                    <linearGradient
                      id="board-grade-11-paint1"
                      x1="76.0001"
                      y1="29.5562"
                      x2="76.0001"
                      y2="122.445"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#4b69ff" stop-opacity="0"></stop>
                      <stop offset="1" stop-color="#4b69ff"></stop>
                    </linearGradient>
                  </defs>
                </svg>
                <div class="close" @click="add(item)">
                  <svg
                    class="close-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="upgrade__add-item"
                v-for="(item, index) in addList"
                :key="index"
                @click="add(item)"
              >
                <img :src="item.img" alt="" />
                <svg
                  viewBox="0 0 152 152"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="absolute top-1/2 left-1/2 w-8/12 -translate-x-1/2 -translate-y-1/2 h-8/12"
                >
                  <path
                    d="M11.1821 38.5774L76 1.1547L140.818 38.5774V113.423L76 150.845L11.1821 113.423V38.5774Z"
                    stroke="url(#board-grade-11-paint0)"
                    stroke-width="2"
                  ></path>
                  <path
                    opacity="0.25"
                    d="M76.0001 29.5562L116.222 52.7784V99.2228L76.0001 122.445L35.778 99.2228V52.7784L76.0001 29.5562Z"
                    fill="url(#board-grade-11-paint1)"
                  ></path>
                  <defs>
                    <linearGradient
                      id="board-grade-11-paint0"
                      x1="76"
                      y1="0"
                      x2="76"
                      y2="152"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#4b69ff" stop-opacity="0"></stop>
                      <stop offset="1" stop-color="#4b69ff"></stop>
                    </linearGradient>
                    <linearGradient
                      id="board-grade-11-paint1"
                      x1="76.0001"
                      y1="29.5562"
                      x2="76.0001"
                      y2="122.445"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#4b69ff" stop-opacity="0"></stop>
                      <stop offset="1" stop-color="#4b69ff"></stop>
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </template>
          </div>
        </div>

        <div class="total">
          <span
            ><span>{{ $t("总件数") }}</span
            ><span style="margin: 0px 3px; color: #fff">{{
              addList.length
            }}</span
            ><span style="color: #fff">/</span
            ><span style="margin-left: 3px; color: #fff">9</span></span
          >
          <span style="margin-left: 5px"
            ><span>{{ $t("总价值") }}</span
            ><span style="color: #fff; margin-left: 3px">{{
              allPrice
            }}</span></span
          >
        </div>
      </div>
      <div class="button goAdd">
        <span @click="goAdd"
          >{{ $t("升级按钮") }}
          <svg
            data-v-eebb099b=""
            class="w-4.5 h-4.5"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              data-v-eebb099b=""
              d="M5.99316 3.48657H13.531V11.0421"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              data-v-eebb099b=""
              d="M13.531 3.48657L3.46875 13.5488"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path></svg
        ></span>
      </div>
      <div class="boxlist">
        <!-- 标题 -->
        <div class="title">
          {{ $t("你的物品") }}<span>({{ total }})</span>
        </div>
        <div class="boxs">
          <template v-if="$store.state.loginStates">
            <div
              ref="box"
              class="box"
              v-for="item in boxList"
              :key="item.id"
              :style="[
                { '--ww': boxWidth + 'px' },
                { 'border-top-color': item.rarityColor },
                {
                  opacity:
                    addList.length >= 9 && !selectBox.includes(item.id)
                      ? '0.5'
                      : '1',
                },
              ]"
            >
              <div class="price">
                <img src="./img/money.png" alt="" />
                <span>{{ item.price }}</span>
              </div>
              <div class="wuqi">
                <img :src="item.img" alt="" />
              </div>
              <div class="description">
                <div class="c2">{{ item.shortName }}</div>
                <div class="c1">{{ item.exteriorName }}</div>
              </div>
              <div
                class="transition-opacity delete"
                v-show="selectBox.includes(item.id)"
                @click="add(item)"
              >
                <img src="../assets/img/item-selected.svg" alt="" />
              </div>
              <div
                class="transition-opacity add"
                v-show="!selectBox.includes(item.id) && addList.length < 9"
                @click="add(item)"
              >
                <img src="../assets/img/item-select.svg" alt="" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="no-login">
              <svg
                data-v-3d1441ac=""
                class="mb-4"
                width="26"
                height="28"
                viewBox="0 0 26 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  data-v-3d1441ac=""
                  d="M22.3333 10.3334H3.66667C2.19391 10.3334 1 11.5273 1 13V23.6667C1 25.1395 2.19391 26.3334 3.66667 26.3334H22.3333C23.8061 26.3334 25 25.1395 25 23.6667V13C25 11.5273 23.8061 10.3334 22.3333 10.3334Z"
                  stroke="#8494A7"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  data-v-3d1441ac=""
                  d="M5 5C5 3.93913 5.42143 2.92172 6.17157 2.17157C6.92172 1.42143 7.93913 1 9 1H17C18.0609 1 19.0783 1.42143 19.8284 2.17157C20.5786 2.92172 21 3.93913 21 5V10.3333H5V5Z"
                  stroke="#8494A7"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <p>{{ $t("登录查看你的库存") }}</p>
              <div @click="goLogin" class="steam-login"
                ><svg
                  data-v-397f479b=""
                  class="svg-inline--fa fa-steam-symbol fa-lg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="steam-symbol"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M395.5 177.5c0 33.8-27.5 61-61 61-33.8 0-61-27.3-61-61s27.3-61 61-61c33.5 0 61 27.2 61 61zm52.5.2c0 63-51 113.8-113.7 113.8L225 371.3c-4 43-40.5 76.8-84.5 76.8-40.5 0-74.7-28.8-83-67L0 358V250.7L97.2 290c15.1-9.2 32.2-13.3 52-11.5l71-101.7c.5-62.3 51.5-112.8 114-112.8C397 64 448 115 448 177.7zM203 363c0-34.7-27.8-62.5-62.5-62.5-4.5 0-9 .5-13.5 1.5l26 10.5c25.5 10.2 38 39 27.7 64.5-10.2 25.5-39.2 38-64.7 27.5-10.2-4-20.5-8.3-30.7-12.2 10.5 19.7 31.2 33.2 55.2 33.2 34.7 0 62.5-27.8 62.5-62.5zm207.5-185.3c0-42-34.3-76.2-76.2-76.2-42.3 0-76.5 34.2-76.5 76.2 0 42.2 34.3 76.2 76.5 76.2 41.9.1 76.2-33.9 76.2-76.2z"
                  ></path></svg
                >{{ $t("通过Steam登录") }}</div
              >
            </div>
          </template>
        </div>
        <div class="pagination">
          <pagination
            v-if="total > 0"
            :total="total"
            :currentPage="page"
            :perPage="pageSize"
            :showNav="showNav"
            @current-change="currentChange"
          ></pagination>
        </div>
      </div>
    </div>
    <div class="win-box" v-if="winInfo.length > 0">
      <mywin
        :winList="winInfo"
        :isis="false"
        @winexchange="fenjie($event)"
        @winget="closeDiag($event)"
        @winX="closeDiag($event)"
      ></mywin>
    </div>
  </div>
</template>

<script>
import mywin from "@/components/my_win.vue";
import MyHomebot from "./my_homebot.vue";
import pagination from "@/components/pagination.vue";
export default {
  components: {
    mywin,
    MyHomebot,
    pagination,
  },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      close: false,
      isSelect: false,
      page: 1,
      pageSize: 14,
      total: 0,
      boxList: [],
      addList: [],
      selectBox: [],
      allPrice: 0,
      winInfo: [],
      width: "",
      player_skins_ids: "",
      winState: false,
      boxWidth: 180,
      content: "",
      language:
        localStorage.getItem("lang") ||
        this.$store.state.currentLanguage ||
        "zh",
         code: ""
    };
  },
  mounted() {
    this.getInfo(this.page);
    this.width = document.body.clientWidth;
    this.$nextTick(() => {
      this.boxWidth = this.$refs.box[0].clientWidth;
    });
    this.getRule();
    let codeInfo = this.GetUrlParam("code");
    this.code = codeInfo ? codeInfo.split("#")[0] : "";
    this.inputCode = this.code ? false : true;
  },
  methods: {
    getRule() {
      this.$axios({
        url: "/api/index/ruletextlist",
        method: "post",
      }).then((res) => {
        if (res.data.code == 1) {
          let data = res.data.data;
          for (let i = 0; i < data.length; i++) {
            if ("汰换".includes(data[i].name)) {
              this.content = data[i].center;
              return;
            }
          }
        }
      });
    },
    goRule() {
      this.$router.push({ name: "Rule", params: { title: "汰换" } });
    },
    winexchange(msg) {
      this.winState = msg;
    },
    winget(msg) {
      this.winState = msg;
    },
    winX(msg) {
      this.winState = msg;
    },
    // 分解饰品
    fenjie() {
      this.$axios({
        url: "/api/rucksack/exchangetomoney",
        method: "post",
        data: { player_skins_ids: [this.player_skins_ids].join() },
      }).then((res) => {
        if (res.data.code == 1) {
          console.log(res.data);
          this.$message({
            message:
              this.language == "zh" ? res.data.msg : res.data.english_msg,
            type: "success",
            customClass: "message-styles",
          });
          this.winInfo = [];
          this.getInfo(1);
        } else {
          this.$message({
            message:
              this.language == "zh" ? res.data.msg : res.data.english_msg,
            type: "error",
            customClass: "message-styles",
          });
          this.winInfo = [];
        }
      });
    },
    closeDiag() {
      this.winInfo = [];
      this.winState = false;
      this.getInfo(1);
    },
    // 获取页面数据
    getInfo(page) {
      let id = JSON.parse(localStorage.getItem("userInfo")).id;
      this.$axios({
        url: "/api/rucksack/packagelist",
        method: "post",
        data: { page: page, pagesize: this.pageSize },
      }).then((res) => {
        if (res.data.code == 1) {
          this.boxList = res.data.data.data;
          this.total = res.data.data.total;
        }
      });
    },
    // 页码切换
    currentChange(index) {
      this.getInfo(index);
    },
    // 点击箱子将箱子添加到合成
    add(boxInfo) {
      if (this.selectBox.includes(boxInfo.id)) {
        // 如果已经选择箱子了，则点击取消选择
        let arrs = this.selectBox;
        for (let i = 0; i < arrs.length; i++) {
          if (arrs[i] == boxInfo.id) {
            arrs.splice(arrs.indexOf(arrs[i]), 1);
          }
        }
        // 将箱子从添加里面删除
        let addArr = this.addList;
        for (let i = 0; i < addArr.length; i++) {
          if (addArr[i].id == boxInfo.id) {
            // 删除箱子时减去先箱子的价格
            this.allPrice = (
              Number(this.allPrice) - Number(addArr[i].price)
            ).toFixed(2);
            addArr.splice(addArr.indexOf(addArr[i]), 1);
          }
        }
      } else {
        // 如果没有选择箱子，则加入到选择的箱子中
        if (this.selectBox.length >= 9) {
          // 如果选择的箱子达到了最大值，则提示用户，不再添加
          this.$message({
            message: $t("最多只能添加9个饰品"),
            type: "error",
            customClass: "message-styles",
          });
        } else {
          this.selectBox.push(boxInfo.id);
          this.addList.push(boxInfo);
          // 计算出所有饰品的总价值
          let box = this.addList;
          this.allPrice = 0;
          for (let i = 0; i < box.length; i++) {
            this.allPrice = (
              Number(this.allPrice) + Number(box[i].price)
            ).toFixed(2);
          }
        }
      }
      // console.log(this.selectBox)
      // console.log(this.addList,'addList')
    },
    // 合成饰品
    goAdd() {
      let id = this.selectBox.join();
      this.$axios({
        url: "/api/exchange/exchange",
        method: "post",
        data: { ids: id },
      }).then((res) => {
        if (res.data.code == 1) {
          // this.winInfo.skins_info.push(res.data.data);
          // console.log(this.winInfo.skins_info,'测试数据');
          this.winInfo.push(res.data.data);
          console.log(this.winInfo, "测试数据");
          this.player_skins_ids = res.data.data.player_skins_id;
          this.selectBox = [];
          this.addList = [];
          this.allPrice = 0;
          this.$message({
            message:
              this.language == "zh" ? res.data.msg : res.data.english_msg,
            type: "success",
            customClass: "message-styles",
          });
        } else {
          this.$message({
            message:
              this.language == "zh" ? res.data.msg : res.data.english_msg,
            type: "error",
            customClass: "message-styles",
          });
        }
      });
    },
    goLogin(){
      this.$store.commit("getLogin", true);
      let params = {
        invite_code: this.code,
      };
      this.$axios
        .post("/api/login/getsteamloginurl", this.$qs.stringify(params))
        .then((res) => {
          if (res.data.code == 1) {
            window.location.href = res.data.data.url;
          }
        });
    }
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-input__inner {
    background-color: #131723;
    border: none !important;
    color: #c3c3e2;
    width: 255px;
    height: 50px;
    margin-left: 4px;
    padding: 17px 24px 17px 35px;
  }
}
.main {
  width: 100%;
  background-color: #1e2336;
}
.container {
  width: 100%;
  // height: calc(100vh - 175px);
  background-color: #1e2336;
  padding: 4rem 3.5rem;
  box-sizing: border-box;
  font-size: 0.9375rem;
  .upgrade-title {
    text-align: center;
    color: #fff;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
  }
  .top-title {
    width: 100%;
    height: 40px;
    background-color: #181c27;
    border-radius: 5px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 0 15px;
    box-sizing: border-box;
    .tai {
      img {
        width: 15px;
        height: auto;
        margin-right: 3px;
      }
    }
    .rule {
      display: flex;
      align-items: center;
      img {
        width: 15px;
        height: auto;
        margin-right: 3px;
      }
      span {
        font-size: 14px;
      }
    }
  }
  .ruleBox {
    width: 530px;
    height: 480px;
    padding-bottom: 30px;
    background-image: url(./img/box-bg.jpg);
    background-size: 100% 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    border-radius: 10px;
    @media (max-width: 550px) {
      width: 90vw;
    }
    .cancel {
      position: absolute;
      top: 10px;
      right: 13px;
      transition: 0.4s;
      cursor: pointer;
      &:hover {
        transform: rotate(90deg);
      }
      img {
        width: 16px;
        height: auto;
      }
    }
    .title {
      font-size: 22px;
      margin-top: 40px;
    }
    .con {
      overflow-y: scroll;
      padding: 0 100px;
      @media (max-width: 550px) {
        padding: 0 50px;
      }
      p {
        margin-top: 20px;
      }
    }
  }
  .top {
    width: 100%;
    min-height: 300px;
    background-color: #171c2b;
    border-radius: 0.5rem;
    padding: 25px 20px;
    box-sizing: border-box;
    color: white;
    position: relative;
    box-sizing: border-box;
    .title {
      margin-bottom: 0.5rem;
      span {
        // font-size: 26px;
        font-weight: 400;
      }
    }
    .min-max {
      color: #7f8291;
    }
    .rule {
      position: absolute;
      top: 20px;
      right: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 20px;
        height: auto;
        margin-right: 5px;
      }
    }
    .add {
      width: 100%;
      height: 140px;
      margin-top: 8px;
      // margin-left: 105px;
      margin-left: 5vw;
      @media (max-width: 550px) {
        height: auto;
        margin-left: 0;
        .money {
          font-size: 12px !important;
        }
      }
      ul {
        margin-left: 10px;
        display: flex;
        @media (max-width: 550px) {
          flex-wrap: wrap;
          margin-left: 5px;
        }
        li {
          width: 7.29vw;
          // width: 140px;
          // height: 140px;
          height: 7.29vw;
          border: 1px solid #555;
          border-radius: 5px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          @media (max-width: 550px) {
            width: 100px;
            height: 100px;
            margin-right: 0;
            margin: 3px;
          }
          .box {
            // margin: 7.2px 7.2px;
            width: 140px;
            height: 140px;
            background-image: url(./img/boxlist-bg.png);
            background-size: 125% auto;
            background-position: center;
            border-radius: 10px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            box-sizing: border-box;
            cursor: pointer;

            .price {
              position: absolute;
              top: 5px;
              right: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 15px;
                height: auto;
              }
            }
            .wuqi {
              img {
                width: 100px;
                height: auto;
              }
            }
            .description {
              position: absolute;
              left: 5px;
              bottom: 5px;
              .c1 {
                color: #a9abbc;
                font-size: 12px;
              }
              .c2 {
              }
            }
          }
          .money {
            width: 40px;
            font-size: 18px;
            color: #555;
            position: absolute;
          }
        }
      }
    }
    .total {
      color: #7f8291;
      margin-top: 5px;
    }
    .button {
      // margin-top: 5px;
      width: 100%;
      text-align: center;

      span {
        background-color: #ffc600;
        color: black;
        font-size: 18px;
        padding: 7px 50px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
  .boxlist {
    width: 100%;
    min-height: 740px;
    background-color: #292f47;
    border-radius: 7px;
    margin-top: 20px;
    padding: 25px;
    box-sizing: border-box;
    position: relative;
    .title {
      font-size: 1.125rem;
      line-height: 3rem;
      font-weight: 500;
      color: #eaeaed;
      height: 3rem;
      margin-bottom: 1rem;
      span {
        color: #9497a3;
      }
    }

    .pagination {
      // position: absolute;
      // left: 50%;
      // bottom: 40px;
      // transform: translate(-50%, 0);
    }
    .active {
      border: 1px solid #f5d016;
    }
    .boxs {
      width: 100%;
      display: flex;
      // justify-content: center;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
    }
    .box {
      margin: 0.37vw;
      // width: 9.3vw;
      // height: 9.3vw;
      width: calc(100% / 7 - 0.75vw);
      // height: 180px;
      height: 13.5vw;
      // height: var(--ww);
      // background-image: url(./img/boxlist-bg.png);
      // background-size: 125% auto;
      // background-position: center;

      // border-radius: 10px;
      background: #131723cc;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      box-sizing: border-box;
      cursor: pointer;
      border-top: 2px solid #fff;
      // flex-direction: column;
      padding: 1.25rem;
      box-sizing: border-box;
      .price {
        position: absolute;
        top: 5px;
        right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 15px;
          height: auto;
        }
      }
      .wuqi {
        img {
          width: 140px;
          height: auto;
        }
      }
      .description {
        width: 80%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        position: absolute;
        // left: 5px;
        bottom: 0.6rem;
        .c1 {
          color: #a9abbc;
          font-size: 12px;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .c2 {
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
.bottom {
  width: 100%;
  height: 100px;
  background-color: red;
}

/deep/ .el-pagination {
  display: flex;
}

/deep/ .el-pagination.is-background .el-pager {
  display: flex;
}

/deep/ .el-pagination.is-background .el-pager li {
  width: 35px;
  height: 33px;
  margin-top: -4px;
  padding: 0;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ffc600;
  color: black;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  background-color: #ffc600;
  color: black;
}
/deep/ .el-pagination .btn-next .btn-prev,
.el-pagination .btn-prev .el-icon {
  background-color: #333542;
}
/deep/ .el-pagination .btn-next,
.el-pagination .btn-prev {
  background-color: #181c27;
  border: none !important;
}
/deep/ .el-pager li.active,
.el-pager {
  color: black !important;
}
/deep/ .el-pagination.is-background .btn-next {
  background-color: #181c27 !important;
  border: none !important;
  width: 40px !important;
}
/deep/ .el-pagination.is-background .btn-prev {
  background-color: #181c27 !important;
  border: none !important;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 24px;
}
/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 24px;
}

//中奖后盒子
.win-box {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  display: grid;
  place-items: center;
}

.win-conm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .win-tips {
    height: 60px;
    color: #fff;
    font-size: 16px;
    line-height: 60px;
    font-weight: bold;
  }
}
// .win {
//   width: 450px;
//   padding: 20px;
//   // background-color: #1c2230;
//   position: relative;
//   box-sizing: border-box;
//   border-radius: 10px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   @media (max-width: 550px) {
//     width: 100vw;
//   }
//   .win-conms {
//     display: flex;
//     flex-direction: column !important;
//     align-items: center;
//     justify-content: center;
//     .img {
//       width: 220px;
//       height: 220px;
//       background-image: url(./img/box-skins-golden.jpg);
//       background-size: 100% 100%;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//     }
//     img {
//       width: 100px;
//       height: auto;
//     }
//   }
//   .win-span2 {
//     width: 220px;
//     padding: 10px 0;
//     height: 20px;
//     // background-color: #e9b10e;
//     background-color: #80c00c;
//     background-size: 100% 100%;
//     border: 1px solid #80c00c;
//     color: white;
//     display: flex;
//     font-weight: bold;
//     justify-content: center;
//     align-items: center;
//     border-radius: 5px;
//     margin-top: 15px;
//     font-size: 14px;
//   }
//   .fen {
//     background-color: rgba(255, 255, 255, 0.8);
//     color: white;
//     span {
//       color: red;
//     }
//   }

//   .win-span2:hover {
//     cursor: pointer;
//   }
// }
// .zhe {
//   width: 100vw;
//   height: 100vh;
//   position: fixed;
//   top: 0;
//   left: 0;
//   background-color: rgba(0, 0, 0, 0.6);
//   z-index: 999;
// }
.upgrade__selected-container {
  text-align: center;
  .upgrade__selected {
    h3 {
      color: #eaeaed;
      font-size: 0.9375rem;
      line-height: 1.375rem;
      font-weight: 500;
      margin-top: 1.5rem;
    }
    h4 {
      color: #7f8291;
      font-size: 0.8125rem;
      line-height: 1.125rem;
    }
  }
  .upgrade__add {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    width: 100%;
    flex-wrap: wrap;
  }
  .upgrade__add .upgrade__add-item {
    filter: brightness(1.25) saturate(1.25);
    max-height: 14rem;
    height: 10rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    &:hover {
      cursor: pointer;
      .close {
        opacity: 1;
      }
    }
    .close {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #10111acc;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      z-index: 20;
      padding: 0.5rem;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 0.2s;
      opacity: 0;
      // opacity: 0.8;
      .close-icon {
        width: 3rem;
        height: 3rem;
        opacity: 0.8;
      }
    }
    img {
      position: relative;
      height: 100%;
      z-index: 10;
    }
    .absolute {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 3rem;
      height: 3rem;
    }
  }
}
.goAdd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  span {
    display: block;
    background-image: linear-gradient(
      90deg,
      transparent,
      rgba(61, 90, 254, 25%)
    );
    border: 2px solid #3d5afe;
    letter-spacing: 0.025em;
    border-radius: 99vw;
    margin-bottom: 2rem;
    padding: 0.625rem 3.5rem;
    letter-spacing: 0.025em;
    color: #fff;
    &:hover {
      cursor: pointer;
      background-color: #3d5afe;
    }
  }
}

.transition-opacity {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #131723cc;

  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 68px;
  }
}
.transition-opacity.add {
  opacity: 0;
}
.boxlist .boxs .box:hover {
  .transition-opacity.add {
    cursor: pointer;
    opacity: 1;
  }
}
.no-login {
  width: 100%;
  color: #7f8291;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  p {
    margin: 1.6rem 0;
  }
  .steam-login {
    display: flex;
    color: #fff;
    text-decoration: none;
    padding: 0.625rem 1.5rem;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.1s;
    border: 2px solid #27ae60;
    border-radius: 99px;
    align-items: center;
    background: linear-gradient(to right, rgba(39, 174, 96, 0) 0%, rgba(39, 174, 96, 0.3) 100%);
    svg {
      font-size: 1.25em;
      line-height: 0.05em;
      width: 1.2rem;
      margin: 0rem 0.4rem;
    }
  }
}
@media (max-width: 764px) {
  .container {
    padding: 2rem 1.5rem;
  }
  .upgrade__selected {
    height: 16rem;
  }
  .total {
    text-align: center;
    // letter-spacing: 0.3rem;
  }
  .container .boxlist .box {
    width: calc(100% / 2 - 4vw);
    height: 53.5vw;
    margin: 2vw;
  }
  .container .boxlist .title {
    margin-bottom: 0rem;
    padding-left: 2vw;
  }
  .container .boxlist {
    padding: 4vw 1vw;
  }
  .upgrade__selected-container .upgrade__add .upgrade__add-item {
    height: 7rem;
  }
}
@media (max-width: 414px) {
  .upgrade__selected-container .upgrade__add .upgrade__add-item {
    height: 6rem;
  }
}
</style>