import { render, staticRenderFns } from "./Arena.vue?vue&type=template&id=52d120c0&scoped=true&"
import script from "./Arena.vue?vue&type=script&lang=js&"
export * from "./Arena.vue?vue&type=script&lang=js&"
import style0 from "./Arena.vue?vue&type=style&index=0&id=52d120c0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52d120c0",
  null
  
)

export default component.exports