<template>
  <div class="spread">
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>

    <div class="containers">
      <!-- 标题 -->
      <div class="title1">
        <img src="../assets/img/move-goout.png" alt="" />
      </div>
      <!-- 邀请码 -->
      <div class="invite">
        <div class="me">我的邀请码：</div>
        <div class="cont">
          <span>{{ valueCode }}</span>
          <span class="copy" @click="copyText(valueCode)">复制</span>
        </div>
      </div>
      <!-- 推广链接 -->
      <div class="goout">
        <span class="me">推广链接：</span>
        <div class="cont">
          <span>{{ valueUrl }}</span>
          <span class="copy" @click="copyText(valueUrl)">复制</span>
        </div>
      </div>
      <!-- 基本数据 -->
      <div class="data">
        <div class="num">
          <!-- <img src="../../../assets/img/num.png" alt=""> -->
          <span>邀请人数</span><span class="nums">{{ value1 }}</span>
        </div>
        <div class="awart">
          <!-- <img src="../../../assets/img/awart.png" alt=""> -->
          <span>累计奖励</span
          ><span class="nums"
            ><img src="../assets/img/money.png" alt="" />{{ value3 }}</span
          >
        </div>
        <div class="proportion">
          <!-- <img src="../../../assets/img/proporation.png" alt=""> -->
          <span>奖金比列</span><span class="nums">{{ value2 }}</span>
        </div>
      </div>
      <!-- 中间数据展示部分 -->
      <div class="content">
        <!-- 切换按钮 -->
        <div class="change">
          <span :class="activated==1 ? 'active' : ''" @click="change(1)"
            >邀请记录</span
          >
          <span :class="activated==2 ? 'active' : ''" id="right" @click="change(2)"
            >充值奖励</span
          >
          <span :class="activated==3 ? 'active' : ''" id="right" @click="change(3)"
            >消耗奖励</span
          >
        </div>
        <!-- 邀请记录 -->
        <div class="left stys" v-if="activated==1">
          <div class="title">
            <ul class="ul">
              <li>用户名</li>
              <li>累计充值</li>
              <li>累计流水</li>
              <li>绑定方式</li>
              <li>绑定时间</li>
            </ul>
          </div>
          <div class="cont">
            <ul v-for="item in tableData1" :key="item.id">
              <li class="name">
                <div class="user">{{ item.name }}</div>
              </li>
              <li>{{ item.recharge }}</li>
              <li>{{ item.total_amount }}</li>
              <li>{{ item.source }}</li>
              <li>
                <span class="time">{{ item.endTime }}</span>
                <span class="time">{{ item.firstTime }}</span>
              </li>
            </ul>
          </div>
          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              layout="prev, pager, next"
            :total="tableData1.total"
            background
            small
            @current-change="getPageNum1"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            >
            </el-pagination>
          </div>
          <div class="total">
            <div class="s1">
              总充值：<span>{{ allmoney }}</span>
            </div>
            <div class="s2">
              总消耗：<span>{{ allusemoney }}</span>
            </div>
          </div>
        </div>
        <!-- 每日奖励 -->
        <div class="right stys" v-if="activated==2">
          <div class="title">
            <ul class="ul">
              <li>用户名</li>
              <li>充值金额</li>
              <li>比列</li>
              <li>收益</li>
              <li>充值时间</li>
            </ul>
          </div>
          <div class="cont">
            <ul v-for="item in tableData2" :key="item.id">
              <li class="name">
                <div class="user">{{ item.name }}</div>
              </li>
              <li>{{ item.recharge }}</li>
              <li>{{ item.ratio }}</li>
              <li>{{ item.money }}</li>
              <li>
                <span class="time">{{ item.endTime }}</span>
                <span class="time">{{ item.firstTime }}</span>
              </li>
            </ul>
          </div>
          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              layout="prev, pager, next"
            :total="tableData2.total"
            background
            small
            @current-change="getPageNum1"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 消耗奖励 -->
        <div class="right stys" v-if="activated==3">
          <div class="title">
            <ul class="ul">
              <li>用户名</li>
              <li>消耗金额</li>
              <li>比列</li>
              <li>收益</li>
              <li>消耗时间</li>
            </ul>
          </div>
          <div class="cont">
            <ul v-for="item in tableData3" :key="item.id">
              <li class="name">
                <div class="user">{{ item.name }}</div>
              </li>
              <li>{{ item.recharge }}</li>
              <li>{{ item.ratio }}</li>
              <li>{{ item.money }}</li>
              <li>
                <span class="time">{{ item.endTime }}</span>
                <span class="time">{{ item.firstTime }}</span>
              </li>
            </ul>
          </div>
          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              layout="prev, pager, next"
              :total="tableData2.total"
              background
              small
              :current-page.sync="currentPage"
              @current-change="getPageNum3"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 推广流程 -->
      <div class="goFlow">
        <span>——推广流程——</span>
      </div>
      <!-- 底部 -->
      <div class="footer">
        <div class="left">
          <img src="../assets/img/tui-1.png" alt="" />
        </div>
        <div class="center">
          <img src="../assets/img/tui-2.png" alt="" />
        </div>
        <div class="right">
          <img src="../assets/img/tui-3.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navheader from "@/components/navheader.vue";
export default {
  components: {
    navheader,
  },
  props: ["yidongmenu", "showNav", "openHongbao"],

  data() {
    return {
      // 控制切换按钮状态
      activated: true,
      // 推广链接
      valueUrl: "http://www.gdskins.com",
      // 邀请码
      valueCode: "TRtqaetA",
      tableData1: [],
      tableData2: [],
      tableData3: [],
      allmoney: "",
      allusemoney: "",
      currentPage: 1,
      pageSize: 5,
      total: 0,
      value1: 0,
      value2: 0,
      value3: 0,
      // 绑定方式
      source: "",
      dateList: [
        {
          name: "邀请统计",
          state: false,
          val: 0,
        },
      ],
      payInfoData: {
        flag: true,
        type: "",
        checked2: false,
        checked1: false,
      }, // 充值提示
    };
  },
  mounted() {
    this.getList();
    this.getList1(0);
    this.getPageNum1()
  },
  methods: {
    // 点击邀请记录页码获取数据
    getPageNum1(index) {
      let param = {
        page: index,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("index/Invite/offlinelist", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(res,'oooooooooo')
          this.allmoney = res.data.data.allmoney;
          this.allusemoney = res.data.data.allusemoney;
          // 将日期分成两部分
          let timeData = data.data.list;
          for (let i = 0; i < timeData.length; i++) {
            let firstTime = timeData[i].create_time.substring(0, 10);
            let endTime = timeData[i].create_time.substring(11, 19);
            timeData[i].firstTime = firstTime;
            timeData[i].endTime = endTime;
          }
          timeData.total = data.data.total;
          this.tableData1 = timeData;
        });
    },
    // 点击每日奖励页码获取数据
    getPageNum2(index) {
      let param = {
        page: index,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("index/Invite/offline", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(res,'oooooooooo')
          // this.allmoney = res.data.data.allmoney;
          // this.allusemoney = res.data.data.allusemoney;
          // 将日期分成两部分
          let timeData = data.data.list;
          for (let i = 0; i < timeData.length; i++) {
            let firstTime = timeData[i].create_time.substring(0, 10);
            let endTime = timeData[i].create_time.substring(11, 19);
            timeData[i].firstTime = firstTime;
            timeData[i].endTime = endTime;
          }
          timeData.total = data.data.total;
          this.tableData2 = timeData;
        });
    },

     // 点击消耗奖励页码获取数据
    getPageNum3(index) {
      let param = {
        page: index,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("/index/Invite/waterlist", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(res,'oooooooooo')
          // this.allmoney = res.data.data.allmoney;
          // this.allusemoney = res.data.data.allusemoney;
          // 将日期分成两部分
          let timeData = data.data.list;
          for (let i = 0; i < timeData.length; i++) {
            let firstTime = timeData[i].time.substring(0, 10);
            let endTime = timeData[i].time.substring(11, 19);
            timeData[i].firstTime = firstTime;
            timeData[i].endTime = endTime;
          }
          timeData.total = data.data.total;
          this.tableData3 = timeData;
        });
    },

    // 点击切换按钮切换页面
    change(index) {
      // console.log(index);
      this.currentPage = 1
      if (index == 1) {
        this.activated = 1;
        this.getPageNum1(1)
      } else if(index == 2){
        this.activated = 2;
        this.getPageNum2(1)
      }else if(index == 3) {
        this.activated = 3
        this.getPageNum3(1)
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1; // 切换每页条数时，重置为第一页
      this.getList1(0);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList1(0);
    },
    //挑战推广详情
    goLonger() {
      this.$router.push({
        path: `/SpreadLonger`,
      });
    },
    //我的推广链接和邀请码
    getList() {
      let param = {};
      this.$axios
        .post("index/Invite/inviteinfo", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.valueUrl = data.data.invite_url;
            this.valueCode = data.data.invite_code;
          }
        });
    },
    //我的下线7天/30天/今天/全部
    getList1(days) {
      let param = {
        days: days,
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("index/Invite/offline", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // 将日期分成两部分
          let timeData = data.data.list;
          for (let i = 0; i < timeData.length; i++) {
            let firstTime = timeData[i].time.substring(0, 10);
            let endTime = timeData[i].time.substring(11, 19);
            timeData[i].firstTime = firstTime;
            timeData[i].endTime = endTime;
          }
          timeData.total = data.data.total;
          this.tableData2 = timeData;
          if (data.status == 1) {
            //console.log(data.data.people_num)
            if (data.data.people_num) {
              this.value1 = data.data.people_num;
            }
            if (data.data.ratio) {
              this.value2 = data.data.ratio;
            }
            if (data.data.invite_commission) {
              this.value3 = data.data.invite_commission;
            }

            for (let i = 0; i < this.tableData1.length; i++) {
              if (this.tableData1[i].status == 1) {
                this.tableData1[i].state = "已结算";
              } else {
                this.tableData1[i].state = "未结算";
              }
            }
          }
        });
      this.$axios
        .post("index/Invite/offlinelist", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(res,'oooooooooo')
          this.allmoney = res.data.data.allmoney;
          this.allusemoney = res.data.data.allusemoney;
          // 将日期分成两部分
          let timeData = data.data.list;
          for (let i = 0; i < timeData.length; i++) {
            let firstTime = timeData[i].create_time.substring(0, 10);
            let endTime = timeData[i].create_time.substring(11, 19);
            timeData[i].firstTime = firstTime;
            timeData[i].endTime = endTime;
          }
          timeData.total = data.data.total;
          this.tableData1 = timeData;
        });
    },

    //复制
    copyText(text) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = text; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },

    //我的下线 详情
    getLonger() {
      let param = {};
      this.$axios
        .post("index/Invite/offlineList", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.tableData1 = data.data.list;
          }
        });
    },
    getState(name, val) {
      for (let i = 0; i < this.dateList.length; i++) {
        if (name == this.dateList[i].name) {
          this.dateList[i].state = true;
        } else {
          this.dateList[i].state = false;
        }
      }
      this.getList1(val);
    },
    checkPayInfo() {
      if (this.payInfoData.checked1 && this.payInfoData.checked2) {
        // if (this.payInfoData.type) {
        this.payInfoData.flag = false;
        // }
      } else {
        this.$message.warning("请阅读并勾选所有协议");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.spread {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/bg-move.jpg) !important;
  background-size: 100% 100%;
  background-position: 0px 70px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  /* 兼容Webkit内核浏览器如Chrome和Safari */
  -o-background-size: cover;
  /* 兼容Opera */
  zoom: 1;
  // background-color: red;
}
.containers {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 6px;
  .title1 {
    // margin-left: 50%;
    // transform: translate(0%,-50%);
    img {
      width: 65%;
      height: auto;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translate(-50%, 0%);
      z-index: 99;
    }
  }
  .invite {
    width: 93%;
    height: 30px;
    position: relative;
    margin-left: 50%;
    transform: translate(-50%, 0);
    margin-top: 30px;
    font-size: 16px;
    display: flex;
    align-items: center;
    .me {
      color: white;
      width: 30%;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
    }
    .cont {
      box-sizing: border-box;
      padding-left: 10px;
      font-size: 16px;
      width: 70%;
      height: 100%;
      line-height: 100%;
      color: white;
      background-color: black;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .copy {
        width: 48px;
        height: 100%;
        position: absolute;
        right: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        color: #b000d6;
      }
    }
  }
  .goout {
    width: 93%;
    height: 30px;
    position: relative;
    margin-left: 50%;
    transform: translate(-50%, 0);
    margin-top: 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
    .me {
      color: white;
      margin-left: 4.5%;
      width: 30%;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
    }
    .cont {
      box-sizing: border-box;
      padding-left: 10px;
      font-size: 16px;
      width: 70%;
      height: 100%;
      line-height: 100%;
      margin-left: -4.5%;
      color: white;
      background-color: black;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .copy {
        width: 48px;
        height: 100%;
        position: absolute;
        right: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        color: #b000d6;
      }
    }
  }
  // 基本数据样式
  .data {
    padding: 0 20px;
    background-image: url(../assets/img/move-num-bg.png);
    background-size: 100% 100%;
    font-size: 18px;
    height: 60px;
    display: flex;
    justify-content: space-around;
    color: white;
    margin-top: 20px;
    font-size: 16px;
    padding-top: 3px;
    .nums {
      margin-top: 10px;
      color: #b000d6;
      font-size: 18px;
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
        width: 18px;
        height: auto;
      }
    }
    img {
      display: inline-block;
      width: 25px;
      height: auto;
      margin-right: 2px;
    }
    .num {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .awart {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .proportion {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .content {
    width: 95%;
    height: 442px;
    margin-top: 20px;
    padding: 10px 10px;
    background-color: #151627;
    border: 1px solid #b000d6;
    border-radius: 3px;
    // background-color: yellow;
    .change {
      width: 100%;
      height: 50px;
      text-align: center;
      padding-top: 12px;
      box-sizing: border-box;
      color: white;
      .active {
        background-color: #3699ff;
      }
      span {
        border: 1px solid aqua;
        padding: 5px 8px;
        // color: white;
      }
      #right {
        margin-left: 8px;
      }
    }
    .stys {
      background-color: #151627;
      width: 100%;
      height: 401px;
      .title {
        .ul {
          width: 100%;
          height: 40px;
          background-image: url(../assets/img/mes-invite-bg.png);
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          li {
            flex: 1;
            width: 20%;
            text-align: center;
            color: white;
            font-size: 14px;
          }
        }
      }
      .cont {
        height: 263px;
        ul {
          width: 100%;
          // background-color: #151627 !important;
          height: auto !important;
          display: flex;
          justify-content: center;
          align-items: center;
          .name {
            align-items: flex-start !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .user {
              width: 60px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-align: center;
            }
          }
          li {
            flex: 1;
            color: white;
            height: 50px;
            border-top: 2px solid #1f1847;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            width: 20%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .time {
              font-size: 10px;
              display: block;
            }
          }
        }
      }
      .pagination {
        width: 100%;
        height: 40px;
        margin-top: 10px;
        text-align: center;
      }
      /deep/ .el-pagination.is-background .el-pager li {
        border-radius: 6px;
      }
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #30215f;
      }
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #30215f;
      }
      /deep/ .el-pagination .btn-next .btn-prev,
      .el-pagination .btn-prev .el-icon {
        background-color: #333542;
      }
      /deep/ .el-pager li.active,
      .el-pager {
        color: white !important;
      }
      .total {
        // background-color: red;
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        .s1 {
          color: white;
          span {
            color: #eeb303;
          }
        }
        .s2 {
          margin-left: 100px;
          color: white;
          span {
            color: #ac6cda;
          }
        }
      }
      // 右侧样式
      .right {
        background-color: #151627;
        width: 100%;
        height: 401px;
      }
    }
  }
  // 推广流程样式
  .goFlow {
    width: 100%;
    height: auto;
    margin-top: 20px;
    text-align: center;
    font-size: 22px;
    color: white;
    span {
      text-shadow: 0 0 0.13333rem #7b33d7, 0 0 0.13333rem #a333d7,
        0 0 0.13333rem #3357d7, 0 0 0.13333rem #5e33d7, 0 0 0.13333rem #5e33d7;
    }
  }
  // 底部样式
  .footer {
    margin: 20px 0;
    box-sizing: border-box;
    padding-bottom: 30px;
    text-align: center;
    img {
      width: 320px;
      height: auto;
    }
  }
}
</style>