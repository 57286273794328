<template>
  <div class="pager ng-scope" data-ng-if="vm.itemsCount > 0" style="">
    <a @click="goToPage(1)" class="page-number-double" v-if="showNav">
      <div class="arrow first-page"></div>
      <div class="arrow lower"></div>
    </a>
    <a @click="currentPage == 1 ? '' : prevPageMarket()" class="page-number">
      <div class="arrow lower"></div>
    </a>
    <el-input
      v-model="currentPage"
      placeholder=""
      min="1"
      :max="total"
      @change="goToPage(currentPage)"
    ></el-input>
    <div class="d-inline from ng-binding">
      of
      <span ng-bind-html="vm.maxPage" class="number-of-pages ng-binding">{{
        totalPages
      }}</span>
    </div>

    <a
      @click="currentPage == totalPages ? '' : nextPageMarket()"
      class="page-number"
    >
      <div class="arrow highter"></div>
    </a>
    <a
      @click="currentPage == totalPages ? '' : goToPage(totalPages)"
      v-if="showNav"
      class="page-number-double"
    >
      <div class="arrow last-page"></div>
      <div class="arrow highter"></div>
    </a>
  </div>
</template>
<script>
export default {
  name: "pagination",
  props: {
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    maxVisibleButtons: {
      type: Number,
      default: 7,
    },
    yidongmenu: {
      type: Boolean, // 或者根据实际情况定义类型
      default: false, // 根据实际情况定义默认值
    },
    showNav: {
      type: Boolean,
      default: false,
    },
    openHongbao: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: this.page,
      totalPages: Math.ceil(this.total / this.perPage),
    };
  },
  methods: {
    goToPage(page) {
      if (page < 1) {
        page = 1;
      } else if (page > this.totalPages) {
        page = this.totalPages;
      }
      this.currentPage = page;
      this.$emit("current-change", this.currentPage);
    },
    nextPageMarket() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
      this.$emit("current-change", this.currentPage);
    },
    prevPageMarket() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
      this.$emit("current-change", this.currentPage);
    },
  },
  watch: {
    page: {
      handler(newVal) {
        this.currentPage = newVal;
      },
    },
    total: {
      handler(newVal) {
        this.totalPages = Math.ceil(newVal / this.perPage);
      },
    },
    perPage(newVal) {
      this.totalPages = Math.ceil(this.total / newVal);
    }
  },
};
// computed: {
//     pages() {
//         let start = this.currentPage - Math.floor(this.maxVisibleButtons / 2)
//         if (start < 1) {
//             start = 1
//         }
//         let end = start + this.maxVisibleButtons - 1
//         if (end > this.totalPages) {
//             end = this.totalPages
//         }
//         start = end - this.maxVisibleButtons + 1
//         if (start < 1) {
//             start = 1
//         }
//         let pages = []
//         for (let i = start; i <= end; i++) {
//             pages.push(i)
//         }
// }
</script>
<style scoped>
.pager {
  padding: 10px 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pager .page-number,
.pager .page-number-between,
.pager .page-number-double {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  font-size: 16px;
  display: inline-flex;
  align-content: center;
  position: relative;
}

.pager .page-number {
  margin: 0 25px;
}

.pager .page-number-double {
  width: 40px;
}

.pager .page-number-between {
  width: auto;
}

.pager .page-number.selected {
  background-color: #3d5afe;
}

.d-inline {
  display: inline-block;
}

.pager .from {
  padding-left: 15px;
  font-size: 16px;
  color: #fff;
}

.pager input {
  font-size: 16px;
  text-align: center;
}

.pager .dark-input {
  padding: 18px 15px;
}

.pager .arrow {
  border-left: 2px solid #8494a7;
  border-bottom: 2px solid #8494a7;
  height: 15px;
  width: 15px;
  position: absolute;
}

.pager .page-number:hover,
.pager .page-number-double:hover {
  background-color: #d7d7d74d;
}

.pager .page-number:hover .arrow,
.pager .page-number-double:hover .arrow {
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.pager .arrow.lower,
.pager .arrow.first-page {
  top: calc(50% - 2px);
  transform: rotate(45deg) translate(-50%);
  left: 20px;
}

.pager .arrow.first-page {
  left: 25px;
}

.pager .arrow.highter,
.pager .arrow.last-page {
  transform: rotate(225deg) translate(-50%);
  right: 20px;
  top: 7px;
}

.pager .page-number-double .arrow.first-page {
  left: 26px;
}

.pager .page-number-double .arrow.lower {
  left: 16px;
}

.pager .page-number-double .arrow.last-page {
  right: 26px;
}

.pager .page-number-double .arrow.highter {
  right: 16px;
}

.pager .number-of-pages {
  display: inline-block;
  margin-left: 10px;
}

.pager input[type="number"]::-webkit-inner-spin-button,
.pager input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.pager.header {
  justify-content: end;
}

.pager.header .from {
  padding-left: 0;
  font-size: 16px;
  color: #a5aabd;
  position: relative;
}

.pager.header .from div {
  display: inline-block;
  margin: 0 10px;
}

.pager.header .from .current-page {
  color: #fff;
  margin-right: 25px;
}

.pager.header .from .max-page {
  margin-left: 25px;
}

.pager.header .arrow {
  height: 12px;
  width: 12px;
}

.pager.header .arrow.highter,
.pager.header .arrow.last-page {
  top: 8px;
}

.pager.header .slash {
  width: 20px;
  height: 20px;
  border-right: 2px solid #8494a7;
  transform: rotate(45deg) translateY(-50%);
  margin-right: 10px;
  display: inline-block;
  position: absolute;
  left: 22%;
  top: -3px;
}

.pager.header .page-number {
  margin: 0 15px;
}
.dark-input {
  background: #131723;
  border: none;
  padding: 17px;
  color: #fff;
  font-size: 13px;
  border-radius: 4px;
}
/deep/.el-input {
  width: 89px;
  height: 54px;
}
/deep/.el-input__inner {
  width: 100% !important;
  height: 100% !important;
  text-align: center;
  padding: 18px 15px !important;
}
</style>