<template>
  <div class="roll">
    <div class="box" v-if="showDh">
      <div class="boxx">
        <div class="queren">确认兑换</div>
        <div class="queren" style="font-size: 12px">
          {{ buyCart && buyCart[0].itemName }}
        </div>
        <div class="center">
          <img
            :src="buyCart && buyCart[0].imageUrl"
            style="width: 150px"
            alt=""
          />
          <div>
            <img
              src="../assets/img/masonry.png"
              style="width: 20px"
              slot="prefix"
            />
            <span style="margin-left: 6px">{{
              buyCart && buyCart[0].price
            }}</span>
          </div>
        </div>
        <div class="tbutton">
          <div @click="quxiao()">取消</div>
          <div @click="queren()">确认</div>
        </div>
      </div>
    </div>
    <div class="roll-warp">
      <!-- 顶部按钮 -->
      <div class="main-cese-button">
        <div class="item-box">
          <div
            class="item"
            :class="{ 'case-active': caseType == 1 }"
            @click="caseType = 1"
          >
            {{ $t("商城") }}
          </div>
          <div
            class="item"
            :class="{ 'case-active': caseType == 2 }"
            @click="caseType = 2"
          >
            {{ $t("购物车") }}
          </div>
        </div>
      </div>
      <template v-if="caseType == 1">
        <!-- 搜索区域 -->
        <div class="mobile-search" v-show="!showNav ? !mobileFilters : true">
          <div class="search-box">
            <div class="roll-price">
              <el-input
                :placeholder="$t('搜索')"
                v-model="searchKey"
                style="
                  width: calc(100% - 86px);
                  margin-right: 12px;
                  max-width: 280px;
                  min-width: 255px;
                "
                class="input1"
              >
                <i
                  slot="prefix"
                  class="el-input__icon el-icon-search input1-i"
                  @click="getList(page)"
                ></i>
              </el-input>
              <!-- 手机端 筛选 -->
              <div
                class="filter-button"
                v-if="!showNav"
                @click="showMobileFilters()"
              >
                {{ $t("过滤") }}
              </div>
              <!-- 最小价格 -->
              <el-input
                class="price"
                :placeholder="$t('最低价')"
                type="number"
                v-model="minPrice"
                clearable
              ></el-input>
              <!-- 最大价格 -->
              <el-input
                class="price"
                type="number"
                :placeholder="$t('最高价')"
                v-model="maxPrice"
                clearable
              >
              </el-input>
              <!-- 类型下拉菜单 -->
              <div class="type-down" v-if="showNav">
                <el-button class="type-button" @click="toggleDropdown">
                  {{ $t("类型")
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <div class="dropdown type-dropdown" v-show="showDropdown">
                  <el-checkbox-group v-model="typeList">
                    <el-checkbox
                      :label="item.id"
                      v-for="item in selList"
                      :key="item.id"
                      >{{ item.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
              <!-- 颜色下拉菜单 -->
              <div class="type-down" v-if="showNav">
                <el-button class="color-button" @click="toggleColorDropdown">
                  {{ $t("珍品")
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <div class="dropdown color-dropdown" v-show="showColorDropdown">
                  <el-checkbox-group v-model="selectColorList">
                    <el-checkbox
                      :class="colorClass(item)"
                      :label="item.id"
                      v-for="item in colorList"
                      :key="item.id"
                      >{{ item.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
              <div class="sort-block" @click="listSort">
                <div style="font-size: 14px; font-weight: bold">价格</div>
                <div class="sort-icon">
                  <!-- <img
                src="../assets/img/s1.png"
                v-show="priceSort == 'desc'"
                alt=""
              />
              <img
                src="../assets/img/s2.png"
                v-show="priceSort == 'asc'"
                alt=""
              /> -->
                  <img
                    src="../assets/img/arrow-small-down.svg"
                    :class="{ desc: priceSort == 'desc' }"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="roll-list" v-show="totalSize > 0">
            <!-- 左边箭头 -->
            <div class="arrows-left" @click="leftArrow" v-if="showNav">
              <div class="arrow lower"></div>
            </div>
            <ul>
              <li
                v-for="(item, index) in list"
                :key="index"
                :class="'color-' + item.colour_codename"
              >
                <div class="border"></div>
                <div class="list-warp">
                  <div class="list-price">
                    <div class="price-right" @click="buyState(item.id)">
                      <span>{{ $t("购买") }}</span>
                    </div>
                    <div class="price-left">
                      <!-- <img src="../assets/img/money.png" alt="" /> -->
                      <span>{{ item.price }}</span>
                    </div>
                  </div>

                  <div class="list-img">
                    <div class="item-background">
                      <div class="item-main-background"></div>
                      <div class="item-background-elements">
                        <div class="item-image-background"></div>
                        <div class="item_image_background_1"></div>
                        <div class="item_image_background_2"></div>
                      </div>
                    </div>
                    <img :src="item.imageUrl" />
                  </div>
                  <div class="boxlist1-bottom" v-if="showNav">
                    <div class="boxlist1-bot" :title="item.name">
                      {{ getShortName(item) }}
                    </div>
                    <div class="boxlist1-bot2" :title="item.name">
                      <span>{{ getExteriorName(item) }}</span>
                    </div>
                  </div>
                  <div class="boxlist1-bottom boxlist1-mobile" v-if="!showNav">
                    <div class="boxlist1-bot" :title="item.name">
                      {{ getShortName(item) }}
                    </div>
                    <div class="boxlist1-bot2" :title="item.name">
                      <span>{{ getExteriorName(item) }}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <!-- 右边箭头 -->
            <div class="arrows-right" @click="rightArrow" v-if="showNav">
              <div class="arrow highter"></div>
            </div>
          </div>
          <div class="empty" v-show="totalSize == 0">
            {{ $t("暂无数据") }}
          </div>
          <div class="clear"></div>
          <div class="roll-page">
            <pagination
              v-if="totalSize > 0"
              :total="totalSize"
              :currentPage="page"
              :perPage="pageSize"
              :showNav="showNav"
              @current-change="currentChange"
            ></pagination>
            <!-- <el-pagination
          background
          layout="total,prev, pager, next"
          :total="totalSize"
          :page-size="pageSize"
          @current-change="currentChange"
        >
        </el-pagination> -->
          </div>
        </div>
        <!-- 手机端饰品筛选 -->
        <div
          class="mobile-skins-filter"
          v-show="!showNav ? mobileFilters : false"
        >
          <div class="mobile-filters-header">
            <div class="close" @click="closeMobileFilters()">
              <img src="../assets/img/cross-small.png" width="25" height="25" />
              <span>{{ $t("过滤") }}</span>
            </div>
            <div class="clear-button ng-binding" @click="clearFilters()">
              {{ $t("清除过滤") }}
            </div>
          </div>
          <div class="mobile-filters-body">
            <div class="mobile-type-list">
              <span class="ng-binding">{{ $t("类型") }}</span>
            </div>
            <el-checkbox-group v-model="mobileTypeList">
              <el-checkbox
                :label="item.id"
                v-for="item in selList"
                :key="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
            <div class="mobile-type-list">
              <span class="ng-binding">{{ $t("珍品") }}</span>
            </div>
            <el-checkbox-group
            class="color-dropdown"
             v-model="mobileColorList">
              <el-checkbox
                :class="colorClass(item)"
                :label="item.id"
                v-for="item in colorList"
                :key="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div class="mobile-filters-footer">
            <button
              class="mobile-filters-button ng-binding"
              @click="saveMobileFilters"
            >
              {{ $t("保存") }}
            </button>
          </div>
        </div>
      </template>
    </div>

    <div class="clear"></div>
    <div class="myhomebot-mag"></div>
    <myhomebot></myhomebot>
    <!-- <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader> -->
  </div>
</template>

<script>
import myhomebot from "@/components/my_homebot.vue";
import myinform from "@/components/my_inform.vue";
import Utils from "./../assets/js/util.js";
import navheader from "@/components/navheader.vue";
import pagination from "@/components/pagination.vue";
import debounce from "@/utils/debounce.js";
export default {
  components: {
    myhomebot,
    myinform,
    navheader,
    pagination,
  },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      caseType: 1,
      typeList: [],
      loading: false,
      buyCarState: false,
      input1: "",
      input2: "",
      totalSize: 0,
      page: 1,
      pageSize: 24,
      list: [],
      buyCart: [],
      total_num: 0,
      total_price: 0,
      priceSort: "asc",

      searchKey: "",
      // 品质
      pzList: [],
      // 外观
      wgList: [],
      // 品质选中
      rarity: "不限",
      // 外观选中
      exterior: "不限",
      // 主分类id
      classId: "1",
      // 小分类id
      subClassId: "all",
      // 推荐
      recommend: 1,
      // 二级分类
      classList: [],
      // 一级分类
      selList: [],
      colorList: [],
      colorId: "",
      showSure: false,
      showDh: false,
      minPrice: "",
      maxPrice: "",
      showDropdown: false,
      showColorDropdown: false,
      language:
        localStorage.getItem("lang") ||
        this.$store.state.currentLanguage ||
        "zh",
      mobileFilters: false,
      mobileTypeList: [],
      mobileColorList:[],
      selectColorList: [],
    };
  },
  watch: {
    searchKey: {
      handler: debounce(function (value) {
        this.page = 1;
        this.getList();
      }, 500),
    },
    // 监听typeList选择的值
    typeList: {
      handler: debounce(function (value) {
        this.page = 1;
        this.getList();
      }, 500),
    },
    selectColorList: {
      handler: debounce(function (value) {
        this.page = 1;
        this.getList();
      }, 500),
    },
    minPrice: {
      handler: debounce(function (value) {
        this.page = 1;
        this.getList();
      }, 500),
    },
    maxPrice: {
      handler: debounce(function (value) {
        this.page = 1;
        this.getList();
      }, 500),
    },

    // 监听一级分类选中获取二级分类
    classId: {
      handler(value) {
        if (!value) return;
        this.classList = [
          {
            name: "全部",
            id: "all",
          },
        ];
        this.subClassId = "all";

        // this.$axios
        // 	.post(
        // 		"/index/Store/subclassList",
        // 		this.$qs.stringify({
        // 			type_id: value
        // 		})
        // 	)
        // 	.then((res) => {
        // 		if (res.data.status) {
        // 			this.classList.push(...res.data.data.list);
        // 			this.subClassId = this.classList[0].id;
        // 		}
        // 	});
      },
    },
  },
  mounted() {
    this.getListClass();
    this.getColorList();
    this.page = 1;
    this.getList();
  },
  methods: {
    colorClass(item) {
      return `color-${item.codename}`;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      if (this.showDropdown) {
        document.addEventListener("click", this.handleOutsideClick);
      } else {
        document.removeEventListener("click", this.handleOutsideClick);
      }
      this.$forceUpdate();
    },
    toggleColorDropdown() {
      this.showColorDropdown = !this.showColorDropdown;
      if (this.showColorDropdown) {
        document.addEventListener("click", this.handleColorOutsideClick);
      } else {
        document.removeEventListener("click", this.handleColorOutsideClick);
      }
    },
    handleOutsideClick(event) {
      const dropdownElement = document.querySelector(".type-dropdown");
      const buttonElement = document.querySelector(".type-button");

      // 检查点击是否发生在下拉菜单或按钮之外
      if (
        !dropdownElement.contains(event.target) &&
        !buttonElement.contains(event.target)
      ) {
        this.showDropdown = false;
        document.removeEventListener("click", this.handleOutsideClick);
      }
    },
    handleColorOutsideClick(event) {
      const dropdownElement = document.querySelector(".color-dropdown");
      const buttonElement = document.querySelector(".color-button");

      // 检查点击是否发生在下拉菜单或按钮之外
      if (
        !dropdownElement.contains(event.target) &&
        !buttonElement.contains(event.target)
      ) {
        this.showColorDropdown = false;
        document.removeEventListener("click", this.handleColorOutsideClick);
      }
    },
    showMobileFilters() {
      this.mobileFilters = true;
      this.typeList = this.mobileTypeList;
    },
    closeMobileFilters() {
      this.mobileFilters = false;
    },
    clearFilters() {
      this.typeList = [];
      this.mobileTypeList = [];
      this.selectColorList = [];
      this.mobileColorList = [];
      this.page = 0;
      this.closeMobileFilters();
    },
    saveMobileFilters() {
      this.typeList = this.mobileTypeList;
      this.selectColorList = this.mobileColorList;
      this.mobileFilters = false;
      this.page = 1;
      this.getList();
    },
    leftArrow() {
      if (this.page > 1) {
        this.page--;
        this.getList();
      }
    },
    rightArrow() {
      if (this.page < Math.ceil(this.totalSize / this.pageSize)) {
        this.page++;
        this.getList();
      }
    },
    getShortName(item) {
      const lang = this.language;
      switch (lang) {
        case "zh":
          return item.shortName;
        case "en":
          return item.english_shortName;
        default:
          return item.shortName;
      }
    },
    getExteriorName(item) {
      const lang = this.language;
      switch (lang) {
        case "zh":
          return item.exteriorName;
        case "en":
          return item.english_exteriorName;
        default:
          return item.exteriorName;
      }
    },
    quxiao() {
      this.showDh = false;
    },
    queren() {
      if (this.buyCart && this.buyCart.length) {
        let info = this.buyCart;
        let infos = info.map((item) => item.id);
        this.loading = true;
        let param = {
          // skin_info: this.buyCart,
          skin_id: infos.join(),
        };
        this.$axios
          .post("/api/store/buynew", this.$qs.stringify(param))
          .then((res) => {
            let data = res.data;
            this.showDh = false;
            if (data.code == 1) {
              this.loading = false;
              this.buyCart = [];
              for (let i = 0; i < this.list.length; i++) {
                this.list[i].state = false;
              }
              this.total_num = 0;
              this.total_price = 0;
              // this.$store.commit("getMoney", data.data.total_amount);
              // Utils.$emit("masonry", data.data.total_amount);
              this.$message({
                message: this.language == "zh" ? data.msg : data.english_msg,
                type: "success",
                customClass: "message-styles",
              });
              this.showSure = false;
            } else {
              this.loading = false;

              let msg = res.data.msg;
              if (msg.indexOf("余额不足") != -1) {
                this.$message({
                  message: this.language == "zh" ? data.msg : data.english_msg,
                  type: "error",
                  customClass: "message-styles",
                });
                return;
              }
              if (msg.indexOf("商品库存不足") != -1) {
                this.$message({
                  message: this.language == "zh" ? data.msg : data.english_msg,
                  type: "error",
                  customClass: "message-styles",
                });
                return;
              }
              if (msg.indexOf("请联系管理员") != -1) {
                this.showSure = false;
                this.$message({
                  message: this.language == "zh" ? data.msg : data.english_msg,
                  type: "error",
                  customClass: "message-styles",
                });
                return;
              }
              this.showSure = false;
              this.$message({
                message: this.language == "zh" ? data.msg : data.english_msg,
                type: "error",
                customClass: "message-styles",
              });
              this.$store.commit("getLogin", true);
            }
          });
      } else {
        this.$message.error("购物车为空");
      }
    },
    buyState(id) {
      for (let i = 0; i < this.list.length; i++) {
        if (id == this.list[i].id) {
          this.list[i].state = !this.list[i].state;
          // if (this.list[i].state == true) {
          this.buyCart[0] = this.list[i];
          // this.showDh = true;
          for (let j = 0; j < this.buyCart.length; j++) {
            if (id == this.buyCart[j].id) {
              this.buyCart[j].num = 1;
            }
          }
          // }
          //  else {
          // 	for (let j = 0; j < this.buyCart.length; j++) {
          // 		if (id == this.buyCart[j].id) {
          // 			this.buyCart[0] = null;
          // 		}
          // 	}
          // }
        }
      }
      this.buyNum();
      this.$forceUpdate();
      this.queren();
    },
    setSubClassId(item) {
      this.subClassId = item.id;
      this.page = 1;
      this.getList();
    },
    selOran(status, id) {
      this.recommend = status == 2 ? 1 : 2;
      this.classId = id;
      this.getList();
    },
    getListClass() {
      this.$axios.post("/api/store/storetypelist").then((res) => {
        let data = res.data;
        if (data.code == 1) {
          this.selList = data.data;
          // this.classId = this.selList[0].id;
        }
      });
    },
    // 获取颜色类型
    getColorList() {
      this.$axios.post("/api/store/storecolourlist").then((res) => {
        let data = res.data;
        if (data.code == 1) {
          this.colorList = data.data;
        }
      });
    },
    //分页
    currentChange(val) {
      this.page = val;
      // console.log(val,'sdsd');
      this.getList();
    },
    //商城列表
    getList() {
      let param = {
        page: this.page,
        pagesize: this.pageSize,
        order: this.priceSort,
      };
      if (this.searchKey) {
        param["searchKey"] = this.searchKey;
      }
      if (this.minPrice) {
        param["minprice"] = this.minPrice;
      } else {
        param["minprice"] = 0;
      }
      if (this.maxPrice) {
        param["maxprice"] = this.maxPrice;
      }
      if (this.typeList) {
        param["type_id"] = this.typeList.join(",");
      }
      if (this.selectColorList) {
        param["colour_id"] = this.selectColorList.join(",");
      }
      // if (this.subClassId && this.subClassId != "all") {
      //   param["subclass_id"] = this.subClassId;
      // }
      // if (this.rarity && this.rarity != "不限") {
      //   param["rarity"] = this.rarity;
      // }
      // if (this.exterior && this.exterior != "不限") {
      //   param["exterior"] = this.exterior;
      // }
      // if (this.classId == "1") {
      //   param["recommend"] = 1;
      //   delete param.type_id;
      // }
      // console.log(this.typeList.length, "classId");
      // console.log(this.typeList.join(","), "classId");

      this.$axios
        .post("/api/store/skinlist", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.code == 1) {
            this.totalSize = data.data.total;
            this.list = data.data.data;
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].state = false;
            }
            for (let i = 0; i < this.buyCart.length; i++) {
              for (let j = 0; j < this.list.length; j++) {
                if (this.buyCart[i].id == this.list[j].id) {
                  this.list[j].state = true;
                }
              }
            }
            this.$forceUpdate();
          }
        });
    },
    //手机端
    buyCarPhone() {
      this.buyCarState = !this.buyCarState;
    },
    offBuyCar() {
      this.buyCarState = false;
    },
    //取消全部
    offAll() {
      this.buyCart = [];
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].state = false;
      }
      this.buyNum();
    },
    //加一
    addBuy(id) {
      for (let i = 0; i < this.buyCart.length; i++) {
        if (this.buyCart[i].id == id) {
          this.buyCart[i].num = this.buyCart[i].num + 1;
        }
      }
      this.buyNum();
      this.$forceUpdate();
    },
    //减一
    subBuy(id) {
      for (let i = 0; i < this.buyCart.length; i++) {
        if (this.buyCart[i].id == id) {
          this.buyCart[i].num = this.buyCart[i].num - 1;
        }
        if (this.buyCart[i].num == 0) {
          this.buyCart.splice(i, 1);
          for (let j = 0; j < this.list.length; j++) {
            if (this.list[j].id == id) {
              this.list[j].state = false;
            }
          }
        }
      }
      this.buyNum();
      this.$forceUpdate();
    },
    //购物车数量和价格
    buyNum() {
      this.total_num = 0;
      this.total_price = 0;
      for (let i = 0; i < this.buyCart.length; i++) {
        this.total_num += this.buyCart[i].num;
        this.total_price += Number(this.buyCart[i].price) * this.buyCart[i].num;
      }
      this.total_price = this.total_price.toFixed(2);
    },
    //立即购买
    goBuy() {
      this.loading = true;
      let param = {
        skin_info: this.buyCart,
      };
      this.$axios
        .post("index/Store/buynew", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.loading = false;
            this.buyCart = [];
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].state = false;
            }
            this.total_num = 0;
            this.total_price = 0;
            this.$store.commit("getMoney", data.data.total_amount);
            Utils.$emit("masonry", data.data.total_amount);
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.showSure = false;
          } else {
            this.loading = false;

            let msg = res.data.msg;
            if (msg.indexOf("余额不足") != -1) {
              this.$message({
                message: "余额不足，请先充值",
                type: "warning",
              });
              return;
            }
            if (msg.indexOf("商品库存不足") != -1) {
              this.$message({
                message: "商品库存不足",
                type: "warning",
              });
              return;
            }
            if (msg.indexOf("请联系管理员") != -1) {
              this.showSure = false;
              this.$message({
                message: "活动用户不支持购买饰品,请联系管理员",
                type: "warning",
              });
              return;
            }
            this.showSure = false;
            this.$message({
              message: "请先登录",
              type: "warning",
            });
            this.$store.commit("getLogin", true);
          }
        });
    },
    //数据排序
    listSort() {
      if (this.priceSort == "desc") {
        this.priceSort = "asc";
      } else if (this.priceSort == "asc") {
        this.priceSort = "desc";
      }
      this.getList();
    },
    //筛选
    // screen() {
    //   let param = {
    //     page: this.page,
    //     pageSize: this.pageSize,
    //     minPrice: this.input1,
    //     maxPrice: this.input2,
    //   };
    //   this.$axios
    //     .post("index/Store/skinList", this.$qs.stringify(param))
    //     .then((res) => {
    //       let data = res.data;
    //       if (data.status == 1) {
    //         this.totalSize = data.data.total;
    //         this.list = data.data.list;
    //         this.priceSort = true;
    //         for (let i = 0; i < this.list.length; i++) {
    //           this.list[i].state = false;
    //         }
    //         for (let i = 0; i < this.buyCart.length; i++) {
    //           for (let j = 0; j < this.list.length; j++) {
    //             if (this.buyCart[i].id == this.list[j].id) {
    //               this.list[j].state = true;
    //             }
    //           }
    //         }
    //       }
    //     });
    // },
  },
  beforeDestroy() {
    // 确保在组件销毁前移除事件监听器
    document.removeEventListener("click", this.handleOutsideClick);
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-input__inner {
    background-color: #131723;
    border: none !important;
    color: #c3c3e2;
    width: 255px;
    height: 50px;
    margin-left: 4px;
    padding: 17px 24px 17px 35px;
  }
}

@media screen and (max-width: 750px) {
  .box {
    top: 50px !important;
    .boxx {
      width: 90vw !important;
      height: 50vh !important;
      background-color: #1b2330 !important;
      border-radius: 20px;
    }
  }
}

.box {
  position: fixed;
  top: 50px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);

  .boxx {
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 500px;
    height: 500px;
    border-radius: 20px;
    // background: rgba(15, 16, 20, 1);
    background-color: #1b2330;
    // background-size: 100% 100%;
    background-position: left top;
    background-size: 100%;

    .queren {
      margin-top: 10px;
    }

    .center {
      img {
        margin: 12px 0;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .tbutton {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 24px;

      div {
        background: rgba(247, 188, 52, 1);
        padding: 6px 32px;
        color: black;
      }
    }
  }
}

.oran-sel {
  // background: url("../assets/95/linebg.png") no-repeat;
  // background-size: 100% 100%;
  // border-top: 1px solid #e9b10e;
  // margin: 20px auto 0 auto;
  // background-color: #2b2c37;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 750px) {
    .sel-top {
      ul {
        // display: grid !important;
        // grid-template-columns: repeat(auto-fit, minmax(75px, 1fr)) !important;
        width: 100%;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        justify-content: flex-start;
        flex-wrap: wrap;

        li {
          width: 23% !important;
          margin-left: 7px !important;
        }
      }
    }
  }

  .sel-top {
    // border-bottom: 1px solid #e9b10e;
    // background-color: #1a1c24;

    ul {
      display: flex;

      li {
        margin-top: 12px;
        margin-left: 12px;

        .seltop-warp {
          width: 75px;
          height: 75px;
          background-image: url("../assets/img/sc3.png");
          background-size: 75px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            width: 60%;
            height: auto;
            max-height: 78px;
          }

          span {
            font-size: 12px;
            color: #878787;
          }
        }

        .seltop-warp1 {
          // background-color: #2b2c37;
          background-image: url("../assets/img/sc4.png");
          background-size: 75px;
          border-radius: 0 !important;

          span {
            color: white;
          }
        }
      }

      li:hover {
        cursor: pointer;
      }
    }
  }

  .class-box {
    background-color: #2b2c37;
    padding: 0 20px 0px 20px;

    .class-list {
      margin-top: 15px;

      ul {
        display: flex;

        li {
          margin-right: 10px;

          span {
            border: 1px solid #ffc400;
            border-radius: 5px;
            padding: 5px 16px;
            font-size: 12px;
            color: #fff;
          }

          .span2 {
            border: 1px solid #ffc400;
            color: #fff;
            font-size: 12px;
          }

          span:hover {
            cursor: pointer;
            background-color: #ffc400;
            color: #000;
          }
        }
      }
    }
  }

  .sel-bot {
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin: 30px 0 10px 0px;

      &:last-child {
        display: flex;
        justify-content: flex-start;
      }
    }

    .selbot-left {
      flex: 1;
      min-width: 200px;
      min-height: 60px;

      .pz-container,
      .wg-container {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 5px 0;

        .name {
          color: #fff;
          width: 45px;
        }

        .wg-content {
          color: #848492;
          flex: 1;
          white-space: nowrap;
          overflow: auto;

          span {
            padding: 0 10px;
            cursor: pointer;

            &:hover {
              color: #e9b10e;
            }
          }

          .active {
            color: #e9b10e;
          }
        }
      }
    }

    .selbot-right {
      max-width: 400px;
      // margin-left: 10px;
      display: flex;
      align-items: center;

      .span {
        margin: 0 8px;
        color: #848492;
      }

      .pirec-btn {
        margin: 0 10px;
        background-color: #333542;
        border-radius: 5px;
        color: #848492;
        font-size: 15px;
        font-weight: 600;
        padding: 10px 26px;
      }

      .pirec-btn:hover {
        cursor: pointer;
        background-color: #3a3f50;
      }

      .input {
        width: 120px;

        img {
          width: auto;
          height: 18px;
        }
      }

      .input /deep/ .el-input__prefix,
      .input /deep/ .el-input__suffix {
        top: 11px;
      }

      .input1 {
        width: 200px;
      }

      .input1-i:hover {
        cursor: pointer;
      }
    }

    .selbot-right /deep/ .el-input__inner {
      background-color: #2b2c37;
      border: 1px solid #e9b10e;
      color: #c3c3e2;
    }
  }
}

.buy-sure {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  color: #fff;

  .sure {
    position: absolute;
    width: 80%;
    height: 20%;
    max-width: 500px;
    max-height: 500px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: #2b2c37;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-size: 20px;
      text-align: center;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        padding: 8px 30px;
        font-size: 14px;
        border: 1px solid #e9b10e;
        cursor: pointer;
      }

      .sure-btn {
        background-color: #e9b10e;
        margin-left: 20px;
        color: #000;
      }
    }
  }
}

.class-list {
  margin: 10px 0;
  height: 50px;
  background-color: #2b2c37;

  ul {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;

    li {
      margin-right: 10px;
      line-height: 50px;

      span {
        background-color: #e9b10e;
        border-radius: 5px;
        padding: 8px 16px;
        font-size: 14px;
      }

      .span2 {
        border: 1px solid #848492;
        background-color: #2b2c37;
        color: #848492;
        font-size: 14px;
      }

      span:hover {
        cursor: pointer;
        background-color: #e9b10e;
        color: #000;
      }
    }
  }
}

.go-mall-car {
  position: fixed;
  bottom: 20px;
  left: 50%;
  background-color: #e9b10e;
  color: #000000;
  padding: 8px 0;
  font-weight: bold;
  font-family: ninefont !important;
  width: calc(100% - 80px);
  max-width: 600px;
  transform: translate(-50%, 0);
  border-radius: 50px;
  box-shadow: 0px 16px 48px 16px rgba(0, 0, 0, 0.08),
    0px 12px 32px rgba(0, 0, 0, 0.12), 0px 8px 16px -8px rgba(0, 0, 0, 0.16);
  font-size: 18px;
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roll {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: #1e2335;

  .myhomebot-mag {
    margin-top: 120px;
  }

  .roll-warp {
    height: 100%;
    // padding: 16px 16px 16px 16px;

    .roll-hint {
      font-size: 16px;
      color: #848493;

      span {
        color: #e9b10e;
      }
    }
    .search-box {
      width: 100%;
      border-bottom: 1px solid #35394a;
      padding: 20px 0px;
    }
    .roll-price {
      display: flex;
      // flex-direction: row;
      // align-items: center;
      // background-color: #2b2c37;
      //   padding: 20px 0 0;
      width: 1464px;
      margin: 0 auto;

      .sort-block {
        background-color: #131723;
        border-radius: 5px;
        width: 85px;
        height: 50px;
        display: flex;
        align-items: center;
        color: #606266;
        display: flex;
        font-size: 15px;
        align-items: center;
        justify-content: center;
        // margin-right: 10px;
        cursor: pointer;
        user-select: none;

        .sort-icon {
          display: flex;
          flex-direction: column;
          margin-left: 10px;

          img {
            width: 20px;
          }
          img.desc {
            transform: rotate(180deg);
          }

          .icon {
            color: #333;
            font-size: 10px !important;
          }

          .active-icon {
            color: #fff !important;
          }
        }
      }

      .left {
        flex: 1;
        // min-width: 200px;
        min-height: 60px;

        .shengjiang {
          padding: 8px 14px;
          border-radius: 4px;
          background-color: #e9b10e;
          display: flex;
          align-items: center;

          span {
            margin-right: 6px;
          }

          img {
            width: 14px;
          }
        }

        .pz-container,
        .wg-container {
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 5px 0;
          width: 100%;

          .name {
            color: #fff;
            width: 45px;
          }

          .wg-content {
            color: #848492;
            // flex: 1;
            white-space: nowrap;
            overflow: auto;

            span {
              padding: 0 10px;
              cursor: pointer;

              &:hover {
                color: #e9b10e;
              }
            }

            .active {
              color: #e9b10e;
            }
          }
        }
      }

      .right {
        max-width: 400px;
        display: flex;
        align-items: center;
        // margin-left: 10px;

        span {
          margin-left: 5px;
          margin-right: 5px;
          color: #e9b10e;
        }

        .screen {
          height: 40px;
          line-height: 40px;
          margin-left: 20px;
          background-color: #e9b10e;
          padding: 0 30px;
          font-weight: 600;
          color: #1a1c24;
          border-radius: 5px;
        }

        .screen:hover {
          cursor: pointer;
        }

        .input {
          width: 100px;

          img {
            width: auto;
            height: 15px;
          }
        }

        .input /deep/ .el-input__prefix,
        .el-input__suffix {
          top: 12.5px;
        }

        .input /deep/ .el-input__inner {
          background-color: #000;
          color: white;
          border: 1px solid #893e8a;
        }
      }
    }
    .type-down {
      position: relative;
      cursor: pointer;
      display: inline-block;
      // padding: 17px 5px 17px 20px;
      width: 140px;
      height: 50px;
      margin: 0px 8px;
    }
    .type-down .dropdown {
      position: absolute;
      top: 52px;
      left: 50%;
      transform: translateX(-50%);
      width: 155px;
      background: #131723;
      border-radius: 4px;
      transition: all 0.5s;
      padding: 12px;
      z-index: 99;
    }
    .type-down .dropdown .el-checkbox {
      width: 100%;
      padding: 12px 10px;
      margin-right: 0px !important;
      box-sizing: border-box;
    }
    .type-down .dropdown /deep/.el-checkbox__input {
      position: absolute;
      right: 0;
    }
    .type-down .dropdown /deep/.el-checkbox__inner {
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
    .type-down
      .dropdown
      /deep/.el-checkbox__input.is-checked
      + .el-checkbox__label {
      color: #fff !important;
    }

    .search-box .price {
      width: 125px;
      height: 50px;
      margin-right: 8px;
    }
    .search-box .price /deep/.el-input__inner {
      width: 100%;
      height: 100%;
    }
    .search-box /deep/.el-button {
      width: 100%;
      height: 100%;
      background: #131723;
      border: none;
      color: #606266;
      padding: 0;
      line-height: 50px;
    }
    .search-box /deep/.el-button span {
      display: block;
      width: 100%;
      height: 100%;
    }

    .roll-list {
      // margin-top: 20px;
      padding: 20px 40px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      position: relative;
      @media screen and (max-width: 767px) {
        display: flex;
        justify-content: center;
        padding: 0 !important;
        padding-top: 20px !important;
      }
      ul {
        margin: 0 -4px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media screen and (max-width: 767px) {
          li {
            width: 40% !important;
            float: left;
            height: 40% !important;
            flex: 1;
            margin: 0 9px 20px !important;
            position: relative;
            .list-warp {
              overflow: visible;
            }
          }
        }

        li {
          // width: 16.66%;
          width: 11vw;
          height: 11vw;
          margin-top: 0.2vw;
          margin-right: 0.2vw;
          position: relative;
          // float: left;
          margin: 0 12px 25px;
          .list-warp {
            width: 100%;
            height: 100%;
            // margin: 4px;
            // background-color: #2b2c37;
            border-radius: 5px;
            position: relative;
            overflow: hidden;

            .list-bor {
              position: absolute;
              top: 0;
              z-index: 666;
              width: 100%;
              height: 100%;
              background-color: rgba(233, 177, 14, 0.1);
            }

            .ico {
              position: absolute;
              top: 5px;
              left: 5px;
              font-size: 14px;
              font-weight: 700;
              // color: #fafcfa;
              // background-color: rgb(81, 113, 156);
              color: #ccc;
              padding: 8px 8px;
              z-index: 99;
            }

            .buy-state {
              position: absolute;
              top: 0;
              right: 0;

              img {
                width: 15px;
                height: 15px;
              }
            }

            .list-img {
              background: url("../assets/img/openBox/item-bg.jpg");
              // background-image: url("../assets/img/scroll_pc.png");
              // background-image: url("https://oss.wwmanghe.com/prod/lucky/f1d5f606-2ba9-4ce6-b4dc-ab44553e3c1a.png?x-oss-process=image/format,webp");
              background-size: 100% 100%;
              // overflow: hidden;
              // width: 305px;
              // height: 315px;
              width: 100%;
              height: 110px;
              // padding-top: 24px;
              display: flex;
              align-items: center;
              flex-direction: column;
              position: relative;
              // height: 130px;
              img {
                // margin: 32px 0;
                // width: 60%;
                // height: auto;
                width: auto !important;
                // height: 150px !important;
                height: 100% !important;
                z-index: 2;
              }
              .bot {
                margin-top: 30px;
                height: auto !important;
              }
            }

            @media screen and (max-width: 767px) {
              .list-img {
                height: 145px !important;
                background-size: 100% auto;
                padding: 0 0px;
                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;
                .list-name {
                  position: absolute;
                  bottom: 30px;
                  left: 50%;
                  transform: translate(-50%, 0);
                }

                // height: 130px;
                img {
                  // margin: 30px 0;
                  width: auto !important;
                  height: 90px !important;
                  max-height: 90px !important;
                  // margin-top: 30px;
                  // transform: translateY(12px);
                  margin-top: 0px !important;
                }
              }
              .list-name {
                // margin-left: 0 !important;
              }
            }

            .bot {
              margin-top: 30px;
              width: 100%;

              // background-color: #2b2c37;
              .list-name {
                width: 100%;
                text-align: center;
                // margin-left: 12px;
                font-size: 14px;
                color: #c3c3e2;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                // transform: translateY(12px);
              }

              .list-price {
                // margin-top: 30px;
                padding: 10px 8px;
                display: flex;
                text-align: center;
                justify-content: center;

                .price-right {
                  color: #848492;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }

    .roll-page {
      margin: 10px 0 0px -10px;
      text-align: center;
    }

    .roll-page
      /deep/
      .el-pagination.is-background
      .el-pager
      li:not(.disabled).active {
      background-color: #e9b10e;
      color: #1a1c24;
    }

    .roll-page /deep/ .el-pagination.is-background .btn-next,
    /deep/ .el-pagination.is-background .btn-prev,
    /deep/ .el-pagination.is-background .el-pager li {
      background-color: #333542;
      color: #848492;
    }
  }

  .roll-right {
    position: fixed;
    width: 250px;
    height: 100%;
    top: 60px;
    right: 0px;
    z-index: 888;
    background-color: #30313f;
    text-align: center;
    border-left: 1px solid #30313f;

    .off {
      padding: 16px;
      background-color: #1a1c24;

      span {
        background-color: #30313f;
        border-radius: 5px;
        padding: 8px 16px;
        color: #848492;
        font-size: 14px;
        font-weight: 600;
      }

      span:hover {
        cursor: pointer;
      }

      .buy-btn-phone1 {
        display: none;
        margin-left: 10px;
        padding: 8px 16px;
        background-color: #e9b10e;
        border-radius: 5px;
        color: #1a1c24;
        font-size: 15px;
        font-weight: 600;
      }
    }

    .shopping-box {
      height: calc(~"100vh - 300px");
      padding: 16px 16px 16px 8px;

      .num {
        text-align: center;
        padding: 0 0 16px 0;
        color: #e9b10e;
      }

      .shopping-cart {
        height: 100%;
        overflow: hidden;
        overflow-y: auto;

        ul li {
          margin-top: 10px;
          margin-right: 10px;
          padding: 8px;
          background-color: #2b2c37;

          .cart-warp {
            .cart-top {
              display: flex;

              .cart-img {
                min-width: 70px;
                max-width: 70px;
                min-height: 55px;
                max-height: 55px;
                background-image: url("../assets/img/box-skins-blue.jpg");
                background-size: 100% 100%;
                text-align: center;

                img {
                  width: 100%;
                  height: auto;
                }
              }

              .cart-name {
                margin-left: 10px;

                h5 {
                  font-size: 14px;
                  color: #c3c3e2;
                }

                h6 {
                  display: flex;
                  align-items: center;

                  img {
                    width: auto;
                    height: 15px;
                  }

                  span {
                    margin-left: 5px;
                    color: #e9b10e;
                    font-size: 16px;
                  }
                }
              }
            }

            .cart-bot {
              display: flex;
              align-items: center;

              span {
                color: #c3c3e2;
              }

              .num {
                background-color: #444659;
                padding: 3px 12px;
                font-size: 15px;
              }

              .sub,
              .add {
                font-size: 24px;
                padding: 0 12px;
              }

              .sub:hover,
              .add:hover {
                cursor: pointer;
              }
            }
          }
        }

        img {
          width: 50px;
          height: 50px;
        }
      }

      /*滚动条样式*/
      .shopping-cart::-webkit-scrollbar {
        width: 4px;
        /*height: 4px;*/
      }

      .shopping-cart::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
      }

      .shopping-cart::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .buy {
      background-color: #2b2c37;
      padding: 16px;
      text-align: center;
      font-size: 14px;
      color: #c3c3e2;

      span {
        margin-left: 10px;
        color: #e9b10e;
      }
    }

    .buy-btn {
      margin-top: 20px;
      text-align: center;

      .el-btn {
        color: #1a1c24;
        font-size: 15px;
        font-weight: 600;
      }

      span {
        color: #1a1c24;
        background-color: #e9b10e;
        padding: 8px 80px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 600;
      }

      span:hover {
        cursor: pointer;
        background-color: #f5c432;
      }
    }
  }

  .roll-right1 {
    display: block;
    z-index: 88;
  }

  .buy-btn-phone {
    display: none;
    margin-top: 20px;

    span {
      padding: 8px 22px;
      background-color: #e9b10e;
      border-radius: 5px;
      color: #1a1c24;
      font-size: 15px;
      font-weight: 600;
    }
  }
}
.list-price {
  padding: 0px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #131724;
  line-height: 40px;
}
.price-left {
  display: flex;
  align-items: center;

  img {
    width: auto;
    height: 20px !important;
    margin: 0 !important;
    margin-right: 5px !important;
  }

  span {
    padding-right: 10px;
    line-height: 38px;
    font-size: 13px;
    color: #a5aabd;
    font-weight: 700;
  }
}
.boxlist1-bottom {
  background: #131724;
  padding: 8px 15px;
  height: 67px;
  box-sizing: border-box;
}
.boxlist1-bottom .boxlist1-bot {
  width: 90%;
  margin: auto;
  color: white;
  font-family: "微软雅黑";
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  word-wrap: break-word;
}
.boxlist1-bottom .boxlist1-bot2 {
  height: 16px;
  width: 90%;
  margin: auto;
  color: #cccccc;
  font-family: "微软雅黑";
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  word-wrap: break-word;
}
.price-right {
  color: #a5aabd !important;
  font-family: "微软雅黑";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.7s;
  width: 70px;
}
.price-right:hover {
  cursor: pointer;
  width: 110px;
  transition: width 0.7s;
}
.price-right:hover span {
  background: #3d5afe;
}
.price-right span {
  text-decoration: none;
  display: block;
  border: 2px solid #3d5afe;
  border-radius: 99px;
  text-align: center;
  background: linear-gradient(
    to right,
    rgba(61, 90, 254, 0) 0%,
    rgba(61, 90, 254, 0.3) 100%
  );
  min-width: 60px;
  line-height: 22px;
  color: #fff;
  font-size: 11px;
}
.roll-list ul li .border {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -0.375rem;
  width: 0.375rem;
  z-index: 0;
  background-image: linear-gradient(to bottom, #4b69ff 0, #2740bb 100%);
}
// .gradient-color-\#007acc {
//   background-image: linear-gradient(to bottom, #4b69ff 0, #2740bb 100%);
// }
// // 紫色
// .gradient-color-\#8a2be2 {
//   background-image: linear-gradient(to bottom, #8847ff 0, #5e29bf 100%);
// }
// // 金色
// .gradient-color-\#FFA500 {
//   background-image: linear-gradient(to bottom, #ffae39 0%, #b46d06 100%);
// }

/* 红色 */
.color-\#FF0000 .border {
  background-image: linear-gradient(
    to bottom,
    #e34343 0%,
    #ca2d2d 100%
  ) !important;
}
.color-\#FF0000 .price-right span {
  background: linear-gradient(to right, #eb4b4b00 0, #eb4b4b85 100%) !important;
  border-color: #eb4b4b !important;
}
.color-\#FF0000 .price-right:hover span {
  background: #eb4b4b !important;
}
.color-\#FF0000 .item_image_background_1 {
  background: radial-gradient(
    32.33% 32.33% at 50% 50%,
    #d53636 0%,
    rgba(213, 54, 54, 0) 100%
  );
}
.color-\#FF0000 .item_image_background_2 {
  background: radial-gradient(
    32.33% 32.33% at 50% 50%,
    #d53636 0%,
    rgba(213, 54, 54, 0) 33.63%
  );
}

/* 黄色 */
.color-\#FFCC33 .border {
  background-image: linear-gradient(
    to bottom,
    #ffae39 0%,
    #b46d06 100%
  ) !important;
}
.color-\#FFCC33 .price-right span {
  background: linear-gradient(to right, #ffd70000 0, #ffd70085 100%) !important;
  border-color: gold !important;
}
.color-\#FFCC33 .price-right:hover span {
  background: gold !important;
}
.color-\#FFCC33 .item_image_background_1 {
  background: radial-gradient(
    32.33% 32.33% at 50% 50%,
    #f5c900 0%,
    rgba(245, 201, 0, 0) 100%
  );
}
.color-\#FFCC33 .item_image_background_2 {
  background: radial-gradient(
    32.33% 32.33% at 50% 50%,
    #f5c900 0%,
    rgba(245, 201, 0, 0) 33.63%
  );
}
/* 黑色 */
.color-\#000000 .border {
  background-image: linear-gradient(
    to bottom,
    #000000 0%,
    #444444 100%
  ) !important;
}
.color-\#000000 .price-right span {
  background: linear-gradient(
    to right,
    #00000000 0%,
    #00000085 100%
  ) !important;
  border-color: #000000 !important;
}
.color-\#000000 .price-right:hover span {
  background: #000000 !important;
}
.color-\#000000 .item_image_background_1 {
  background: radial-gradient(circle at center, #000000 0%, transparent 100%);
}
.color-\#000000 .item_image_background_2 {
  background: radial-gradient(
    circle at center,
    #000000 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}
/* 蓝色 */
.color-\#3366FF .border {
  background-image: linear-gradient(
    to bottom,
    #4b69ff 0%,
    #2740bb 100%
  ) !important;
}
.color-\#3366FF .price-right span {
  background: linear-gradient(to right, #4b69ff00 0, #4b69ff85 100%) !important;
  border-color: #4b69ff !important;
}
.color-\#3366FF .price-right:hover span {
  background: #4b69ff !important;
}
.color-\#3366FF .item_image_background_1 {
  background: radial-gradient(
    32.33% 32.33% at 50% 50%,
    #3d5afe 0%,
    rgba(61, 90, 254, 0) 100%
  );
}
.color-\#3366FF .item_image_background_2 {
  background: radial-gradient(
    32.33% 32.33% at 50% 50%,
    #3d5afe 0%,
    rgba(61, 90, 254, 0) 33.63%
  );
}
/* 绿色 */
.color-\#00CC00 .border {
  background-image: linear-gradient(
    to bottom,
    #25a464 0%,
    #186b42 100%
  ) !important;
}
.color-\#00CC00 .price-right span {
  background: linear-gradient(to right, #25a46400 0, #25a46485 100%) !important;
  border-color: #25a464 !important;
}
.color-\#00CC00 .price-right:hover span {
  background: #25a464 !important;
}
.color-\#00CC00 .item_image_background_1 {
  background: radial-gradient(
    32.33% 32.33% at 50% 50%,
    #25a464 0%,
    rgba(37, 164, 100, 0) 100%
  );
}
.color-\#00CC00 .item_image_background_2 {
  background: radial-gradient(
    32.33% 32.33% at 50% 50%,
    #4278b6 0%,
    rgba(66, 120, 182, 0) 33.63%
  );
}
/* 紫色 */
.color-\#9900FF .border {
  background-image: linear-gradient(
    to bottom,
    #8847ff 0%,
    #5e29bf 100%
  ) !important;
}
.color-\#9900FF .price-right span {
  background: linear-gradient(to right, #8847ff00 0, #8847ff85 100%) !important;
  border-color: #8847ff !important;
}
.color-\#9900FF .price-right:hover span {
  background: #8847ff !important;
}
.color-\#9900FF .item_image_background_1 {
  background: radial-gradient(
    32.33% 32.33% at 50% 50%,
    #8746fe 0%,
    rgba(135, 70, 254, 0) 100%
  );
}
.color-\#9900FF .item_image_background_2 {
  background: radial-gradient(
    32.33% 32.33% at 50% 50%,
    #ad21be 0%,
    rgba(173, 33, 190, 0) 33.63%
  );
}
/* 橙色 */
.color-\#FF5511 .border {
  background-image: linear-gradient(
    to bottom,
    #ffa500 0%,
    #cc7a00 100%
  ) !important;
}
.color-\#FF5511 .price-right span {
  background: linear-gradient(
    to right,
    #ffa50000 0%,
    #ffa50085 100%
  ) !important;
  border-color: #ffa500 !important;
}
.color-\#FF5511 .price-right:hover span {
  background: #ffa500 !important;
}
.color-\#FF5511 .item_image_background_1 {
  background: radial-gradient(
    32.33% 32.33% at 50% 50%,
    #ffa500 0%,
    rgba(135, 70, 254, 0) 100%
  );
}
.color-\#FF5511 .item_image_background_2 {
  background: radial-gradient(
    32.33% 32.33% at 50% 50%,
    #ffa500 0%,
    rgba(173, 33, 190, 0) 33.63%
  );
}
// 箭头
.arrow {
  border-left: 2px solid #8494a7;
  border-bottom: 2px solid #8494a7;
  height: 18px;
  width: 18px;
  position: absolute;
}
.lower {
  top: calc(50% - 2px);
  transform: rotate(45deg) translate(-50%);
  left: 22px;
}
.highter {
  transform: rotate(225deg) translate(-50%);
  right: 22px;
  top: 5px;
}
.arrows-left,
.arrows-right {
  width: 45px;
  height: 45px;
  font-size: 16px;
  display: inline-flex;
  align-content: center;
  // position: relative;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.arrows-left:hover,
.arrows-right:hover {
  background-color: #d7d7d74d;
}
.arrows-left:hover .arrow,
.arrows-right:hover .arrow {
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.arrows-left {
  left: 72px;
}
.arrows-right {
  right: 72px;
}
/deep/.el-pagination /deep/.el-pager {
  opacity: 0;
}
.empty {
  padding: 60px;
  font-weight: 700;
  text-align: center;
  color: #fff;
}
.boxlist1-mobile {
  position: absolute;
  bottom: -5%;
  background: transparent;
  width: 100%;
}
.mobile-skins-filter {
  background: #131723;
  color: #fff;
  .mobile-filters-header {
    border-bottom: 1px solid #343a4a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 5%;
  }
  .close {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    span {
      margin-left: 20px;
    }
  }
  .clear-button {
    background-color: #ffffff0d;
    margin-top: 0;
    padding: 17px;
    color: #fff;
    font-size: 13px;
    border-radius: 4px;
  }
}
.mobile-filters-body {
  span {
    padding: 30px 0 0 20px;
    color: #fff;
    display: block;
    width: 100%;
  }
  padding: 0 5% 120px;
  /deep/.el-checkbox-group {
    display: flex;
    flex-direction: column;
  }
  /deep/.el-checkbox {
    padding: 12px 10px;
    // opacity: .5;
    margin-right: 0px !important;
    padding-left: 20px;
    position: relative;
  }
  /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: #fff;
  }
  .color-dropdown /deep/.el-checkbox__label:before{
    right: 0px !important;
    z-index: 1;
    left: auto !important;
    height:70% !important;
  }
  .color-dropdown /deep/.el-checkbox__label{
    position: static;
  }
  
}
.mobile-filters-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px 23px;
  background: #131723;
  border-top: 1px solid #343a4a;
  z-index: 2;
  .mobile-filters-button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 16px 30px;
    background: linear-gradient(
      270deg,
      rgba(61, 90, 254, 0.3) 0%,
      rgba(61, 90, 254, 0) 100%
    );
    border: 2px solid #3d5afe;
    border-radius: 999px;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
  }
}
.mobile-search {
  padding: 0 10px;
}
.item-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.65;
  .item-main-background {
    background: url("../assets/img/shop_products_background.png");
    background-size: 100% 100%;
    mix-blend-mode: difference;
    opacity: 0.6;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .item-background-elements {
    position: relative;
    width: 100%;
    height: 100%;
    mix-blend-mode: screen;
    filter: blur(18.2px);
  }
  .item-image-background {
    background-image: url("../assets/img/shop_product_main_background.png");
    background-size: 100% 100%;
    mix-blend-mode: screen;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    opacity: 0.25;
  }
}
.item_image_background_1 {
  mix-blend-mode: soft-light;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}
.item_image_background_2 {
  mix-blend-mode: normal;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}
.color-dropdown {
  /deep/.el-checkbox__label {
    position: relative;
  }
  /deep/.el-checkbox__label:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #fff;
    content: "";
    z-index: -1;
  }
  .color-\#FF0000 /deep/.el-checkbox__label:before {
    background: #ff0000;
  }
  .color-\#FFCC33 /deep/.el-checkbox__label:before {
    background: #ffcc33;
  }
  .color-\#000000 /deep/.el-checkbox__label:before {
    background: #000000;
  }
  .color-\#3366FF /deep/.el-checkbox__label:before {
    background: #3366ff;
  }
  .color-\#00CC00 /deep/.el-checkbox__label:before {
    background: #00cc00;
  }
  .color-\#FF5511 /deep/.el-checkbox__label:before {
    background: #ff5511;
  }
}
/deep/.el-checkbox__inner {
  background-color: transparent !important;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
/deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #3d5afe !important;
}
/deep/.el-checkbox__inner::after {
  content: "✔";
  color: #fff;
  border: none !important;

  left: 0px !important;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner::after {
  transform: rotate(0deg) scaleY(1) !important;
}
</style>