<template>
  <div class="main">
    <div class="zhe" v-if="close"></div>
    <div class="ruleBox" v-if="close">
      <div class="cancel" @click="close = false">
        <img src="./img/close.png" alt="" />
      </div>
      <div class="title">
        <div>玩法规则</div>
      </div>
      <div class="con">
        <div v-html="content" style="margin-top:20px"></div>
      </div>
    </div>
    <div class="back">
      <div class="left" @click="$router.push('/Index')">
        <span class="el-icon-arrow-left l"></span>
        <span>返回</span>
      </div>
      <div class="right" @click="goRule">
        <img src="./img/rule.png" alt="" />
        <span>游戏规则</span>
      </div>
    </div>
    <div class="nav" v-if="!showAll">
        <div class="items" :class="{active:showTop==0}" @click="showTop=0">
            每小时盲盒
        </div>
        <div class="items" :class="{active:showTop===1}" @click="showTop=1">
            每天盲盒
        </div>
        <div class="items" :class="{active:showTop==2}" @click="showTop=2">
            每周盲盒
        </div>
    </div>
    <div class="top">
      <div class="box" v-for="(item, index) in boxInfo" :key="item.id" v-if="showAll || (!showAll && showTop==index)">
            <div class="title" v-if="index == 0">每小时盲盒</div>
            <div class="title" v-if="index == 1">每天盲盒</div>
            <div class="title" v-if="index == 2">每周盲盒</div>
        <div class="wuqi">
          <img :src="item.imageUrl" alt="" />
        </div>
        <div class="row1" style="color:white;margin-bottom:3px">{{ item.exteriorName }}</div>
        <div class="row1">{{ item.shortName }}</div>
        <div class="row2">
          <img src="./img/money.png" alt="" />
          <span>{{ item.price }}</span>
        </div>
        <div class="row3" v-if="index == 0">倒计时内充值{{item.min_recharge}}以上即可参与</div>
        <div class="row3" v-if="index == 1">倒计时内充值{{item.min_recharge}}以上即可参与</div>
        <div class="row3" v-if="index == 2">倒计时内充值{{item.min_recharge}}以上即可参与</div>
        <div class="row4">
          <div class="right">
            <span class="s" v-if="timeInfo[index].days>0">
              <span>{{ timeInfo[index].days }}天</span>
              <span>D</span>
            </span>
            <span style="margin: 0 6px; color: white"  v-if="timeInfo[index].days>0">:</span>
            <span class="s">
              <span>{{ timeInfo[index].hours }}</span>
              <span>H</span>
            </span>
            <span style="margin: 0 6px; color: white">:</span>
            <span class="s">
              <span>{{ timeInfo[index].minutes }}</span>
              <span>M</span>
            </span>
            <span style="margin: 0 6px; color: white">:</span>
            <span class="s">
              <span>{{ timeInfo[index].seconds }}</span>
              <span>S</span>
            </span>
          </div>
        </div>
        <div class="button" @click="join(item.id)" v-if="item.is_active==0">立即参与</div>
        <div class="buttons" @click="join(item.id)" v-else>已参与</div>
      </div>
    </div>
    <div class="content">
      <div class="item" v-if="showAll || (!showAll && showTop==0)">
        <div class="title">最近掉落</div>
        <div class="list">
          <div class="box" v-for="item in oneList" :key="item.id">
            <div class="all">
              <div class="shang">
                <img :src="item.imageUrl" alt="" />
                <span>{{ item.shortName }}</span>
              </div>
              <div class="xia">
                <div class="img">
                  <img :src="item.image" alt="" />
                </div>
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <div class="roll-page" style="margin-bottom: 50px">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total1"
              :page-size="pageSize"
              @current-change="currentChange1"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="item" v-if="showAll || (!showAll && showTop==1)">
        <div class="title">最近掉落</div>
        <div class="list">
          <div class="box" v-for="item in twoList" :key="item.id">
            <div class="all">
              <div class="shang shang2">
                <img :src="item.imageUrl" alt="" />
                <span>{{ item.shortName }}</span>
              </div>
              <div class="xia">
                <div class="img">
                  <img :src="item.image" alt="" />
                </div>
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <div class="roll-page" style="margin-bottom: 50px">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total2"
              :page-size="pageSize"
              @current-change="currentChange2"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="item" v-if="showAll || (!showAll && showTop==2)">
        <div class="title">最近掉落</div>
        <div class="list">
          <div class="box" v-for="item in threeList" :key="item.id">
            <div class="all">
              <div class="shang shang3">
                <img :src="item.imageUrl" alt="" />
                <span>{{ item.shortName }}</span>
              </div>
              <div class="xia">
                <div class="img">
                  <img :src="item.image" alt="" />
                </div>
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <div class="roll-page" style="margin-bottom: 50px">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total3"
              :page-size="pageSize"
              @current-change="currentChange3"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <MyHomebot></MyHomebot>
  </div>
</template>

<script>
import MyHomebot from "./my_homebot.vue";
export default {
  components: {
    MyHomebot
  },
  data() {
    return {
      close: false,
      hTime: 0,
      mTime: 0,
      sTime: 0,
      time1: "",
      timeInfo1: {},
      time2: "",
      timeInfo2: {},
      time3: "",
      timeInfo3: {},
      boxInfo: [],
      timeInfo: [],
      timer: null,
      oneId: "",
      twoId: "",
      threeId: "",
      oneList: [],
      twoList: [],
      threeList: [],
      total1: "",
      total2: "",
      total3: "",
      showTop: 0,
      showList: 0,
      showAll:true,
      content:'',
    };
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  created() {
    
  },
  async mounted() {
    if(document.body.clientWidth>600) {
        this.showAll = true
    }else{
        this.showAll = false
    }
    this.getInfo();
    this.timeInfo = [
        this.getTime(this.time1),
        this.getTime(this.time2),
        this.getTime(this.time3),
      ];
    this.timer = setInterval(() => {
      this.timeInfo = [
        this.getTime(this.time1),
        this.getTime(this.time2),
        this.getTime(this.time3),
      ];
      this.time1--;
      this.time2--;
      this.time3--;
    }, 1000);
    this.getRule()
  },
  methods: {
    getRule() {
      this.$axios({
        url:'/api/index/ruletextlist',
        method:'post'
      }).then((res) => {
        if(res.data.code == 1) {
          let data = res.data.data
        for(let i=0;i<data.length;i++) {
          if('时间盲盒'.includes(data[i].name)) {
            this.content = data[i].center
            return
          }
        }
        }
      })
    },
    goRule() {
      if(this.showAll) {
        this.close = true
      }else{
        this.$router.push({name:'Rule',params:{title:'时间盲盒'}})
      }
    },
    getInfo() {
      console.log("请求数据");
      this.$axios({
        url: "/api/rechargetime/rechargetimelist",
        method: "post",
      }).then((res) => {
        if (res.data.code == 1) {
          this.boxInfo = res.data.data;
          let data = res.data.data;
          this.time1 = data[0].down_time;
          this.time2 = data[1].down_time;
          this.time3 = data[2].down_time;
          this.oneId = data[0].id;
          this.twoId = data[1].id;
          this.threeId = data[2].id;
          console.log(this.boxInfo, "boxinfo");
          this.getList1();
          this.getList2();
          this.getList3();
        }
      });
    },
    getList1(page = 1) {
      this.$axios({
        url: "/api/rechargetime/recordlist",
        method: "post",
        data: {
          id: this.oneId,
          page: page,
          pagesize: 9,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.oneList = res.data.data.data;
          this.total1 = res.data.data.total;
        }
      });
    },
    currentChange1(index) {
      this.getList1(index);
    },
    currentChange2(index) {
      this.getList2(index);
    },
    currentChange3(index) {
      this.getList3(index);
    },
    getList2(page = 1) {
      this.$axios({
        url: "/api/rechargetime/recordlist",
        method: "post",
        data: {
          id: this.twoId,
          page: page,
          pagesize: 9,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.twoList = res.data.data.data;
          this.total2 = res.data.data.total;
        }
      });
    },
    getList3(page = 1) {
      this.$axios({
        url: "/api/rechargetime/recordlist",
        method: "post",
        data: {
          id: this.threeId,
          page: page,
          pagesize: 9,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.threeList = res.data.data.data;
          this.total3 = res.data.data.total;
        }
      });
    },
    join(id) {
      this.$axios({
        url: "/api/rechargetime/joinrechargetime",
        method: "post",
        data: { id },
      }).then((res) => {
        if (res.data.code == 1) {
          this.$message({
            message: res.data.msg,
            type: "success",
            customClass: "messageStyle",
          });
          this.getInfo();
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
            customClass: "messageStyle",
          });
        }
      });
    },
     getTime(time) {
      var data = time * 1000
    var days = Math.floor((time) / (3600 * 24));
      var hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = parseInt((data % (1000 * 60)) / 1000);
      if(days<0) {
        days = 0
      }
      if (hours < 0) {
        hours = 0;
      }
      if (minutes < 0) {
        minutes = 0;
      }
      if (seconds < 0) {
        seconds = 0;
      }
      return {days, hours, minutes, seconds };
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 100%;
  background-color: #07090e;
  .zhe {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
  }
  .ruleBox {
    width: 530px;
    height: 480px;
    background-image: url(./img/box-bg.jpg);
    background-size: 100% 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    border-radius: 10px;
    z-index: 99999;
    padding-bottom: 30px;
    @media (max-width: 550px) {
      width: 90vw;
    }
    .cancel {
      position: fixed;
      top: 10px;
      right: 13px;
      transition: 0.4s;
      cursor: pointer;
      &:hover {
        transform: rotate(90deg);
      }
      img {
        width: 16px;
        height: auto;
      }
    }
    .title {
      font-size: 22px;
      margin-top: 40px;
    }
    .con {
      overflow-y: scroll;
      padding: 0 100px;
      @media (max-width: 550px) {
        padding: 0 50px;
      }
      p {
        margin-top: 20px;
      }
    }
  }
  .back {
    width: 100%;
    border-radius: 3px;
    height: 60px;
    line-height: 60px;
    // background-color: rgba(0, 0, 0, 0.28);
    background-color: #12161f;
    padding: 0 30px;
    box-sizing: border-box;
    position: relative;
    color: #a9abbc;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 550px) {
    //   margin-top: 15px;
      height: 40px;
      line-height: 40px;
    }
    .left {
      cursor: pointer;
      // background-color: red;
      width: 75px;
      &:hover {
        color: white;
        .l {
          border: 1.5px solid white !important;
        }
      }
    }
    .l {
      padding: 5px;
      border: 1.5px solid #383f4f;
      border-radius: 6px;
      margin-right: 6px;
    }
    .right {
      //   position: absolute;
      //   right: 30px;
      //   top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: white;
    //   font-size: 16px;
      &:hover {
        color: white;
      }
      img {
        width: 20px;
        height: auto;
        margin-right: 6px;
      }
    }
  }
  .nav{
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    color: white;
    .items{
        width: 33%;
        height: 35px;
        border-radius: 5px;
        margin: 0 2px;
        background-color: #14161A;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .active{
        background-color: #51420A !important;
        color: #FFC600 !important;
    }
  }
  .top {
    width: 100%;
    height: 440px;
    background-image: url(./img/time-top-bg.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0 10%;
    box-sizing: border-box;
    display: flex;
    @media (max-width: 550px) {
        width: 100vw;
        margin-top: 10px;
        .box{
            width: 100% !important;
        }
    }
    .box {
      width: 33.3%;
      height: 100%;
      // background-color: red;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .wuqi {
        width: 192px;
        height: 178px;
        background-image: url(./img/top-item-bg.png);
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        img {
            width: auto;
          height: 55%;
        }
      }
      .title {
        color: white;
        margin-top: 10px;
      }
      .row1 {
        color: rgb(255, 162, 42);
      }
      .row2 {
        display: flex;
        justify-content: center;
        color: #f9c545;
        margin: 5px 0;
        img {
          width: 20px;
          height: auto;
        }
      }
      .row3 {
        color: white;
      }
      .row4 {
        margin-top: 10px;
        height: 50px;
        // background-color: red;
        display: flex;
        align-items: center;
        @media (max-width: 550px) {
          height: 14vw;
        }
        .left {
          text-align: center;
          height: 100%;
          font-size: 14px;
          margin-right: 20px;
          padding-top: 10px;
          @media (max-width: 550px) {
            padding-top: 5px;
            font-size: 13px;
            img {
              width: 26px;
            }
          }
        }
        .right {
          display: flex;
          align-items: center;
          height: 100%;
          .s {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            width: 36px;
            border-radius: 5px;
            background-color: #23262e;
            padding: 4px 0;
            box-sizing: border-box;
            @media (max-width: 550px) {
              width: 45px;
            }
            span {
              font-size: 12px;
              color: white;
              @media (max-width: 550px) {
                font-size: 14px;
              }
              &:nth-child(1) {
                color: #f9c545;
                font-weight: 700;
                font-size: 12px;
                @media (max-width: 550px) {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
      .button {
        background: #ffc600;
        padding: 2px 30px;
        border-radius: 15px;
        color: #4f3d2e;
        margin-top: 15px;
        cursor: pointer;
      }
      .buttons {
        background: #585858;
        padding: 2px 30px;
        border-radius: 15px;
        color: white;
        margin-top: 15px;
        cursor: pointer;
      }
    }
  }
  .content {
    width: 100%;
    padding: 0 10%;
    box-sizing: border-box;
    display: flex;
    margin-top: 15px;
    @media (max-width: 550px) {
        padding: 0;
    }
    .item {
      width: 33.3%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 550px) {
        // width: 100%;
        width: 100vw;
      }
      .title {
        width: 90%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background-color: #12161f;
        border-radius: 10px;
        color: white;
        font-size: 24px;
        font-weight: 700;
      }
      .list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .box {
          width: 31.3%;
          height: 130px;
          margin: 0 1%;
          margin-top: 20px;
          overflow: hidden;
          color: white;
          &:hover {
            .all {
              transform: translate(0, -130px);
              transition: 0.5s;
            }
          }
          .all {
            transition: 0.5s;
          }
          .shang2 {
            background-image: url(./img/list-item-bg2.png) !important;
          }
          .shang3 {
            background-image: url(./img/list-item-bg3.png) !important;
          }
          .shang {
            width: 100%;
            height: 130px;
            background-image: url(./img/list-item-bg1.png);
            background-size: 100% 100%;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img {
              width: auto;
              height: 50%;
              margin-bottom: 20px;
            }
            span {
              display: block;
              width: 95%;
              margin-top: -15px;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              @media (max-width: 550px) {
                font-size: 12px;
              }
            }
          }
          .xia {
            width: 100%;
            height: 130px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #f9c545;
            font-size: 14px;
            .img {
              width: 73px;
              height: 73px;
              background-image: url(./img/list-item-avatar-bg.png);
              background-size: 100% 100%;
              background-position: center center;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 10px;
            }
            img {
              width: 57px;
              height: auto;
              border-radius: 50%;
            }
          }
        }
      }
    }
    .pagination {
      width: 100%;
      margin-top: 20px;
    }
  }
}

//页数
.roll-page {
  margin: 10px 0 0 -10px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.roll-page
  /deep/
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #e9b10e;
  color: #1a1c24;
}

.roll-page /deep/ .el-pagination.is-background .btn-next,
/deep/ .el-pagination.is-background .btn-prev,
/deep/ .el-pagination.is-background .el-pager li {
  background-color: #333542;
  color: #848492;
}
</style>