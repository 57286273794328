<template>
  <!-- <div class="hist">
    <div class="hist-warp">
      <div class="hist-list">
        <el-tabs type="border-card" v-model="activeName" @tab-click="getTab">
          <el-tab-pane label="充值记录" name="one">
            <div class="bot">
              <el-table
                :data="tableData"
                style="width: 100%"
                :cell-style="columnStyle"
              >
                <el-table-column prop="createtime" label="日期">
                </el-table-column>
                <el-table-column prop="money" label="充值金额">
                </el-table-column>
                <el-table-column prop="statusName" label="充值结果">
                </el-table-column>
                <el-table-column prop="order_num" label="订单号">
                </el-table-column>
              </el-table>
            </div>
            <div class="roll-page">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="totalSize"
                :page-size="pageSize"
                @current-change="currentChange"
              >
              </el-pagination>
            </div>
          </el-tab-pane>

          <el-tab-pane label="兑换记录" name="two">
            <div class="bot">
              <el-table
                :data="tableData1"
                style="width: 100%"
                :cell-style="columnStyle1"
              >
                <el-table-column prop="createtime" label="日期" width="180">
                </el-table-column>
                <el-table-column prop="price" label="收支" width="180">
                  <template slot-scope="scope">
                    {{
                      scope.row.way != 5
                        ? `+${scope.row.price}`
                        : `-${scope.row.price}`
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="饰品名称">
                </el-table-column>
                <el-table-column prop="img" label="饰品图片">
                  <template slot-scope="scope">
                    <img
                      :src="scope.row.img"
                      alt=""
                      style="width: 45px; height: 100%"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="roll-page">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="totalSize1"
                :page-size="pageSize"
                @current-change="currentChange1"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="金币明细" name="three">
            <div class="bot">
              <el-table
                :data="tableData2"
                style="width: 100%"
                :cell-style="columnStyle2"
              >
                <el-table-column prop="createtime" label="日期">
                </el-table-column>
                <el-table-column prop="state" label="类型"> </el-table-column>
                <el-table-column prop="amount" label="收支">
                  <template slot-scope="scope">
                    {{
                      scope.row.amount > 0
                        ? `+${scope.row.amount}`
                        : `${scope.row.amount}`
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="total_amount" label="变动前">
                  <template slot-scope="scope">
                    {{
                      (
                        parseFloat(scope.row.total_amount) -
                        parseFloat(scope.row.amount)
                      ).toFixed(2)
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="total_amount" label="变动后">
                </el-table-column>
              </el-table>
            </div>
            <div class="roll-page">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="totalSize2"
                :page-size="pageSize"
                @current-change="currentChange2"
              >
              </el-pagination>
            </div>
          </el-tab-pane>

          <el-tab-pane label="钻石明细" name="fore">
            <div class="bot">
              <el-table
                :data="tableData3"
                style="width: 100%"
                :cell-style="columnStyle2"
              >
                <el-table-column prop="createtime" label="日期">
                </el-table-column>
                <el-table-column prop="type_text" label="类型"> </el-table-column>
                <el-table-column prop="amount" label="收支">
                  <template slot-scope="scope">
                    {{
                      scope.row.amount > 0
                        ? `+${scope.row.amount}`
                        : `${scope.row.amount}`
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="total_amount" label="变动前">
                  <template slot-scope="scope">
                    {{
                      (
                        parseFloat(scope.row.total_amount) -
                        parseFloat(scope.row.amount)
                      ).toFixed(2)
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="total_amount" label="变动后">
                </el-table-column>
              </el-table>
            </div>
            <div class="roll-page">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="totalSize3"
                :page-size="pageSize"
                @current-change="currentChange3"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div> -->

  <div class="main">
    <!-- 切换按钮 -->
    <div class="change">
      <div :class="activeted == 1 ? 'active' : ''" @click="change(1)">
        充值记录
      </div>
      <div :class="activeted == 2 ? 'active' : ''" @click="change(2)">
        兑换记录
      </div>
      <div :class="activeted == 3 ? 'active' : ''" @click="change(3)">
        金币明细
      </div>
      <div :class="activeted == 4 ? 'active' : ''" @click="change(4)">
        钻石明细
      </div>
    </div>
    <!-- 充值记录 -->
    <div class="tableData" v-if="activeted==1">
      <div class="t1">
        <ul>
          <li>日期</li>
          <li>充值金额</li>
          <li>充值结果</li>
          <li>订单号</li>
        </ul>
      </div>
      <div class="content" v-for="(item,index) in tableData" :key="index">
        <ul :class="index%2==0?'bg1':'bg2'">
          <li>{{item.createtime}}</li>
          <li>{{item.money}}</li>
          <li>{{item.statusName}}</li>
          <li>{{item.order_num}}</li>
        </ul>
      </div>
      <!-- 分页 -->
        <div class="roll-page">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="totalSize"
            :page-size="pageSize"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
    </div>
    <!-- 兑换记录 -->
    <div class="tableData" v-if="activeted==2">
      <div class="t1">
        <ul>
          <li>日期</li>
          <li>收支</li>
          <li>饰品名称</li>
          <li>饰品图片</li>
        </ul>
      </div>
      <div class="content" v-for="(item,index) in tableData1" :key="index">
        <ul :class="index%2==0?'bg1':'bg2'">
          <li>{{item.createtime}}</li>
          <li>{{item.price}}</li>
          <li>{{item.name}}</li>
          <li><img :src="item.img" alt="" style="width: 45px; height: auto"></li>
        </ul>
      </div>
      <!-- 分页 -->
        <div class="roll-page">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="totalSize1"
            :page-size="pageSize"
            @current-change="currentChange1"
          >
          </el-pagination>
        </div>
    </div>
    <!-- 金币明细 -->
    <div class="tableData" v-if="activeted==3">
      <div class="t1">
        <ul>
          <li>日期</li>
          <li>类型</li>
          <li>收支</li>
          <li>变动前</li>
          <li>变动后</li>
        </ul>
      </div>
      <div class="content" v-for="(item,index) in tableData2" :key="index">
        <ul :class="index%2==0?'bg1':'bg2'">
          <li>{{item.createtime}}</li>
          <li>{{item.type_text}}</li>
          <li>{{item.amount}}</li>
          <li>{{(parseFloat(item.total_amount) -parseFloat(item.amount)).toFixed(2)}}</li>
          <li>{{item.total_amount}}</li>
        </ul>
      </div>
      <!-- 分页 -->
        <div class="roll-page">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="totalSize2"
            :page-size="pageSize"
            @current-change="currentChange2"
          >
          </el-pagination>
        </div>
    </div>
    <!-- 钻石明细 -->
    <div class="tableData" v-if="activeted==4">
      <div class="t1">
        <ul>
          <li>日期</li>
          <li>类型</li>
          <li>收支</li>
          <li>变动前</li>
          <li>变动后</li>
        </ul>
      </div>
      <div class="content" v-for="(item,index) in tableData3" :key="index">
        <ul :class="index%2==0?'bg1':'bg2'">
          <li>{{item.createtime}}</li>
          <li>{{item.type_text}}</li>
          <li>{{item.amount}}</li>
          <li>{{(parseFloat(item.total_amount) -parseFloat(item.amount)).toFixed(2)}}</li>
          <li>{{item.total_amount}}</li>
        </ul>
      </div>
      <!-- 分页 -->
        <div class="roll-page">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="totalSize3"
            :page-size="pageSize"
            @current-change="currentChange3"
          >
          </el-pagination>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 当前页数
      currentPage: 1,
      // 点击查看后显示的订单号
      order: "",
      // 控制显示哪个表格
      activeted: 1,
      // 控制点击查看按钮的状态
      isShow: false,
      activeName: "one",
      totalSize: 0,
      page: 1,
      pageSize: 10,
      totalSize1: 0,
      totalSize2: 0,
      totalSize3: 0,
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      currentPage:1,
      activeted:1,
    };
  },
  mounted() {
    this.getTopupList(this.page);
  },
  methods: {
    change(index) {
      this.currentPage = 1
      this.currentPage = 1;
      this.activeted = index;
      if (index == 1) {
      } else if (index == 2) {
        this.getShoppList(this.page);
      } else if (index == 3) {
        this.getBalanceList(this.page);
      } else {
        this.getFenJieList(this.page);
      }
    },
    get() {
      this.getTopupList(this.page);
    },
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 2) {
        //第三第四列的背景色就改变了2和3都是列数的下标
        return "color: #e9b10e ;";
      }
    },
    columnStyle1({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        //第三第四列的背景色就改变了2和3都是列数的下标
        return "color: #e9b10e ;";
      }
    },
    columnStyle2({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 4) {
        //第三第四列的背景色就改变了2和3都是列数的下标
        return "color: #e9b10e ;";
      }
      if (columnIndex == 3) {
        return "color: #c3c3e2 ;";
      }
      if (columnIndex == 2) {
        return "color: white ;";
      }

      if (columnIndex == 1) {
        // if (row.pay == "支出") {
        // 	return "color:  #c3c3e2 ;";
        // } else {
        return "color:#02bf4d;";
        // }
      }
    },
    columnStyle3({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 4) {
        //第三第四列的背景色就改变了2和3都是列数的下标
        return "color: #c3c3e2 ;";
      }
      if (columnIndex == 3) {
        return "color: #e9b10e ;";
      }

      if (columnIndex == 1) {
        if (row.pay == "支出") {
          return "color:  #02bf4d ;";
        } else {
          return "color:#c3c3e2;";
        }
      }
    },
    getTab(tab) {
      //console.log(tab.name);
      if (tab.name == "one") {
      } else if (tab.name == "two") {
        this.getShoppList(this.page);
      } else if (tab.name == "three") {
        this.getBalanceList(this.page);
      } else {
        this.getFenJieList(this.page);
      }
    },
    getTopupList(page) {
      let param = {
        page: page,
        pagesize: this.pageSize,
      };
      this.$axios
        .post("/api/record/recharge", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.code == "1" && data.data != null) {
            this.totalSize = data.data.total;
            this.tableData = data.data.data;
            for (let i = 0; i < this.tableData.length; i++) {
              if (this.tableData[i].mode == "zhifubao") {
                this.tableData[i].modeName = "支付宝";
              } else if (this.tableData[i].mode == "jdpay") {
                this.tableData[i].modeName = "支付宝";
              } else {
                this.tableData[i].modeName = "微信";
              }

              if (this.tableData[i].status == 1) {
                this.tableData[i].statusName = "未支付";
              } else if (this.tableData[i].status == 2) {
                this.tableData[i].statusName = "待支付";
              } else if (this.tableData[i].status == 3) {
                this.tableData[i].statusName = "支付成功";
              } else {
                this.tableData[i].statusName = "支付失败";
              }
            }
          }
        });
    },

    getShoppList(page) {
      let param = {
        page: page,
        pagesize: this.pageSize,
      };
      this.$axios
        .post("/api/record/skinshistory", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.code == "1" && data.data != null) {
            this.totalSize1 = data.data.total;
            this.tableData1 = data.data.data;
          }
        });
    },
    getFenJieList(page) {
      let param = {
        page: page,
        pagesize: this.pageSize,
      };
      this.$axios
        .post("/api/record/masonrydetail", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.code == "1" && data.data != null) {
            this.totalSize3 = data.data.total;
            this.tableData3 = data.data.data;
          }
        });
    },
    getBalanceList(page) {
      let param = {
        page: page,
        pagesize: this.pageSize,
      };
      this.$axios
        .post("/api/record/balancedetail", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.code == "1" && data.data != null) {
            this.totalSize2 = data.data.total;
            this.tableData2 = data.data.data;

            for (let i = 0; i < this.tableData2.length; i++) {
              if (Number(this.tableData2[i].amount) >= 0) {
                this.tableData2[i].pay = "收入";
              } else {
                this.tableData2[i].pay = "支出";
              }
              this.tableData2[i].state = tableData2[i].type_text
              return
              if (this.tableData2[i].type == 1) {
                this.tableData2[i].state = "皮肤兑换商城币";
              } else if (this.tableData2[i].type == 2) {
                this.tableData2[i].state = "福利赠送";
              } else if (this.tableData2[i].type == 3) {
                this.tableData2[i].state = "充值";
              } else if (this.tableData2[i].type == 4) {
                this.tableData2[i].state = "对战失败";
              } else if (this.tableData2[i].type == 5) {
                this.tableData2[i].state = "对战平局";
              } else if (this.tableData2[i].type == 7) {
                this.tableData2[i].state = "幸运饰品失败";
              } else if (this.tableData2[i].type == 8) {
                this.tableData2[i].state = "cdk兑换";
              } else if (this.tableData2[i].type == 10) {
                this.tableData2[i].state = "下级充值推广佣金";
              } else if (this.tableData2[i].type == -1) {
                this.tableData2[i].state = "购买盲盒";
              } else if (this.tableData2[i].type == -2) {
                this.tableData2[i].state = "决战沙城";
              } else if (this.tableData2[i].type == -3) {
                this.tableData2[i].state = "幸运饰品";
              } else if (this.tableData2[i].type == -4) {
                this.tableData2[i].state = "商城购买饰品";
              } else if (this.tableData2[i].type == -6) {
                this.tableData2[i].state = "钻石兑换金币";
              } else if (this.tableData2[i].type == 6) {
                this.tableData2[i].state = "红包领取";
              } else if (this.tableData2[i].type == 11) {
                this.tableData2[i].state = "卡密充值";
              } else if (this.tableData2[i].type == 12) {
                this.tableData2[i].state = "签到福利";
              } else if (this.tableData2[i].type == 13) {
                this.tableData2[i].state = "盲盒任务";
              } else if (this.tableData2[i].type == 14) {
                this.tableData2[i].state = "对战任务";
              } else if (this.tableData2[i].type == 15) {
                this.tableData2[i].state = "充值任务";
              } else if (this.tableData2[i].type == 16) {
                this.tableData2[i].state = "注册赠送";
              } else if (this.tableData2[i].type == 17) {
                this.tableData2[i].state = "vip福利";
              } else if (this.tableData2[i].type == 18) {
                this.tableData2[i].state = "亏损补偿";
              } else if (this.tableData2[i].type == -7) {
                this.tableData2[i].state = "Roll房消耗";
              } else if (this.tableData2[i].type == 19) {
                this.tableData2[i].state = "Roll房退款";
              }
            }
          }
        });
    },

    //充值流水 页数
    currentChange(val) {
      this.getTopupList(val);
    },

    //玩家商城 分页
    currentChange1(val) {
      this.getShoppList(val);
    },

    //余额流水 分页
    currentChange2(val) {
      this.getBalanceList(val);
    },
    //余额流水 分页
    currentChange3(val) {
      this.getFenJieList(val);
    },

    goLucky() {
      this.$router.push({
        path: `/Lucky`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.hist {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  .hist-warp {
  }

  //tabs
  .hist-list {
    margin-top: 12px;
  }

  .hist-list /deep/ .el-tabs--border-card {
    // background-color: #2b2c37;
    background-color: rgba(16, 36, 48, 1);
    height: 78.5vh;
    border: none;
  }

  /deep/.el-tab-pane {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 68.5vh;
  }

  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header {
    border: none;
    background-color: rgba(16, 36, 48, 1);
  }

  /deep/.cell {
    height: 35px;
    line-height: 35px;
  }

  /deep/.el-table__header th {
    background-color: rgba(16, 36, 48, 1) !important;
  }

  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    background-color: rgba(27, 77, 98, 1);
    border: none;
  }

  /deep/.el-tabs__nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    // background-color: #24252f;
    background-color: rgba(24, 59, 79, 1);
    height: 50px;
    line-height: 50px;
    width: 33.33%;
    text-align: center;
    border: none;
  }

  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    color: #e9b10e;
  }

  //页数
  .roll-page {
    width: 100%;
    margin: 10px 0 0 -10px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .roll-page
    /deep/
    .el-pagination.is-background
    .el-pager
    li:not(.disabled).active {
    background-color: #e9b10e;
    color: #1a1c24;
  }

  .roll-page /deep/ .el-pagination.is-background .btn-next,
  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .el-pager li {
    background-color: #333542;
    color: #848492;
  }

  //表格
  .bot /deep/ .el-table th,
  .bot /deep/ .el-table tr {
    background-color: rgba(16, 36, 48, 1) !important;
  }

  .bot /deep/ .el-table td,
  .bot /deep/ .el-table th.is-leaf {
    border-bottom: 1px solid #444659;
  }

  .bot /deep/ .el-table::before {
    height: 0;
  }

  .bot /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #212e3e !important;
  }

  /*.bot /deep/ .cell {
    padding: 0;
    height: 60px;
    line-height: 60px;
  }*/

  .roomleft-num {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      font-size: 20px;
      font-weight: 400;
      color: #c3c3e2;
    }

    h6 {
      font-weight: 200;
      color: #848492;
      font-size: 16px;

      span {
        color: #17b4ed;
      }

      span:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}


.main{
  // background-color: red;
  .change {
    width: 100%;
    height: auto;
    color: white;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    div {
      padding: 7px 20px;
      border-radius: 3px;
      box-sizing: border-box;
      border: 1px solid #9434d8;
      background-color: #1d213f;
      margin-top: 20px;
    }
    .active {
      background: linear-gradient(0deg, #9434d8 0%, #7822e5 100%) !important;
      border-image: linear-gradient(180deg, #a56fff, #7317d0) 1 1 !important;
    }
  }
  .tableData{
    width: 100%;
    height: auto;
    margin-top: 30px;
    position: relative;
    .t1{
      ul{
        display: flex;
        color: white;
        justify-content: space-around;
        width: 100%;
        height: 45px;
        background: linear-gradient(180deg, rgba(82, 0, 255, 0) 0%, rgba(0, 22, 224, .2) 100%);
        border: .02667rem solid rgba(169, 72, 250, .2);
        li{
          flex: 1;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .content{
      .bg1{
          background: linear-gradient(90deg, #1c2248 0%, rgba(21, 25, 54, 0) 100%);
        }
        .bg2{
          background: rgba(18, 20, 41, .35);
        }
      ul{
        color: white;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-around;
        background: linear-gradient(90deg, #1c2248 0%, rgba(21, 25, 54, 0) 100%);
        }
        li{
          width: 100%;
          height: 60px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .button{
            padding: 3px 12px;
            cursor: pointer;
            color: black;
            background-color: #ffc600;
            border-radius: 3px;
          }
          .buttons{
            padding: 3px 12px;
            cursor: pointer;
            color: black;
             background-color: #9e9e9c;
             border-radius: 3px;
             cursor: not-allowed !important;
          }
          .buttonss{
            font-weight: 700;
            color: #ffc600;
          }
      }
    }
  }
}
.roll-page{
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translate(-50%,0);
  display: flex;
  justify-content: center;
}
  .roll-page
    /deep/
    .el-pagination.is-background
    .el-pager
    li:not(.disabled).active {
    background-color: #e9b10e;
    color: #1a1c24;
  }

  .roll-page /deep/ .el-pagination.is-background .btn-next,
  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .el-pager li {
    background-color: #333542;
    // background-color: white;
    color: #848492;
  }

  //表格
  .bot /deep/ .el-table th,
  .bot /deep/ .el-table tr {
    background-color: rgba(16, 36, 48, 1) !important;
  }

  .bot /deep/ .el-table td,
  .bot /deep/ .el-table th.is-leaf {
    border-bottom: 1px solid #444659;
  }

  .bot /deep/ .el-table::before {
    height: 0;
  }

  .bot /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #212e3e !important;
  }
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #30215f !important;
  color: white !important;
}

/deep/ .el-pagination.is-background .el-pager li,/deep/ .el-pagination.is-background .btn-next,/deep/ .el-pagination.is-background .btn-prev {
background-color: white !important;
}


// 分页
    // .pagination {
    //   position: absolute;
    //   left: 50%;
    //   // top: 104%;
    //   transform: translate(-50%, 0);
    // }

// /deep/ .el-pagination.is-background .el-pager li {
//   border-radius: 6px;
// }
// /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
//   background-color: #30215f;
// }
// /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
//   color: #30215f;
// }
// /deep/ .el-pagination .btn-next .btn-prev,
// .el-pagination .btn-prev .el-icon {
//   background-color: #333542;
// }
// /deep/ .el-pager li.active,
// .el-pager {
//   color: white !important;
// }
// .chakan {
//   color: #af6bf8;
// }
</style>