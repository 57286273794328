<template>
  <div class="main">
    <div class="navs">
      <div :class="isActive == 1 ? 'active' : ''" @click="changeList(1)">
        我的背包
      </div>
      <div :class="isActive == 2 ? 'active' : ''" @click="changeList(2)">
        提取中
      </div>
      <div :class="isActive == 3 ? 'active' : ''" @click="changeList(3)">
        已提取
      </div>
    </div>
    <div class="list" v-if="isActive == 1">
      <div
        class="box"
        :class="activeArr.indexOf(item.id) !== -1 ? 'active' : ''"
        v-for="(item, index) in skinList"
        :key="index"
        @click="check(item.id)"
        ref="box"
        :style="{ '--ww': boxWidth + 'px' }"
      >
        <div class="price">
          <img src="@/assets/img/money.png" alt="" />
          <span>{{ item.price }}</span>
        </div>
        <img class="img" :src="item.img" alt="" />
        <div class="description">
          <div class="top">{{ item.exteriorName }}</div>
          <div class="bottom">{{ item.shortName }}</div>
        </div>
      </div>
    </div>
    <div class="list" v-if="isActive == 2">
      <div
        class="box"
        :class="activeArr.indexOf(item.id) !== -1 ? 'active' : ''"
        v-for="(item, index) in skinList"
        :key="index"
        @click="check(item.id)"
        ref="box"
        :style="{ '--ww': boxWidth + 'px' }"
      >
        <div class="price">
          <img src="@/assets/img/money.png" alt="" />
          <span>{{ item.price }}</span>
        </div>
        <img class="img" :src="item.img" alt="" />
        <div class="description">
          <div class="top">{{ item.exteriorName }}</div>
          <div class="bottom">{{ item.shortName }}</div>
        </div>
      </div>
    </div>
    <div class="list" v-if="isActive == 3">
      <div
        class="box"
        :class="activeArr.indexOf(item.id) !== -1 ? 'active' : ''"
        v-for="(item, index) in skinList"
        :key="index"
        @click="check(item.id)"
        ref="box"
        :style="{ '--ww': boxWidth + 'px' }"
      >
        <div class="price">
          <img src="@/assets/img/money.png" alt="" />
          <span>{{ item.price }}</span>
        </div>
        <img class="img" :src="item.img" alt="" />
        <div class="description">
          <div class="top">{{ item.exteriorName }}</div>
          <div class="bottom">{{ item.shortName }}</div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="roll-page">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        width="100%"
        small="true"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <!-- 底部 -->
    <div class="select" v-if="isActive == 1">
      <div class="selectAll">
        <span v-if="!liang" @click="selAll()"
          ><img src="./img/select2.png" alt=""
        /></span>
        <span v-if="liang" @click="offAll()"
          ><img src="./img/select1.png" alt=""
        /></span>
        <div>
          <span>全选</span>
          <span style="margin-left: 10px">已选：{{ activeArr.length }}件,</span>
          <span class="s2"
            >共<img class="img" src="@/assets/img/money.png" alt="" />{{
              allMoney
            }}</span
          >
        </div>
      </div>
      <div class="get">
        <span class="s1" @click="coniAll()">兑换</span>
        <span @click="conisAll()">取回</span>
      </div>
    </div>

    <span :plain="true">{{ exchangeHint }}</span>
    <!-- <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader> -->

    <div
      class="exchange-decoration"
      v-if="changeDecoration.length && !changeDecorations.length"
    >
      <div class="content">
        <span class="title">确定要回收吗?</span>
        <div class="operate">
          <div @click="changeDecoration = []">取消</div>
          <div @click="startExchange">确认</div>
        </div>
      </div>
    </div>
    <div
      class="exchange-decoration"
      v-if="changeDecoration.length && changeDecorations.length"
    >
      <div class="content">
        <span class="title">确定要提取吗?</span>
        <div class="operate">
          <div @click="NoExchange">取消</div>
          <div @click="startExchanges">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
import navheader from "@/components/navheader.vue";
export default {
  components: {
    navheader,
  },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      allMoney: 0,
      boxWidth: 160,
      activeArr: [],
      liang: false,
      isActive: 1,
      activeName: "one",
      loading: false,
      total: 0,
      total_value: 0,
      skinList: [],
      skinList2: [],
      skinList3: [],
      pageSize: 10,
      exchangeHint: "",
      checkedPrice: 0,
      random: "",
      exchangeType: "diamond",
      changeDecoration: [],
      page: 2,
      changeDecorations: [],
    };
  },
  methods: {
    changeList(index) {
      this.isActive = index
      this.getList()
    },
    getTab(tab) {
      if (tab.name == "one") {
        this.$route.query.type = "我的背包";
      } else if (tab.name == "two") {
        this.$route.query.type = "提取中的饰品";
      } else if (tab.name == "three") {
        this.$route.query.type = "已提取的饰品";
      }
      this.getList();
    },
    //兑换多个
    coniAll() {
      if (!Number.parseFloat(this.checkedPrice)) {
        this.$message.error("请选中饰品");
        return;
      }
      var arr = [];
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          return;
        }
      }
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state) {
          arr.push(this.skinList[i].id);
        }
      }
      this.changeDecoration = arr;
    },
    // 提取多个
    conisAll() {
      // console.log('提取')
      if (!Number.parseFloat(this.checkedPrice)) {
        this.$message.error("请选中饰品");
        return;
      }
      var arr = [];
      var arrs = [];
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          return;
        }
      }
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state) {
          arr.push(this.skinList[i].id);
          arrs.push(this.skinList[i].itemId);
        }
      }
      this.changeDecoration = arr;
      this.changeDecorations = arrs;
    },
    //单个选中
    check(id) {
      if (this.activeArr.indexOf(id) !== -1) {
        this.activeArr = this.activeArr.filter((item) => item !== id);
        let data = this.skinList;
        for (let i = 0; i < data.length; i++) {
          if (data[i].id == id) {
            this.allMoney = this.allMoney - parseFloat(data[i].price);
          }
        }
        this.allMoney = Number(this.allMoney.toFixed(2));
      } else {
        this.activeArr.push(id);
        let data = this.skinList;
        for (let i = 0; i < data.length; i++) {
          if (data[i].id == id) {
            this.allMoney = this.allMoney + parseFloat(data[i].price);
          }
        }
        this.allMoney = Number(this.allMoney.toFixed(2));
      }

      if (this.activeArr.length == this.skinList.length) {
        // console.log('xiangdenglu','ppp')
        this.liang = true;
      }else {
        this.liang = false
      }
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].id == id) {
          this.skinList[i].state = !this.skinList[i].state;
          // console.log(this.skinList[i].state1);
          if (this.skinList[i].state == true && !this.skinList[i].state1) {
            this.checkedPrice = (
              parseFloat(this.checkedPrice) + parseFloat(this.skinList[i].price)
            ).toFixed(2);
          } else {
            this.checkedPrice = (
              parseFloat(this.checkedPrice) - parseFloat(this.skinList[i].price)
            ).toFixed(2);
          }
        }
      }
      this.$forceUpdate();
    },
    //选择当前页
    selAll() {
      this.liang = true;
      let data = this.skinList;
      for (let i = 0; i < data.length; i++) {
        this.allMoney = this.allMoney + parseFloat(data[i].price);
      }
      this.allMoney = this.allMoney.toFixed(2);
      this.checkedPrice = 0;
      for (let i = 0; i < this.skinList.length; i++) {
        this.activeArr.push(this.skinList[i].id);
        if (!this.skinList[i].state1) {
          if (this.skinList[i].price != null) {
            this.skinList[i].state = true;
            this.checkedPrice = (
              parseFloat(this.checkedPrice) + parseFloat(this.skinList[i].price)
            ).toFixed(2);
          }
        }
      }
    },
    //取消当前页
    offAll() {
      this.liang = false;
      this.allMoney = 0;
      this.activeArr = [];
      this.checkedPrice = 0;
      for (let i = 0; i < this.skinList.length; i++) {
        this.skinList[i].state = false;
        this.checkedPrice = (
          parseFloat(this.checkedPrice) - parseFloat(this.skinList[i].price)
        ).toFixed(2);
      }
      this.$forceUpdate();
      this.tiqu = false;
    },
    /**
     * 兑换成 钻石
     * @param {*} id
     */
    exchangeToMoney(id) {
      let param = {
        player_skins_ids: id,
      };
      this.$axios
        .post("/api/rucksack/exchangetomoney", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.code == "1") {
            this.getList(this.page);
            this.$store.commit("getMoney", data.data.total_amount);
            Utils.$emit("masonry", data.data.total_amount);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
            this.changeDecoration = [];
            this.changeDecorations = []
            this.getList()
          } else {
            for (let i = 0; i < this.skinList.length; i++) {
              if (id == this.skinList[i].id) {
                this.skinList[i].loading1 = false;
                this.skinList[i].loading2 = false;
              }
            }
            this.$forceUpdate();
            this.$message({
              showClose: true,
              message: data.msg,
              type: "warning",
            });
            this.getList()
          }
        });
    },
    /**
     * 兑换成 金币
     * @param {*} id
     */
    exchangeToMoney2(id) {
      let param = {
        player_skins_ids: id,
      };
      this.$axios
        .post("/index/User/exchangeToMoney2", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.getList(this.page);
            this.$store.commit("getMoney", res.data.data.total_amount);
            Utils.$emit("money", res.data.data.total_amount);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
            this.changeDecoration = [];
          } else {
            for (let i = 0; i < this.skinList.length; i++) {
              if (id == this.skinList[i].id) {
                this.skinList[i].loading1 = false;
                this.skinList[i].loading2 = false;
              }
            }
            this.$forceUpdate();
            this.$message({
              showClose: true,
              message: data.msg,
              type: "warning",
            });
          }
        });
    },

    //点击取回
    // getPull(event, id, steamId) {
    //   event.stopPropagation();
    //   for (let i = 0; i < this.skinList.length; i++) {
    //     if (id == this.skinList[i].id) {
    //       this.skinList[i].loading1 = true;
    //       this.skinList[i].loading2 = true;
    //       this.skinList[i].state1 = true;
    //     }
    //   }
    //   this.$forceUpdate();
    //   let param = {
    //     player_skins_id: id,
    //     itemId: steamId,
    //     random: this.random,
    //   };
    //   this.$axios
    //     .post("/api/rucksack/skintosteam", this.$qs.stringify(param))
    //     .then((res) => {
    //       // console.log(res.data);
    //       var data = res.data;
    //       if (data.code == "1") {
    //         this.getList(this.page);
    //         this.$message({
    //           showClose: true,
    //           message: data.msg,
    //           type: "success",
    //         });
    //       } else {
    //         var hint = "";
    //         if (data.msg.indexOf("余额不足") != -1) {
    //           hint = "取回错误，请联系客服";
    //         } else if (data.msg.indexOf("取回失败,请充值") != -1) {
    //           hint = "为防止机器人恶意注册，进行任意充值即可开箱提取哦~";
    //         } else {
    //           hint = data.msg;
    //         }
    //         this.$message({
    //           showClose: true,
    //           message: hint,
    //           type: "warning",
    //         });
    //         for (let i = 0; i < this.skinList.length; i++) {
    //           if (id == this.skinList[i].id) {
    //             this.skinList[i].loading1 = false;
    //             this.skinList[i].loading2 = false;
    //             this.skinList[i].state1 = false;
    //           }
    //         }
    //         this.$forceUpdate();
    //       }
    //       this.randomString();
    //     });
    // },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      this.random = n;
    },
    quhuijc() {
      this.$router.push({
        path: "jiaocheng",
      });
    },
    //点击兑换
    getExchange(event, id) {
      event.stopPropagation();
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          return;
        }
      }
      for (let i = 0; i < this.skinList.length; i++) {
        if (id == this.skinList[i].id) {
          this.skinList[i].loading1 = true;
          this.skinList[i].loading2 = true;
          this.checkedPrice = this.skinList[i].price;
        }
      }
      this.$forceUpdate();
      this.changeDecoration = [id];
    },
    startExchange() {
      this.liang = false;
      this.allMoney = 0;
      this.activeArr = [];
      this.checkedPrice = 0;

      // 兑换金币
      // if (this.exchangeType == "dollar") {
      //   this.exchangeToMoney2(this.changeDecoration.join());
      // } else {
      //   // 兑换钻石
      //   console.log(this.changeDecoration,'this.changeD')
      //   this.exchangeToMoney(this.changeDecoration.join());
      // }
      this.exchangeToMoney(this.changeDecoration.join());
      this.checkedPrice = 0;
      this.activeArr = []
      this.allMoney = 0
    },
    //点击取回
    getPulls(id, steamId) {
      for (let i = 0; i < this.skinList.length; i++) {
        if (id == this.skinList[i].id) {
          this.skinList[i].loading1 = true;
          this.skinList[i].loading2 = true;
          this.skinList[i].state1 = true;
        }
      }
      this.$forceUpdate();
      let param = {
        player_skins_ids: id,
        itemId: steamId,
        random: this.random,
      };
      this.$axios
        .post("/api/rucksack/skintosteam", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          console.log(res.data,'data')
          if (data.code == "1") {
            this.getList(this.page);
            this.changeDecoration = [];
            this.changeDecorations = [];
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
            this.getList()
          } else {
            this.changeDecoration = [];
            this.changeDecorations = [];
            var hint = "";
            if (data.msg.indexOf("余额不足") != -1) {
              hint = "取回错误，请联系客服";
            } else if (data.msg.indexOf("取回失败,请充值") != -1) {
              hint = "为防止机器人恶意注册，进行任意充值即可开箱提取哦~";
            } else {
              hint = data.msg;
            }
            this.$message({
              showClose: true,
              message: hint,
              type: "warning",
            });
            for (let i = 0; i < this.skinList.length; i++) {
              if (id == this.skinList[i].id) {
                this.skinList[i].loading1 = false;
                this.skinList[i].loading2 = false;
                this.skinList[i].state1 = false;
              }
            }
            this.$forceUpdate();
          }
          this.getList()
          this.randomString();
        });
    },
    startExchanges() {
      this.liang = false;
      this.allMoney = 0;
      this.activeArr = [];
      this.checkedPrice = 0;
      // 兑换
      this.getPulls(this.changeDecoration.join(), this.changeDecorations.join());
      // this.checkedPrice = 0;
      this.activeArr = []
      this.allMoney = 0
    },
    NoExchange() {
      this.changeDecoration.forEach((e) => {
        for (let i = 0; i < this.skinList.length; i++) {
          if (e == this.skinList[i].id) {
            this.skinList[i].loading1 = false;
            this.skinList[i].loading2 = false;
          }
        }
      });
      this.$forceUpdate();
      this.changeDecoration = [];
      this.changeDecorations = [];
    },
    currentChange(val) {
      this.getList(val);
      this.checkedPrice = 0;
    },
    getList(page) {
      this.page = page;
      let param = {
        pagesize: this.pageSize,
        page: this.page,
      };
      var url = "/api/rucksack/packagelist";
      this.pageSize = 10
      if (this.isActive ==2) {
        url = "/api/rucksack/processinglist";
        this.pageSize = 100
      }
      if (this.isActive == 3) {
        url = "/api/rucksack/backlist";
        this.pageSize = 100
      }
      this.$axios.post(url, this.$qs.stringify(param)).then((res) => {
        var data = res.data;
        this.skinList = [];
        if (data.code == "1") {
          this.total = data.data.total;
          this.total_value = data.data.total_value;
          this.skinList = data.data.data;
        }
        if (data.msg == "无数据") {
          this.skinList = [];
        }
      });
    },
    getList3(page) {
      this.page = page;
      let param = {
        pageSize: this.pageSize,
        page: this.page,
      };

      url = "/index/User/backList";
      this.$axios.post(url, this.$qs.stringify(param)).then((res) => {
        var data = res.data;
        this.skinList = [];
        if (data.status == "1") {
          this.total = data.data.total;
          this.total_value = data.data.total_value;
          this.skinList = data.data.skinList;
        }
        if (data.msg == "无数据") {
          this.skinList3 = [];
        }
      });
    },
    getList2(page) {
      this.page = page;
      let param = {
        pageSize: this.pageSize,
        page: this.page,
      };

      url = "/index/User/processingList";
      this.$axios.post(url, this.$qs.stringify(param)).then((res) => {
        var data = res.data;
        this.skinList = [];
        if (data.status == "1") {
          this.total = data.data.total;
          this.total_value = data.data.total_value;
          this.skinList = data.data.skinList;
        }
        if (data.msg == "无数据") {
          this.skinList3 = [];
        }
      });
    },
  },
  mounted() {
    this.$nextTick(()=> {
      this.boxWidth = this.$refs.box[0].clientWidth;
    })
    window.onresize = () => {
      this.boxWidth = this.$refs.box[0].clientWidth;
    };
    if (!this.$route.query.type) {
      this.$route.query.type = "我的背包";
    }
    this.randomString();
    this.getList();
    this.getList2();
    this.getList3();
  },
};
</script>

<style lang="less" scoped>
.exchange-decoration {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999999999;
  background-color: rgba(0, 0, 0, 0.6);
  display: grid;
  place-items: center;

  .content {
    width: 80%;
    max-width: 500px;
    background-color: #1b2330;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    box-sizing: border-box;
    padding: 30px 0px 0 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 20px;
      flex: 1;
      font-weight: bold;
      margin: 0 0 30px 0;
    }

    .types {
      width: 70%;
      margin-bottom: 50px;

      .active-btn {
        // background-color: #e9b10e;
        // color: #000 !important;
        font-weight: bold;
      }

      .btn {
        margin: 10px 0;
        height: 40px;
        line-height: 40px;
        color: #fff;
        cursor: pointer;
        border-radius: 8px;
        // border: 1px solid #2b3441;
        display: flex;
        justify-content: center;
        align-items: center;

        .dollar {
          width: 20px;
          height: 20px;
          margin-right: 3px;
        }

        .diamond {
          width: 17px;
          margin-right: 3px;
          height: 17px;
        }
      }
    }

    .operate {
      width: 100%;
      height: 50px;
      font-weight: bold;
      display: flex;
      border-top: 1px solid #000;

      & > div {
        flex: 1;
        display: grid;
        place-items: center;
        cursor: pointer;

        &:first-child {
          border-right: 1px solid #000;
        }
      }
    }
  }
}

.main {
  overflow: hidden;
  //   height: calc(100vh - 145px);
  width: 100vw;
  background-image: url(../assets/img/bg-move.jpg) !important;
  background-size: 100% 100%;
  background-position: 0px 0px;
  // background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  /* 兼容Webkit内核浏览器如Chrome和Safari */
  -o-background-size: cover;
  /* 兼容Opera */
  zoom: 1;
  padding: 0 15px;
  box-sizing: border-box;
  position: relative;
  .navs {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .active {
      background-color: #9434d8;
    }
    div {
      padding: 8px 10px;
      margin-right: 10px;
      color: white;
      font-size: 16px;
      border: 1px solid #9434d8;
      background-color: #1c203e;
    }
  }
  .list {
    width: 100%;
    // height: calc(100% - 260px);
    overflow: scroll;
    // background-color: white;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    .active {
      border: 2px solid #ff31ed;
    }
    .box {
      width: 46%;
      height: var(--ww);
      // height: 160px;
      border-radius: 10px;
      color: white;
      position: relative;
      background-color: #1a1f30;
      background-image: url(./img/box-bg.png);
      background-size: 100% 100%;
      margin-top: 10px;
      box-sizing: border-box;
      cursor: pointer;
      &::after {
        display: block;
        content: "";
        width: 100%;

        height: 2px;
        position: absolute;
        bottom: 0;
        // background: var(--color);
        // background-color: white;
      }
      .price {
        position: absolute;
        top: 4px;
        right: 6px;
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: auto;
        }
      }
      .img {
        width: 60%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
      }
      .description {
        position: absolute;
        bottom: 5px;
        left: 5px;
        font-size: 14px;
      }
    }
  }
  .roll-page {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    // position: absolute;
    // left: 0;
    // bottom: 150px;
    // z-index: 99;
    margin-bottom: 100px;
  }
  .select {
    width: calc(100% - 30px);
    margin-left: 15px;
    height: 90px;
    background-color: #1b212d;
    position: fixed;
    left: 0;
    bottom: 0px;
    padding-top: 10px;
    box-sizing: border-box;
    border-radius: 15px 15px 0 0;
    z-index: 99;
    .selectAll {
      color: white;
      font-size: 15px;
      display: flex;
      margin-left: 10px;
      // align-items: flex-end;
      div {
        margin-top: 5px;
        display: flex;
        margin-left: 10px;
        .s2 {
          display: flex;
          align-items: center;
          margin-top: -4px;
        }
        img {
          width: 20px;
          height: auto;
        }
      }
      img {
        width: 25px;
        height: auto;
        color: yellow;
      }
    }
    .get {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: white;
      margin: 4px 0;
      .s1 {
        border: 2px solid #6917ff;
      }
      span {
        padding: 5px 23px;
        background-color: #231f42;
        border-radius: 20px;
        border: 2px solid #f530e4;
        box-sizing: border-box;
        margin-right: 15px;
      }
    }
  }
}
</style>