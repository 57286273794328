<template>
  <!-- <div class="areroom">
		<div class="room-warp">
			<div class="room-title">
				<div class="title-right">
					<span class="break">
						<i class="el-icon-timer"></i>
						<span>截止时间: {{ roomData.run_lottery_time }}</span>
					</span>
				</div>
			</div>
			<div class="room-top">
				<div class="roomtop-left">
					<div class="room-img">
						<img :src="roomData.image" />
						<span class="span1" v-if="roomData.type == 2">主播</span>
						<span class="span1" v-if="roomData.type == 1">官方</span>
					</div>
					<div class="room-int">
						<h5>{{ roomData.room_name }}</h5>
						<div class="roomint-top">
							<span>规则说明:{{ roomData.desc }}</span>
						</div>
						<div class="roomint-bot"></div>
					</div>
				</div>
				<div class="num">
					<div class="num-box">
						<span class="num-span">{{ roomData.count ? roomData.count: 0 }}</span>
						<span>饰品数量</span>
					</div>
					<div class="num-box">
						<span class="num-span">{{ roomData.person_num ? roomData.person_num: 0}}</span>
						<span>参与人数</span>
					</div>
					<div class="num-box">
						<span class="num-span">{{ roomData.pool ? roomData.pool: 0}}</span>
						<span>饰品价值</span>
					</div>
				</div>
			</div>
			<div class="roomtop-right">
				<div class="btn-warp" @click="addRoom" v-if="!status && roomData.status != 2">
					<img v-if="roomData.condition_type == 1 || roomData.condition_type == 3"
						src="../assets/img/suo1.png" />
					<span>加入房间</span>
				</div>
				<div class="btn-warp" v-if="status && roomData.status != 2">
					<span>已加入</span>
				</div>
				<div class="btn-warp" v-if="roomData.status == 2">已结束</div>
			</div>
			<div class="box-bottom">
				<div :class="selectType == 1?'select':''" @click="changeSelectType(1)">
					奖池
				</div>
				<div :class="selectType == 2?'select':''" @click="changeSelectType(2)">
					参与用户
				</div>
				<div :class="selectType == 3?'select':''" @click="changeSelectType(3)">
					中奖用户
				</div>
			</div>
			<div class="boxlist-bot" v-if="selectType == 1">
				<ul>
					<li v-for="(item, index) in list" :key="index" class="card">
						<div class="boxlist-warp">
							<div class="boxlist1-top">
								<img :src="item.img" />
							</div>
							<div>
								<div class="boxlist1-bot" :title="item.name" style="margin-bottom:10px">
									{{ item.name }}
								</div>
								<div class="list-pirce" style="">
									<div class="pirce-left">
										<img src="../assets/img/money.png" />
										<div>
											<span>{{ item.price }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li style="height: 0;"></li>
					<li style="height: 0;"></li>
					<li style="height: 0;"></li>
					<li style="height: 0;"></li>
				</ul>
			</div>
			<div class="boxlist-bot" v-if="selectType == 3">
				<ul>
					<li v-for="(item, index) in player_skin" :key="index" class="card">
						<div class="boxlist-warp">
							<div class="boxlist1-top">
								<span class="ico4">
									<img :src="item.player_img" />
									{{ item.player_name }}
								</span>
								<img :src="item.skin_img" />
							</div>
							<div>
								<div class="boxlist1-bot" :title="item.name">
									{{ item.name }}
								</div>
								<div class="list-pirce" style="">
									<div class="pirce-left" style="">
										<img src="../assets/img/money.png" />
										<div>
											<span>{{ item.price }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li style="height: 0;"></li>
					<li style="height: 0;"></li>
					<li style="height: 0;"></li>
					<li style="height: 0;"></li>
				</ul>
			</div>
			<div class="room-num" v-if="selectType == 2">
				<div class="num-list">
					<ul>
						<li v-for="(item, index) in player_list" :key="index">
							<img :src="item.img" />
							<span>{{item.name}}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
		
		<div class="pass" v-if="passState">
      <div class="pass-box" :class="{ pc: showNav }">
        <span class="pass-hint" @click="hidePass"
          ><i class="el-icon-close"></i
        ></span>
        <div class="pass-title">输入房间口令</div>
        <div class="input">
          <input type="text" v-model="coin" />
        </div>
        <div class="pass-btn">
          <el-button class="cancel" type="warning" plain @click="passState = false"
            >取消</el-button
          >
          <el-button class="submit" type="warning" @click="passAddRoom"
            >确认</el-button
          >
        </div>
      </div>
    </div>

	</div> -->

  <div class="main">
    <div class="pass" v-if="passState">
      <div class="pass-box" :class="{ pc: showNav }">
        <span class="pass-hint" @click="hidePass"
          ><i class="el-icon-close"></i
        ></span>
        <div class="pass-title">输入房间口令</div>
        <div class="input">
          <input type="text" v-model="coin" />
        </div>
        <div class="pass-btn">
          <el-button
            class="cancel"
            type="warning"
            plain
            @click="passState = false"
            >取消</el-button
          >
          <el-button class="submit" type="warning" @click="passAddRoom"
            >确认</el-button
          >
        </div>
      </div>
    </div>
    <div class="back">
      <div class="left" @click="$router.push('/Arena')">
        <span class="el-icon-arrow-left l"></span>
        <span>返回</span>
      </div>
      <div class="right">
        <!-- <img src="./img/hhh.png" alt="" />
        <span>当前种子</span> -->
      </div>
    </div>
    <div class="roomInfo">
      <div class="info">
        <div class="avatar">
          <img :src="roomData.image" alt="" class="img" />
          <img
            src="./img/avatar-bottom-guan.png"
            alt=""
            class="bo"
            v-if="roomData.type == 1"
          />
          <img src="./img/avatar-bottom-zhu.png" alt="" class="bo" v-else-if="roomData.type == 2" />
        </div>
        <div class="num" v-if="width">
          <div class="price">
            <img src="./img/price.png" alt="" />
            <span class="all">
              <span class="zi">总价值</span
              ><span>{{ roomData.pool ? roomData.pool : 0 }}</span>
            </span>
          </div>
          <div class="price" style="">
            <img src="./img/wupin.png" alt="" />
            <span class="all">
              <span class="zi">物品</span
              ><span>{{ roomData.count ? roomData.count : 0 }}</span>
            </span>
          </div>
          <div class="price">
            <img src="./img/player.png" alt="" />
            <span class="all">
              <span class="zi">玩家</span
              ><span>{{ roomData.person_num ? roomData.person_num : 0 }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="other">
        <div class="title">
          <span>{{ roomData.room_name }}</span>
        </div>
        <div class="row1">
          参与条件：<span>{{ roomData.desc }}</span>
        </div>
        <div class="row2">
          <div class="left">
            <img src="./img/jishi.png" alt="" />
            <div>开奖倒计时</div>
          </div>
          <div class="right">
            <span class="s" v-if="dTime>0">
              <span>{{ dTime }}</span>
              <span>D</span>
            </span>
            <span style="margin: 0 6px" v-if="dTime>0">:</span>
            <span class="s">
              <span>{{ hTime }}</span>
              <span>H</span>
            </span>
            <span style="margin: 0 6px">:</span>
            <span class="s">
              <span>{{ mTime }}</span>
              <span>M</span>
            </span>
            <span style="margin: 0 6px">:</span>
            <span class="s">
              <span>{{ sTime }}</span>
              <span>S</span>
            </span>
          </div>
        </div>
        <div class="row4" v-if="!width">
          <div class="price">
            <span class="all">
              <img src="./img/price.png" alt="" />
              <span class="zi">总价值</span>
            </span>
            <span>{{ roomData.pool ? roomData.pool : 0 }}</span>
          </div>
          <div class="price" style="">
            <span class="all">
              <img src="./img/wupin.png" alt="" />
              <span class="zi">物品</span>
            </span>
            <span>{{ roomData.count ? roomData.count : 0 }}</span>
          </div>
          <div class="price">
            <span class="all">
              <img src="./img/player.png" alt="" />
              <span class="zi">玩家</span>
            </span>
            <span>{{ roomData.person_num ? roomData.person_num : 0 }}</span>
          </div>
        </div>
        <div class="row3 none" v-if="roomData.is_in == 1">
          <img src="./img/chafang-ac.png" alt="" />
          <span>已参与</span>
        </div>
        <div class="row3 ccc" v-else @click="addRoom">
          <img src="./img/chafang.png" alt="" />
          <span>加入房间</span>
        </div>
      </div>
    </div>

    <div class="winlist" v-if="roomType == 2">
      <div class="titlewin">
        <img src="./img/winlist.png" alt="" />
        <span>获奖名单</span>
      </div>
      <div class="list">
        <div class="item" v-for="item in winList" :key="item.player_id">
          <div class="row1">
            <img src="./img/money.png" alt="" />
            <span>{{ item.price }}</span>
          </div>
          <div class="wuqi">
            <img :src="item.skin_img" alt="" />
          </div>
          <div class="row2">
            <div class="h22">{{ item.shortName }}</div>
            <span style="fontsize: 13px; color: #a9abbc; margin-top: 3px">{{
              item.exteriorName
            }}</span>
          </div>
          <div class="bottom">
            <img :src="item.player_img" alt="" />
            <div>{{ item.player_name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="changeList" v-if="roomType == 2">
      <span
        @click="showList = true"
        style="margin-right: 10px"
        :class="{ activeH: showList }"
        >包含奖励</span
      >
      <span @click="showList = false" :class="{ activeH: !showList }"
        >参与用户</span
      >
    </div>
    <div class="listBox" v-if="showList == 1">
      <div class="title" v-if="roomType != 2">
        <div class="left">
          <img src="./img/baohan.png" alt="" v-if="!width" />
          <span>包含奖励</span>
        </div>
        <div class="right" v-if="width">
          <img src="./img/dao.png" alt="" style="margin-top: 4px" />
          <span class="wei" style="margin: 0 5px">物品</span>
          <span class="huang wei">{{
            roomData.count ? roomData.count : 0
          }}</span>
          <span
            style="
              margin: 2px 13px 0;
              width: 2px;
              height: 13px;
              background: #292933;
            "
          ></span>
          <img src="./img/liwu.png" alt="" />
          <span class="wei" style="margin: 0 5px">总价值</span>
          <img
            src="@/assets/img/money.png"
            alt=""
            style="width: 20px; height: auto; margin-top: 4px"
          />
          <span class="huang wei">{{ roomData.pool ? roomData.pool : 0 }}</span>
        </div>
      </div>
      <div class="list">
        <div class="item" v-for="item in list" :key="item.id">
          <div class="row1 wei">
            <p>{{ item.shortName }}</p>
          </div>
          <div class="row2">{{ item.exteriorName }}</div>
          <img :src="item.img" alt="" />
          <div class="row3">
            <img
              src="@/assets/img/money.png"
              alt=""
              style="width: 20px; height: auto; margin-right: 3px"
            />
            <span class="wei" style="color: rgb(255, 198, 0)">{{
              item.price
            }}</span>
          </div>
        </div>
      </div>
      <!-- <div style="height:20px;width:100%"></div> -->
    </div>
    <div class="titleuser" v-if="roomType != 2">
      <span>参与用户</span>
      <span class="users">
        <img src="./img/user.png" alt="" />
        <span style="margin-right: 5px">参与用户</span>
        <span class="num">{{ joinList.length }}</span>
      </span>
    </div>
    <div class="user" v-if="roomType != 2">
      <div class="item" v-for="item in joinList" :key="item.id">
        <img :src="item.image" alt="" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <div class="user" v-if="roomType == 2 && showList == false">
      <div class="item" v-for="item in joinList" :key="item.id">
        <img :src="item.image" alt="" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showList: true,
      roomType: 1,
      password: "",
      passState: false,
      id: "",
      roomData: {},
      list: [],
      winList: [],
      jioniList: [],
      player_list: [],
      price: 0,
      player_skin: [],
      player_skin_price: 0,
      status: false,
      selectType: 1,
      time: "",
      dTime: 0,
      hTime: 0,
      mTime: 0,
      sTime: 0,
      timer: 0,
      total: "",
      width: true,
    };
  },
  async mounted() {
    if (document.body.clientWidth < 600) {
      this.width = false;
    }
    //console.log(this.$route.query.id);
    this.id = this.$route.query.id;
    this.status = this.$route.query.status;
    await this.getRoomList();
    this.timer = setInterval(() => {
      this.getTime(this.time);
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getTime(time) {
      let time1 = Date.parse(time);
      let date = new Date().getTime();
      let data = time1 - date;
      var days = Math.floor(data / (1000 * 60 * 60 * 24));
      var hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = parseInt((data % (1000 * 60)) / 1000);
      if (days < 0) {
        days = 0;
      }
      if (hours < 0) {
        hours = 0;
      }
      if (minutes < 0) {
        minutes = 0;
      }
      if (seconds < 0) {
        seconds = 0;
      }
      this.dTime = days;
      this.hTime = hours;
      this.mTime = minutes;
      this.sTime = seconds;
      //   console.log(hours,minutes,seconds,'timeeeee')
    },
    changeSelectType(e) {
      this.selectType = e;
    },
    //加入房间
    addRoom() {
      if (
        this.roomData.condition_type == 1 ||
        this.roomData.condition_type == 3
      ) {
        this.passState = true;
        return;
      } else {
        var param = {
          free_room_id: this.id,
          password: "",
        };
      }

      this.$axios
        .post("/api/free/addfreeroom", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.code == "1") {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.getRoomList();
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        });
    },
    //隐藏密码框
    hidePass() {
      this.passState = false;
    },
    //密码加入房间
    passAddRoom() {
      let param = {
        free_room_id: this.id,
        password: this.password,
      };
      this.$axios
        .post("/api/free/addfreeroom", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.code == "1") {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.getRoomList();
            this.passState = false;
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        });
    },
    //房间数据
    getRoomList() {
      let param = {
        free_room_id: this.id,
      };
      this.$axios
        .post("/api/free/freeroomdetail", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.code == "1") {
            this.player_list = data.data.player_list;
            this.player_list.forEach((e) => {
              console.log(e);
              if (e.id == JSON.parse(localStorage.getItem("userInfo")).id) {
                this.status = true;
              }
            });
            this.roomData = data.data;
            this.roomType = data.data.status;
            this.list = data.data.skin_list;
            this.winList = data.data.player_skin;
            this.joinList = data.data.player_list;
            this.time = data.data.run_lottery_time;

            if (data.data.status == 2) {
              this.player_skin = data.data.player_skin;
              //中奖记录总价
              for (let i = 0; i < this.player_skin.length; i++) {
                this.player_skin_price += Number(this.player_skin[i].price);
              }
              this.player_skin_price = this.player_skin_price.toFixed(2);
            }

            //奖池奖品总价
            /* for (let i = 0; i < this.list.length; i++) {
							  this.price += Number(this.list[i].price);
							}*/
            this.price = data.data.pool;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.box-bottom {
  display: flex;
  background-color: #2b2c37;
  color: white;
  margin-top: 12px;
  border-radius: 16px;

  div {
    border-radius: 16px;
    width: 33%;
    text-align: center;
    padding: 6px 12px;
  }

  .select {
    background-color: #ffba00;
    color: black;
    font-weight: 600;
  }
}

@media screen and (max-width: 767px) {
  .areroom {
    padding: 0 !important;
  }

  .boxlist-bot {
    zoom: 0.64;
    padding: 10px 0px;

    ul {
      display: flex !important;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 12px;
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(160px, 160px));
      // gap: 8px;

      li {
        margin-left: 0 !important;
        margin-top: 12px;
        width: 48% !important;
        // background: url("../assets/img/arenaroom.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        max-height: auto !important;
        height: auto !important;

        .boxlist-warp {
          margin: 0 !important;
          padding-bottom: 0 !important;
        }

        .boxlist1-top {
          padding: 20px 50px 60px 50px !important;
          background-size: 100% 100%;
          text-align: center;
          // border-bottom: 3px solid #f1a920;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          height: auto !important;
          // position: absolute;
          // top: 0;
          // bottom: 0;
          // left: 0;
          // right: 0;
          // margin: auto;

          // box-shadow: 0px 5px 10px #f1a920;
          img {
            width: auto;
            height: 100% !important;
            max-height: auto !important;
            margin: 12px 0 !important;
            cursor: pointer;
          }
        }

        .boxlist1-bot {
          padding: 8px 0;
          width: 100%;
          margin: auto;
          color: white;
          font-family: "微软雅黑";
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: left;
          white-space: nowrap;
          padding: 0 !important;
          line-height: 24px;
          font-size: 12px !important;
          font-weight: bold;
          word-wrap: break-word;
          position: absolute;
          left: 0;
          left: 6px !important;
          bottom: 0;
        }

        .boxlist1-bot2 {
          height: 16px;
          width: 100%;
          margin: auto;
          color: rgb(204, 204, 204);
          font-family: "微软雅黑";
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 12px !important;
          text-align: right;
          word-wrap: break-word;
          position: absolute;
          right: 0;
          bottom: 12px !important;
        }

        .boxlist1-bot1 {
          padding: 8px;
          display: flex;
          align-items: center;

          img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
          }

          span {
            margin-left: 8px;
            color: #c3c3e2;
            font-size: 12px !important;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .list-pirce {
          padding: 0px 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          font-size: 12px !important;
          bottom: -22px;
        }

        .ico {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 12px;
          color: #04f540;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 2px 4px;
        }

        .pirce-left {
          display: flex;
          align-items: center;
          padding-right: 10px;

          img {
            width: auto;
            height: 20px;
            margin-right: 5px;
          }

          & > div {
            flex: 1;
            color: #f4bd50;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span:last-child {
              font-size: 12px !important;
              font-family: "微软雅黑";
              color: white;
            }
          }
        }
      }
    }
  }
}

.boxlist-bot {
  padding: 10px 0px;

  ul {
    display: flex;
    width: 100%;
    margin-bottom: 12px;
    flex-wrap: wrap;
    justify-content: space-between;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(160px, 160px));
    // gap: 8px;

    li {
      width: 160px;
      width: 24%;
      background: url("../assets/img/arenaroom.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      margin-top: 12px;

      .boxlist-warp {
        // background-color: #24252f;
        border-radius: 5px;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 20px);

        .boxlist1-top {
          background-size: 100% 100%;
          overflow: hidden;
          padding-top: 24px;
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 50px 20px 70px 20px;

          // height: 130px;
          img {
            width: auto !important;
            height: 120px !important;
            max-height: 120px !important;
          }

          .ico4 {
            position: absolute;
            left: 5px;
            top: 5px;
            display: flex;
            align-items: center;
            color: white;
            img {
              width: 55px !important;
              height: 55px !important;
              border-radius: 50%;
            }
          }
        }

        .boxlist1-bot {
          padding: 8px 0;
          width: 100%;
          margin: auto;
          color: white;
          font-family: "微软雅黑";
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          line-height: 24px;
          font-size: 16px;
          word-wrap: break-word;
          position: absolute;
          left: 0;
          bottom: 0;
          text-align: center;
          margin: auto;
        }

        .boxlist1-bot2 {
          height: 24px;
          width: 100%;
          margin: auto;
          // color: rgb(204, 204, 204);
          color: white;
          font-family: "微软雅黑";
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: right;
          word-wrap: break-word;
          position: absolute;
          right: 0;
          bottom: 24px;
        }

        .boxlist1-bot1 {
          padding: 8px;
          display: flex;
          align-items: center;

          img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
          }

          span {
            margin-left: 8px;
            color: #c3c3e2;
            font-size: 16px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .list-pirce {
          padding: 0px 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          text-align: center;
          left: 0;
          right: 0;
          bottom: -12px;
          margin: auto;
        }

        .ico {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 14px;
          color: #04f540;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 2px 4px;
        }

        .pirce-left {
          display: flex;
          align-items: center;
          padding-right: 10px;
          margin: auto;

          img {
            width: auto;
            height: 20px;
            margin-right: 5px;
          }

          & > div {
            flex: 1;
            color: #f4bd50;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span:last-child {
              font-family: "微软雅黑";
              font-size: 16px;
              color: white;
            }
          }
        }

        .pirce-right {
          // color: #ffae00 !important;
          color: white;
          // font-weight: 700;
          font-size: 16px;
          font-family: "微软雅黑";
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.areroom {
  width: 100;
  height: 100vh;
  // overflow: hidden;
  overflow-y: auto;
  padding: 0 500px;
  background-color: #06090f;

  .room-warp {
    height: 100%;
    padding: 16px;
  }

  .room-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-left {
      font-size: 16px;
      color: #848492;

      span {
        color: #c3c3e2;
      }
    }

    .title-right {
      font-size: 16px;
      color: #848492;

      i {
        margin-left: 10px;
      }

      span {
        margin-left: 5px;
      }
    }
  }

  .room-top {
    margin-top: 30px;
    background-color: #2b2c37;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .num {
      width: 100%;
      padding: 0 16px;
      margin-top: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .num-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;

        span {
          color: #848492;
          font-size: 10px;
        }

        span:last-child {
          margin-top: 5px;
        }

        .num-span {
          color: white;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }

    .roomtop-left {
      display: flex;
      align-items: flex-start;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid #444659;

      .room-img {
        // padding-right: 20px;
        // border-right: 1px solid #444659;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          border: 1px solid #e9b10e;
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }

        .span1 {
          margin-top: -13px;
          font-size: 13px;
          background-color: #e9b10e;
          padding: 0 6px;
          border-radius: 4px;
        }

        .span2 {
          margin-top: 10px;
          color: #c3c3e2;
        }
      }

      .room-int {
        margin-left: 20px;
        color: white;

        h5 {
          font-size: 16px;
          font-weight: 600;
        }

        span {
          font-size: 14px;
          color: #848492;
        }
      }
    }
  }

  .roomtop-right {
    margin-top: 12px;

    .btn-warp {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e9b10e;
      border-radius: 5px;
      padding: 9px 22px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }

      span {
        font-size: 15px;
        color: #1a1c24;
        font-weight: 600;
      }
    }

    .btn-warp:hover {
      cursor: pointer;
    }

    .btn-warp1 {
      font-size: 15px;
      color: #c3c3e2;
    }
  }

  .win-top {
    display: flex;
    margin-top: 15px;
    align-items: center;

    h5 {
      font-size: 20px;
      font-weight: 400;
      color: #c3c3e2;
    }

    h6 {
      margin-left: 20px;
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: center;
      color: #848492;

      img {
        height: 16px;
        width: auto;
      }

      span {
        padding: 0 4px;
        color: #e9b10e;
      }
    }
  }

  .room-win {
    margin-top: 30px;

    .win-con {
      margin-top: 20px;
      background-color: #2b2c37;
      padding: 16px;
      border-radius: 5px;

      ul {
        display: flex;
        flex-wrap: wrap;

        // justify-content: space-evenly;
        li {
          width: 12.5%;

          .win-warp {
            background-image: url("../assets/img/lucky.jpg");
            background-size: 100% 100%;
            margin: 2px;
            padding: 0 10px;
            background-color: #fff;
            position: relative;
            border-radius: 5px;

            span {
              position: absolute;
              top: 4px;
              left: 4px;
              background-color: rgba(0, 0, 0, 0.6);
              font-size: 12px;
              padding: 0 5px;
              border-radius: 10px;
              color: #fff;
            }

            img {
              width: 100%;
              height: auto;
              // height: 70px;
              // width: auto;
            }
          }
        }
      }
    }
  }

  .win-con1 {
    margin-top: 20px;
    background-color: #2b2c37;
    padding: 16px;
    border-radius: 5px;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 14.28%;

        .win-warp {
          margin: 2px;
          background-color: #24252f;
          position: relative;
          border-radius: 5px;

          .win-warp1 {
            padding: 0 10px;
            background-image: url("../assets/img/lucky.jpg");
            background-size: 100% 100%;

            img {
              width: 100%;
              max-height: 70px;
            }
          }

          .win-name {
            margin-top: -25px;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }

            span {
              font-size: 14px;
              color: #c3c3e2;
              // margin-top: 5px;
              padding-bottom: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .span1 {
            position: absolute;
            top: 4px;
            left: 4px;
            background-color: rgba(0, 0, 0, 0.6);
            font-size: 12px;
            padding: 0 5px;
            border-radius: 10px;
            color: #fff;
          }

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .room-num {
    margin-top: 30px;

    .num-list {
      margin-top: 20px;
      background-color: #2b2c37;
      padding: 16px;
      border-radius: 5px;

      ul {
        margin-top: -40px;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        // padding-bottom: 22px;
        // display: grid !important;
        // grid-gap: 10px 0;
        justify-content: space-between;

        // grid-template-columns: repeat(auto-fit, minmax(21.5%,20%));
        // margin: auto;
        li {
          // margin-right: 20px;
          margin-top: 40px;
          width: 50px;
          height: 50px;
          text-align: center;
          margin-left: 4px;

          span {
            display: inline-block;
            width: 50px;
            text-overflow: ellipsis;
            text-align: center;
            overflow: hidden;
            font-size: 12px;
            white-space: nowrap;
            color: white;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
    }
  }

  // .pass-box {
  // 	position: fixed;
  // 	top: 30%;
  // 	left: 50%;
  // 	width: 300px;
  // 	margin-left: -150px;
  // 	padding: 16px;
  // 	border-radius: 5px;
  // 	background-color: #333542;

  // 	.pass-hint {
  // 		position: absolute;
  // 		right: 0;
  // 		top: 0;

  // 		i {
  // 			font-size: 20px;
  // 			color: #c3c3e2;
  // 		}

  // 		i:hover {
  // 			cursor: pointer;
  // 		}
  // 	}

  // 	.pass-title {
  // 		display: flex;
  // 		justify-content: center;
  // 		color: #c3c3e2;
  // 		font-size: 20px;
  // 	}

  // 	.input {
  // 		margin-top: 15px;
  // 		width: 100%;

  // 		input {
  // 			width: 100%;
  // 			height: 40px;
  // 			outline: none;
  // 			border: none;
  // 			color: #848492;
  // 		}

  // 		input:focus {
  // 			border: none;
  // 		}
  // 	}

  // 	.pass-btn {
  // 		margin-top: 15px;
  // 		width: 100%;

  // 		button {
  // 			width: 100%;
  // 		}
  // 	}
  // }
}

.pass {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .pass-box {
    width: 20%;
    @media (max-width: 550px) {
      width: 80%;
    }
  }
}

.main {
  width: 100%;
  padding: 0 200px;
  box-sizing: border-box;
  background-color: #13161b;
  overflow-y: scroll;
  @media (max-width: 550px) {
    padding: 0 10px;
  }
  .back {
    width: 100%;
    border-radius: 3px;
    height: 60px;
    line-height: 60px;
    background-color: rgba(0, 0, 0, 0.28);
    margin-top: 30px;
    padding: 0 30px;
    box-sizing: border-box;
    position: relative;
    color: #a9abbc;
    font-size: 14px;
    @media (max-width: 550px) {
      margin-top: 15px;
      height: 40px;
      line-height: 40px;
    }
    .left {
      cursor: pointer;
      // background-color: red;
      width: 75px;
      &:hover {
        color: white;
        .l {
          border: 1.5px solid white !important;
        }
      }
    }
    .l {
      padding: 5px;
      border: 1.5px solid #383f4f;
      border-radius: 6px;
      margin-right: 10px;
    }
    .right {
      position: absolute;
      right: 30px;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        color: white;
      }
      img {
        width: 20px;
        height: auto;
        margin-right: 10px;
      }
    }
  }
  .roomInfo {
    border-radius: 3px;
    width: 100%;
    height: 420px;
    margin-top: 10px;
    background-color: #13161b;
    background-image: url(./img/info-bg.png);
    background-size: 100%;
    background-repeat: no-repeat;
    color: white;
    display: flex;
    @media (max-width: 550px) {
      flex-direction: column;
      height: auto;
    }
    .info {
      width: 32%;
      height: 100%;
      // background-color: red;
      padding: 0 1.2%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      @media (max-width: 550px) {
        width: 100%;
      }
      .avatar {
        width: 15.5vw;
        // width: 300px;
        height: 15.5vw;
        // background-color: red;
        background-image: url(./img/room-avatar-bg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @media (max-width: 550px) {
          width: 35vw;
          height: 35vw;
          .img {
            width: 20vw !important;
            height: auto !important;
          }
        }
        .img {
          width: 140px;
          height: auto;
          border-radius: 50%;
          border: 3px solid #ffc600;
        }
        .bo {
          position: absolute;
          bottom: 60px;
          transform: scale(1);
          @media (max-width: 550px) {
            width: 20vw;
            bottom: 5vw;
          }
        }
      }
      .num {
        display: flex;
        width: 100%;
        margin-top: 4%;
        justify-content: space-around;
        .price {
          display: flex;
          justify-content: center;
          align-items: center;
          .all {
            display: flex;
            flex-direction: column;
            margin-left: 2px;
            font-size: 20px;
            color: #ffc600;
            .zi {
              font-size: 14px;
              color: white;
            }
          }
        }
      }
    }
    .other {
      width: 30%;
      height: 100%;
      // background-color: yellow;
      margin-left: 100px;
      @media (max-width: 550px) {
        width: 100%;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .title {
        font-size: 24px;
        font-weight: 700;
        margin-top: 12%;
        @media (max-width: 550px) {
          margin-top: 10px;
          font-size: 18px;
        }
      }
      .row1 {
        margin-top: 6%;
        @media (max-width: 550px) {
          margin-top: 10px;
          font-size: 14px;
        }
        span {
          color: #ffc600;
          font-size: 14px;
        }
      }
      .row2 {
        margin-top: 6.5%;
        // height: 70px;
        height: 16%;
        // background-color: red;
        display: flex;
        align-items: center;
        @media (max-width: 550px) {
          height: 14vw;
        }
        .left {
          text-align: center;
          height: 100%;
          font-size: 14px;
          margin-right: 20px;
          padding-top: 10px;
          @media (max-width: 550px) {
            padding-top: 5px;
            font-size: 13px;
            img {
              width: 26px;
            }
          }
        }
        .right {
          display: flex;
          align-items: center;
          height: 100%;
          .s {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            width: 55px;
            border-radius: 5px;
            background-color: #23262e;
            padding: 4px 0;
            box-sizing: border-box;
            @media (max-width: 550px) {
              width: 45px;
            }
            span {
              font-size: 16px;
              @media (max-width: 550px) {
                font-size: 14px;
              }
              &:nth-child(1) {
                color: #f9c545;
                font-weight: 700;
                font-size: 20px;
                @media (max-width: 550px) {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
      .row4 {
        width: 90%;
        height: 20%;
        // margin-top: 20px;
        .price {
          color: #f9c545;
          font-size: 16px;
          font-weight: 700;
          .zi {
            color: white;
            font-size: 14px;
            font-weight: auto;
          }
          display: flex;
          justify-content: space-between;
          margin-top: 15px;
          .all {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .none {
        user-select: none;
        cursor: not-allowed !important;
      }
      .row3 {
        // margin-top: 11%;
        margin-top: 9%;
        width: 90%;
        height: 15%;
        background: #323640;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        font-size: 20px;
        font-weight: 700;
        @media (max-width: 550px) {
          height: 45px;
          font-size: 15px;
          margin-bottom: 20px;
          margin-top: 20px;
          img {
            width: 22px !important;
          }
        }
        img {
          margin-right: 5px;
          width: 35px;
          height: auto;
        }
      }
      .ccc {
        background: #ffc600 !important;
        color: black;
      }
    }
  }

  .winlist {
    width: 100%;
    .titlewin {
      margin-bottom: 20px;
      color: #ffc600;
      font-weight: 700;
      font-size: 20px;
      img {
        width: 40px;
      }
    }
    .list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 230px;
        height: 280px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: #202125;
        border-radius: 10px;
        margin-right: 19px;
        margin-bottom: 19px;
        position: relative;
        @media (max-width: 550px) {
          width: 43vw;
          height: 60vw;
          margin: 0 8px 20px;
        }
        &:nth-child(1) {
          .bottom {
            border: 1.5px solid #fbc300 !important;
            background: rgba(251, 195, 0, 0.4) !important;
            img {
              border: 1px solid #fbc300 !important;
            }
          }
        }
        &:nth-child(2) {
          .bottom {
            border: 1.5px solid #fbc300 !important;
            background: rgba(251, 195, 0, 0.4) !important;
            img {
              border: 1px solid #fbc300 !important;
            }
          }
        }
        &:nth-child(3) {
          .bottom {
            border: 1.5px solid #fbc300 !important;
            background: rgba(251, 195, 0, 0.4) !important;
            img {
              border: 1px solid #fbc300 !important;
            }
          }
        }
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 3px;
          background-color: #cfcfcf;
          top: 2px;
          left: 0;
          z-index: 99;
          border-top-right-radius: 2px;
          border-top-left-radius: 2px;
        }
        .row1 {
          display: flex;
          align-items: center;
          margin-top: 10px;

          img {
            width: 20px;
            height: auto;
          }
        }
        .wuqi {
          width: 120px;
          height: 125px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: url(./img/win-item-bg.png);
          background-size: 100% auto;
          @media (max-width: 550px) {
            width: 22vw;
            height: 23vw;
          }
          img {
            width: 55px;
            height: auto;
          }
        }
        .row2 {
          display: flex;
          align-items: center;
          flex-direction: column;
          @media (max-width: 550px) {
            span {
              font-size: 12px !important;
            }
          }
          .h22 {
            @media (max-width: 550px) {
              width: 90%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-align: center;
              font-size: 14px;
            }
          }
        }
        .bottom {
          width: 170px;
          height: 40px;
          display: flex;
          align-items: center;
          border-radius: 20px;
          border: 1px solid #8a8a8a;
          background-color: hsla(0, 0%, 40%, 0.4);
          @media (max-width: 550px) {
            width: 80%;
            height: 30px;
            font-size: 14px;
          }
          div {
            width: 95px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
          }
          img {
            width: 40px;
            height: auto;
            margin-right: 7px;
            border-radius: 50%;
            border: 1px solid #8a8a8a;
          }
        }
      }
    }
  }

  .listBox {
    width: 100%;
    // height: 500px;
    margin-top: 50px;
    margin-bottom: 50px;
    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      .wei {
        font-weight: 700;
      }
      .huang {
        color: rgb(255, 198, 0);
      }
      .left {
        font-size: 18px;
        font-weight: 700;
        display: flex;
        align-items: center;
        img {
          width: 20px;
          margin-right: 5px;
        }
        @media (max-width: 550px) {
          font-size: 14px;
        }
      }
      .right {
        margin-right: 20px;
        display: flex;
        align-items: center;
        @media (max-width: 550px) {
          font-size: 14px;
        }
        span {
          display: block;
        }
      }
    }
    .list {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      .item {
        margin: 0.55%;
        width: 15.5%;
        // height: 270px;
        height: 14vw;
        margin-top: 9px;
        border-radius: 2px;
        // background-color: yellow;
        background-image: url(./img/wuqi-bg.png);
        background-size: 120% auto;
        background-position: center center;
        // background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        @media (max-width: 550px) {
          margin-left: 10px;
          width: 43vw;
          height: 60vw;
          background-size: 135% auto;
        }
        .row1 {
          text-align: center;
          width: 100%;
          @media (max-width: 550px) {
            p {
              width: 100%;
              font-size: 14px !important;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .wei {
          font-weight: 700;
        }
        img {
          // width: 70%;
          // height: auto;
          width: auto;
          height: 45%;
          
        }
        .row2 {
          margin-top: 15px;
          font-size: 14px;
          @media (max-width: 550px) {
            font-size: 13px;
          }
        }
        .row3 {
          display: flex;
          margin-top: 30px;
          @media (max-width: 550px) {
            margin-top: 10px;
            font-size: 12px;
            img {
              width: 15px !important;
            }
          }
        }
      }
    }
  }
  .titleuser {
    color: white;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    .users {
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
      .num {
        color: rgb(255, 198, 0);
        font-weight: 700;
      }
    }
  }
  .user {
    color: #a9abbc;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    .item {
      width: 100px;
      // height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      margin-right: 22px;
      margin-top: 20px;
      .name {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
      @media (max-width: 550px) {
        width: 70px;
        img {
          width: 70px !important;
        }
      }
      img {
        width: 100px;
        height: auto;
        margin-bottom: 3px;
        border-radius: 50%;
      }
    }
  }
}

.changeList {
  color: white;
  margin-top: 30px;
  font-weight: 700;
  font-size: 18px;

  .activeH {
    position: relative;
    color: #ffc600;
    &::after {
      content: "";
      width: 70%;
      height: 3px;
      background-color: #ffc600;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  span {
    cursor: pointer;
  }
}
</style>