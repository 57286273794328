<template>
  <div class="main" >
    <div class="loading-box">
      <img
        class="loading-logo"
        src="../assets/img/logo.png"
        alt=""
        loading="lazy"
      />
      <div class="loading-bar"><img src="../assets/img/loading.png" alt=""></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  data() {
    return {
      showLoading: false
    };
  },
  // mounted() {
  //   this.$bus.$on('show-loading', () => {
  //     this.showLoading = true;
  //     console.log("触发")
  //   });
  //   this.$bus.$on('hide-loading', () => {
  //     this.showLoading = false;
  //     console.log("触发2")
  //   });
  // },
  // beforeDestroy() {
  //   this.$bus.$off('show-loading');
  //   this.$bus.$off('hide-loading');
  // }
};
</script>

<style  scoped lang="less">
.main {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  min-height: 100vh;
  background: #16151f;
  position: fixed;
  inset: 0;
  z-index: 100;
}
.main::after {
  position: absolute;
  content: "";
  width: 90%;
  height: 90%;
  background: url('../assets/img/line.gif');
  background-size: contain;
  background-repeat: no-repeat;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-position: center;
}

.loading-box {
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
  color: #fff;
  position: relative;
 
}
/* .loading-box::before{
  content: '';
  position: absolute;
  width: 200px;
  height: 200px;
  background: url('../src/assets/line.gif');
  background-size: contain;
  background-repeat: no-repeat;
  top: 6%;
  left: 50%;
  transform: translate(-50%,-50%);
} */
.loading-box .loading-bar{
  line-height: 40px;
  // animation: 1.2s ease-in-out infinite loading-logo-flipX;
}
.loading-logo,
.loading-progress {
  position: relative;
  box-sizing: border-box;
}
.loading-logo {
  width: 75%;
  display: inline-block;
  animation: 1.2s ease-in-out infinite loading-logo-flipX;
  border-radius: 17% 83% 83% 17%/50% 0 0 50%;
  margin-left: -1rem;
}
.loading-bar {
  /* height: 4.8px;
  border-radius: 4.8px;
  text-align: center;
  color: #fff; */
}
@keyframes loading-logo-flipX {
  0% {
    opacity: 0.3;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.7;
  }
  75% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 767px) {
  .main::after{
    width: 180% !important;
    height: 180% !important;
  }
  .loading-logo{
    width: 50%;
  }
  .loading-bar{
    text-align: center;
  }
  .loading-bar img{
    width: 50%;
    margin: 0 auto;
  }
}
</style>
