<template>
  <div class="main">
    <div class="header">
      <li>vip</li>
    </div>
    <!-- <div class="title">
      <img src="../assets/img/vip-move.png" alt="">
      <span>会员等级</span>
    </div>
    <div class="dscription">
      <span>尊敬的用户，您当前等级为</span>
      <span class="vipRank">{{grade}}</span>
    </div>
    <div class="money" style="margin-top:10px;color:white;font-size:16px;">
      <span>已充值</span><span>{{recharge}}</span> <span>升级至下一级还需充值</span><span>{{xu_recharge}}</span>
    </div> -->
    <div class="tableData">
      <div class="t1">
        <ul>
          <li>VIP等级</li>
          <li>升级条件</li>
          <li>充值加成</li>
          <li>奖励金币</li>
          <li>操作</li>
        </ul>
      </div>
      <div class="content" v-for="(item,index) in tableData" :key="index">
        <ul :class="index%2==0?'bg1':'bg2'">
          <li>{{item.grade}}</li>
          <li>{{item.upgrade}}</li>
          <li>{{item.rebate}}%</li>
          <li>{{item.reward}}</li>
          <li>
            <span class="button" @click="gets(item.id)" v-if="item.is_qu==1">立即领取</span>
            <span class="buttons" v-if="item.is_qu==0">未达标</span>
            <span class="buttonss" v-if="item.is_qu==2">已发放</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return{
          grade: '',
      recharge: '',
      xu_recharge: '',
      totalSize: 0,
      page: 1,
      pageSize: 10,
      tableData: [],
      tableData1: [],
      totalSize: 0,
      pageSize: 1000,
      totalSize1: 0,
      pageSize1: 10,
      jindu:0
        }
    },
    mounted() {
        this.getskinToSteam(this.page);
    },
    methods: {
      getskinToSteam(page) {
      let param = {
        page: page,
        pagesize: this.pageSize,
      };
      this.$axios
        .post("/api/welfare/vipfuli", this.$qs.stringify(param))
        .then((res) => {
          //  console.log(res.data);
          var data = res.data;
          if (data.code == "1" && data.data != null) {
            // this.totalSize = data.data.total;
            this.tableData = data.data.list;
            this.grade = data.data.grade
            this.recharge = data.data.recharge
            this.xu_recharge  = data.data.xu_recharge 
            this.jindu = (this.recharge/(this.recharge + this.xu_recharge)).toFixed(2)*100
            this.$emit('getJindu',this.jindu)
          }
        });
    },
    gets(id) {
      this.$axios({
        url:'/api/welfare/lingvipfuli',
        method: 'post',
        data:{id}
      }).then((res) => {
        if(res.data.code == 1) {
          this.$message({
            message: res.data.msg,
            type: "success",
            customClass: "messageStyle",
          });
        }else{
          this.$message({
            message: res.data.msg,
            type: "warning",
            customClass: "messageStyle",
          });
        }
      })
    }
    }
}
</script>

<style lang="less" scoped>
.header {
  height: 42px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-top: 10px;
  li{
    list-style: none;
    width: 110px;
    height: 48px;
    line-height: 48px;
    color: white;
    margin-right: 40px;
    text-align: center;
    padding: 0 24px;
    color: #69e0ff !important;
    border-bottom: 2px solid #69e0ff;
    &:hover{
      background: rgba(255, 255, 255, .1);
    }
  }
}
.main{
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  // background-color: red;
}
.tableData{
    width: 100%;
    height: auto;
    margin-top: 80px;

    .t1{
      ul{
        display: flex;
        color: white;
        justify-content: space-around;
        width: 100%;
        height: 50px;
        background: linear-gradient(180deg, rgba(82, 0, 255, 0) 0%, rgba(0, 22, 224, .2) 100%);
        border: .02667rem solid rgba(169, 72, 250, .2);
        li{
          flex: 1;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .content{
      .bg1{
          background: linear-gradient(90deg, #1c2248 0%, rgba(21, 25, 54, 0) 100%);
        }
        .bg2{
          background: rgba(18, 20, 41, .35);
        }
      ul{
        color: white;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-around;
        background: linear-gradient(90deg, #1c2248 0%, rgba(21, 25, 54, 0) 100%);
        }
        li{
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .button{
            padding: 3px 12px;
            cursor: pointer;
            color: black;
            background-color: #ffc600;
            border-radius: 3px;
          }
          .buttons{
            padding: 5px 12px;
            cursor: pointer;
            color: black;
             background-color: #9e9e9c;
             border-radius: 3px;
             cursor: not-allowed !important;
          }
          .buttonss{
            font-weight: 700;
            color: #ffc600;
          }
      }
    }
  }
</style>